<form #f="ngForm">
    <p *ngIf="!isDlg">
        <ng-container *ngIf="manufacturer.opencartId > 0; else noConection">
            <mat-icon class="text-green" matTooltip="Összekapcsolava egy Gyártóval a weblapon">
                {{(manufacturer.opencartId > 0) ? 'power' : 'power_off'}}
            </mat-icon>
        </ng-container>
        <ng-template #noConection>
            <mat-icon class="text-secondary" matTooltip="Nincs Összekapcsolava egy Gyártóval sem a weblapon">power_off</mat-icon>
        </ng-template>
    </p>
   
    <p *ngIf="!isDlg"> 
        <mat-form-field appearance="outline">
            <mat-label>Weblap ID:</mat-label>
            <input matInput name="opecartId" [(ngModel)]="manufacturer.opencartId" autocomplete="off">
            <mat-icon matSuffix matTooltip="Ha a Gyártóhoz van OpenCart ID hozzárendelve akkor az adatok a Weblapon kell módosítani először. Majd frissíteni a rendszer adatokat. Adatok szerkeszése nem lehetséges. Ha törlöd az ID-t a kapcsolat is törlődik.">info</mat-icon>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név</mat-label>
            <input matInput name="name" [(ngModel)]="manufacturer.name" [disabled]="manufacturer.opencartId > 0" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Kép</mat-label>
            <input matInput name="imageUrl" [(ngModel)]="manufacturer.imageUrl" [disabled]="manufacturer.opencartId > 0" autocomplete="off">
            <mat-icon matSuffix matTooltip="Teljes url: http://....">info</mat-icon>
            <span matPrefix>
                <ng-container *ngIf="manufacturer.imageUrl">
                    <img style="width: 40px;" [src]="manufacturer.imageUrl" alt="">
                </ng-container>    
            </span>
        </mat-form-field>
       
    </p>
    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="manufacturer.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="manufacturer"></app-quick-log-info>           
            </span>
        </ng-container>  
    </p>

</form>