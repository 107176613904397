<form>
    <p>
        <mat-form-field appearance="outline">
            <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Projekt Keresés..."  [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
            <mat-option (click)="selectProject(optionProject)" *ngFor="let optionProject of projectOptions" [value]="optionProject">
                {{optionProject.name}}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </p>
    <p>
        <ng-container *ngIf="project">
            <mat-form-field>
                <mat-label>Lepés Választás </mat-label>
                <mat-select>
                    <mat-option (click)="selectAction(option)" *ngFor="let option of actionOptions" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </p>

</form>
