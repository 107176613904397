<div class="quick-log">
    <div class="text-center header"><mat-icon>policy</mat-icon></div>
    <table class="table">
        <tr *ngIf='data.created'>
            <td>Létrhozva</td>
            <td>{{data.created | date:'medium'}}</td>
        </tr>
        <tr *ngIf='data.creator'>
            <td>Létrhozta</td>
            <td>
                
                <span [ngStyle]="{'backgrund-color': data.creator.color}" matBadgeOverlap="false">{{data.creator.email}}</span>
            </td>
        </tr>
        <tr *ngIf='data.updated'>
            <td>Frissítve</td>
            <td>{{data.updated | date:'medium'}}</td>
        </tr>
    
        <tr *ngIf='data.lastModifier'>
            <td>Frisítette</td>
            <td>
                
                <span [ngStyle]="{'backgrund-color': data.lastModifier.color}" matBadgeOverlap="false">{{data.lastModifier.email}}</span>
            </td>
        </tr>
    </table>    
</div>
