import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Item } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services/item.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { DlgEditProductComponent } from 'src/app/shared/dialogs/edit/dlg-edit-product/dlg-edit-product.component';

@Component({
  selector: 'app-page-item',
  templateUrl: './page-item.component.html',
  styleUrls: ['./page-item.component.scss']
})
export class PageItemComponent extends BaseEntity implements OnInit {
  item: Item = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private dialog: MatDialog,
    private moduleFacotry: ModelFactory
  ) {
    super();
    
    this.item = this.moduleFacotry.getNewModel(Item);
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.itemService.get(id).subscribe(i => {
        this.item = i;
      });
    }
  }

  created(entity) {
    this.item = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.item.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../items'],  {
        relativeTo: this.route  
      });
  }

  updated(event) {
    //
  }

  createProductDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditProductComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(product => {});
  }
}
