import {NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
  MatToolbarModule, MatMenuModule,MatIconModule, MatProgressSpinnerModule, MatSidenavModule,
  MatPaginatorModule, MatSnackBarModule, MatTooltipModule, MatSortModule, MatCheckboxModule,
  MatSelectModule, MatBottomSheetModule, MatBadgeModule, MatExpansionModule, MatDividerModule,
  MatAutocompleteModule, MatRadioModule, MatTabsModule, MatStepperModule, MatListModule, MatList
} from '@angular/material';

@NgModule({
  imports: [
  CommonModule, 
  MatToolbarModule,
  MatButtonModule, 
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatTableModule,
  MatMenuModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatSortModule,
  MatCheckboxModule,
  MatSelectModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatExpansionModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatTabsModule,
  MatStepperModule,
  MatListModule
  ],
  exports: [
  CommonModule,
   MatToolbarModule, 
   MatButtonModule, 
   MatCardModule, 
   MatInputModule, 
   MatDialogModule, 
   MatTableModule, 
   MatMenuModule,
   MatIconModule,
   MatProgressSpinnerModule,
   MatSidenavModule,
   MatPaginatorModule,
   MatSnackBarModule,
   MatTooltipModule,
   MatSortModule,
   MatCheckboxModule,
   MatSelectModule,
   MatBottomSheetModule,
   MatBadgeModule,
   MatExpansionModule,
   MatDividerModule,
   MatAutocompleteModule,
   MatRadioModule,
   MatTabsModule,
   MatStepperModule,
   MatListModule
   ],
})

export class CustomMaterialModule { }