import { HttpClient } from '@angular/common/http';
import { Role } from '../models/role.model';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '../shared/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class RolesService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL = '/accounts/roles/';
        // list of the roles
        this.dataSource$ = new BehaviorSubject([]);
        this.data = this.dataSource$.asObservable();
    }
    list(options = null) {
        let url = null;
        let params = null;
        // set options
        let base = { url: null, params: null };
        if (options) {
            options = Object.assign({}, base, options);
        }
        else {
            options = base;
        }
        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
            url = options.url;
        }
        else {
            url = this.baseUrl + this.URL;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }
        return this.http.get(url, { params }).pipe(map(res => {
            res.results = res.results.map(role => new Role(role));
            return res;
        }));
    }
    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get(url).pipe(map(res => new Role(res)));
    }
    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(map(result => new Role(result)), tap(res => { this.notificationService.created(); }));
    }
    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(map(res => new Role(res)), tap(res => { this.notificationService.updated(); }));
    }
    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(tap(res => { this.notificationService.deleted(); }));
    }
}
RolesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolesService_Factory() { return new RolesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: RolesService, providedIn: "root" });
