/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./item/item.component.ngfactory";
import * as i9 from "./item/item.component";
import * as i10 from "../../services/item.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "./page-item.component";
import * as i13 from "../../services/modelFactory.service";
var styles_PageItemComponent = [i0.styles];
var RenderType_PageItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageItemComponent, data: {} });
export { RenderType_PageItemComponent as RenderType_PageItemComponent };
function View_PageItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item._product.nameHu; var currVal_1 = _co.item.serialNumber; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_PageItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ul", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Term\u00E9kek"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageItemComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "flex padding-10-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "full text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["color", "primary"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createProductDlg() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Alap Term\u00E9k L\u00E9trehoz\u00E1sa"])), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Term\u00E9k"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-item", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_ItemComponent_0, i8.RenderType_ItemComponent)), i1.ɵdid(19, 114688, null, 0, i9.ItemComponent, [i10.ItemService, i11.MatDialog], { item: [0, "item"] }, { eventCreated: "eventCreated", eventDeleted: "eventDeleted", eventUpdated: "eventUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "../"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.item.product; _ck(_v, 7, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.item; _ck(_v, 19, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_PageItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-item", [], null, null, null, View_PageItemComponent_0, RenderType_PageItemComponent)), i1.ɵdid(1, 114688, null, 0, i12.PageItemComponent, [i2.ActivatedRoute, i2.Router, i10.ItemService, i11.MatDialog, i13.ModelFactory], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageItemComponentNgFactory = i1.ɵccf("app-page-item", i12.PageItemComponent, View_PageItemComponent_Host_0, {}, {}, []);
export { PageItemComponentNgFactory as PageItemComponentNgFactory };
