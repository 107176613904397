import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { Action } from 'src/app/models/action.model';
import { ProjectService } from 'src/app/services/project.service';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends BaseEntity implements OnInit {
  project: Project = null;

  selectedAction: Action = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService
  ) {
    super();
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.projectService.get(id).subscribe(i => {
        this.project = i;
        this.setInitSelectedAction();
      });
    }
  }

  setInitSelectedAction() {
    if (!this.project.actions) {
      return;
    }

    for (let i = 0; i < this.project.actions.length; i++) {
      let action = this.project.actions[i];
      
      if (action.status === EnumActionStatus.NotStarted) {
        this.selectedAction = action;
        break;
      }

      if (!this.selectedAction) {
        //default to last one
        this.selectedAction = this.project.actions[this.project.actions.length];
      }
    }
  }

  setSelectedAction(action) {
    this.selectedAction = action;
  }

  edit() {
    this.router.navigate(['../project-setup'],  {
      queryParams: {id: this.project.id}, 
      relativeTo: this.route  
    });
  }

  setStatus(status) {
    let closed = (status === 'close') ? true : false;
    this.project.closed = closed;
    this.projectService.update(this.project).subscribe(project => {
      this.project = project;
    });
  }

}
