<ng-container *ngIf="action">
    <div class="section">
        <div class="header">
            <div class="title"><span>Lépés</span></div>
        </div>
        <h6 class="padding-20-0">{{action.order + 1}}. {{action.name}}</h6>
        <mat-form-field appearance="outline">
            <mat-label>Státusz</mat-label>
            <mat-select name="status" (selectionChange)="statusChange($event)"   [(ngModel)]="action.status">
                <mat-option [value]="enumActionStatus.NotStarted">Nem keződőtt el</mat-option>
                <mat-option [value]="enumActionStatus.InProgress">Folyamatban</mat-option>
                <mat-option [value]="enumActionStatus.Blocked">Elakadt</mat-option>
                <mat-option [value]="enumActionStatus.Done">Befejezve</mat-option>
                <mat-option [value]="enumActionStatus.Cancelled">Törölve</mat-option>
            </mat-select>
        </mat-form-field>
        <app-events [action]="action"></app-events>
    </div>
</ng-container>


