<ul class="breadcrumb"> 
    <li>Vallalat</li>  
</ul>

<div class=section>
    <div class="header">
        <div class="title">
            <span>Vállalat  - {{company.domain}}</span>
        </div>
    </div>
    <form>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Név</mat-label>
                <input matInput name="name" [(ngModel)]="company.name" autocomplete="off" required>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline"> 
                <mat-label>Telefon</mat-label>
                <input matInput name="telefon" [(ngModel)]="company.phone" autocomplete="off">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput name="email" [(ngModel)]="company.email" autocomplete="off">
            </mat-form-field>
        </p>
        <hr>
        <div class="badge">Cím</div>
        <div class="flex flex-wrap flex-space-evenly">
            <p class="padding-l-20">
                <mat-form-field appearance="outline">
                    <mat-label>Város</mat-label>
                    <input matInput name="city" [(ngModel)]="company.addressCity" autocomplete="off">
                </mat-form-field>
            </p>
            <p class="padding-l-20">
                <mat-form-field appearance="outline">
                    <mat-label>Utca (1)</mat-label>
                    <input matInput name="cim1" [(ngModel)]="company.addressLine1" autocomplete="off">
                </mat-form-field>
            </p>
            <p class="padding-l-20">
                <mat-form-field appearance="outline">
                    <mat-label>Utca (2)</mat-label>
                    <input matInput name="cim2" [(ngModel)]="company.addressLine2" autocomplete="off">
                </mat-form-field>
            </p>
            <p class="padding-l-20">
                <mat-form-field appearance="outline">
                    <mat-label>Iranyítószám</mat-label>
                    <input matInput name="postcode" [(ngModel)]="company.addressPostcode" autocomplete="off">
                </mat-form-field>
            </p>
        </div>
        <p>
            <button mat-flat-button  color="primary" (click)="update()">
                Mentés
            </button>
        </p>
    </form>
   

    <!--<p class="text-secondary text-small"> Időzóna: {{company.timezone}}, Nyelv: {{company.language}}</p>-->
</div>



<div class="flex flex-align-item-start">
    <mat-card class="w400 margin-lef">
        <mat-card-header>
            <mat-card-title>
                <span>PDF Fejléc Feltöltés</span>
            </mat-card-title>
            <mat-card-subtitle>
                A kép a generált pdf fájlokhoz mint fejléc lesz felhasnalva. A kép alakja ideális ha hosszukás. 
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <img src="{{company.pdfHeader}}" alt="Logo" class="img-fluid">
        </mat-card-content>
        <mat-card-actions>
            <app-file-upload (onFileSelect)="onPdfHeaderUpload($event)"></app-file-upload>
        </mat-card-actions>
    </mat-card>

    <mat-card class="w400 margin-l-20">
        <mat-card-header>
            <mat-card-title>
                <span>Cég Logó</span>
            </mat-card-title>
            <mat-card-subtitle>
               Minnél kissebb kép annál jobb
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <img src="{{company.logo}}" alt="Logo" class="img-fluid">
        </mat-card-content>
        <mat-card-actions>
            <app-file-upload (onFileSelect)="onLogoUpload($event)"></app-file-upload>
        </mat-card-actions>
    </mat-card>
</div>
