<div class="section">
    <div class="header">
        <div class="title"><span>Falhsználó Role</span></div>
    </div>
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
                <th>Role</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let userRole of userRoles">
                <tr>
                    <td>
                        {{userRole.role.name}}
                    </td>
                    <td><span class="float-right">
                        <button mat-mini-fab class="mat-button mat-danger" (confirm-click)="delete(userRole.id)" confirm>
                            <mat-icon>delete</mat-icon>
                          </button>
                        </span>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
   
    
    <!--add new role-->
    <form #f="ngForm" class="form-group">
        <div class="flex">
            <p>
                <mat-form-field appearance="standard">
                    <mat-label>Role</mat-label>
                    <mat-select name="roles" [(ngModel)]="selectedRole" (selectionChange)="setSelectedValue($event)">
                        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                    </mat-select>
                 </mat-form-field>
                </p>
                
                <p>
                    <button mat-mini-fab  color="primary" (click)="create()">
                        <mat-icon>add</mat-icon>
                    </button>
                </p>
        </div>
    </form>
</div>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>
