<form #f="ngForm">
    <p>
        <ng-container *ngIf="item.product">
            <div class="text-secondary text-small">
                {{item._product.nameHu}}
            </div>            
        </ng-container>
        <mat-form-field appearance="outline">
            <mat-label>Alap Termék</mat-label>
            <ng-container *ngIf="item.product; else noProduct">
                <input matInput name="product" disabled [(ngModel)]="item._product.nameHu" autocomplete="off" required>
            </ng-container>
            <ng-template #noProduct>
                <input matInput name="product" readonly [(ngModel)]="item.product" autocomplete="off" required>
            </ng-template>
            <mat-icon class="pointer" (click)="openProductSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Sorozat Szám</mat-label>
            <input matInput name="serialNumber" [(ngModel)]="item.serialNumber"  autocomplete="off" [required]="item.product && item._product.type == 'machine'">
        </mat-form-field>
        
    </p>
    <p>
        <ng-container *ngIf="item.parent">
            <div class="text-secondary text-small">
                {{item._parent._product.nameHu}} - <span class="badge badge-pill badge-warning">{{item._parent.serialNumber}}</span>
            </div>            
        </ng-container>
        <mat-form-field appearance="outline">
            <mat-label>Beépítve</mat-label>
            <mat-icon class="pointer" (click)="openItemSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
            <mat-icon matSuffix matTooltip="Válaszd ki a Terméket ami tartelmazza ezt. Például Alkatrész esetén a Gép.">info</mat-icon>
            <ng-container *ngIf="item.parent; else noParent">
                <input matInput name="parent" disabled [(ngModel)]="item._parent._product.nameHu"  autocomplete="off">
            </ng-container>
            <ng-template #noParent>
                <input matInput name="parent" disabled [(ngModel)]="item.parent" autocomplete="off">
            </ng-template>
        </mat-form-field>
    </p>
    <p class="padding-20-0">
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="item.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="item"></app-quick-log-info>           
            </span>
        </ng-container>
    </p>
</form>
