import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { EnumTaskInterestStatus } from 'src/app/enums/tasks/taskInterestStatus.enum';
import { User } from 'src/app/models/user';
import { TaskInterestService } from 'src/app/services/taskInterest.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-task-interests',
  templateUrl: './task-interests.component.html',
  styleUrls: ['./task-interests.component.scss'],
  providers: [UrlParamService]
})
export class TaskInterestsComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  displayedColumns = ['importance', 'name', 'assignee', 'status', 'project', 'organization'];

  archived = false;

  assignee: User = null;
  users: User[] = [];

  importance = null;
  status = null;

  //ProjectImportance = EnumProjectImportance;
  enumTaskInterestStatus = EnumTaskInterestStatus

  constructor(
    private taskIterestService: TaskInterestService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private usersService: UsersService
  ){
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    this.usersService.getActiveUsers().subscribe(users => {
      // grab the users
        this.users = users;
        this.doGetInitalOptions();
    });
  }

  doGetInitalOptions() {
    let defaultParams = {archived: this.archived}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.taskIterestService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  open(entity) {
    this.router.navigate(['interest'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  changeStatus(event) {
    this.resetFilters();

    // active or archived
    this.archived = (event.value == 'archived') ? true : false;
    
    let param = {archived: this.archived};   
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeImportance(importance) {
    this.resetFilters();

    let param = {importance: null}; 
    
    this.importance = null;
    
    if (importance) {
      this.importance = importance;
      param = {importance: +importance};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeUser(user) {
    this.resetFilters();

    let param = {assignee: null}; 
    
    this.assignee = null;
    
    if (user) {
      this.assignee = user;
      param = {assignee: this.assignee.id};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeTaskStatus(status) {
    this.resetFilters();
    let param = {status: null}; 
    this.status = null;
    
    if (status) {
      this.status = status;
      param = {status: this.status};
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }
}

