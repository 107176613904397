import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { EnumFileUploadType } from '../enums/fileUploadType.enum'


@Injectable({
    providedIn: 'root'
  })
export class FileUploadService {
    baseUrl = environment.baseUrl;

    paths = {};
    
    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {
            this.paths[EnumFileUploadType.CompanyPdfHeader] = '/accounts/company/upload/pdf-header/';
            this.paths[EnumFileUploadType.CompanyLogo] = '/accounts/company/upload/logo/';
        }

    upload(formData: FormData, type: EnumFileUploadType) {
        let path = this.paths[type];
        
        let url = this.baseUrl + path;

        let headers = new HttpHeaders;
        headers = headers.set('Content-Type', 'multipart/form-data');
        headers = headers.set('Accept', 'application/json');

        let file: any = formData.get('file');
        let fileName = file.name;
        let contentDispositionHeader = `form-data; filename="${fileName}"`;
        headers = headers.append('Content-Disposition', contentDispositionHeader);

        headers = headers.append('Access-Control-Allow-Headers', 'content-disposition, accept, accept-encoding, authorization, content-type, dnt, origin, user-agent, x-csrftoken, x-requested-with');
        
        return this.http.post(url, formData).pipe(
            map(result => result),
            tap(res => { this.notificationService.created(); })
        )
    }


}