<ul class="breadcrumb"> 
    <li>Lépés Tervezés</li>  
</ul>

<div class="flex"> 
    <button (click)="doSearch()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="doSearch()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeStatus($event)">
            <mat-radio-button [checked]="!archived" value="active">Active</mat-radio-button>
            <mat-radio-button [checked]="archived" value="archived" matTooltip="Nem válsztható ki Projektek Terv létrhozásakor">Archivált</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <!--TODO: relative path not worked?-->
        <button mat-button [routerLink]="['/app/project-plans']" matTooltip="Projekt Terv létrhozása és módosítása"> 
            Projekt Tervezés
        </button>
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['action']"  matTooltip="Új Lépés"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)">{{row.name}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="task">
        <mat-header-cell *matHeaderCellDef>Feladat</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.taskType == enumTaskTypes.Service">Szervíz</ng-container>
            <ng-container *ngIf="row.taskType == enumTaskTypes.ItemOrder">Megrendelés</ng-container>
            <ng-container *ngIf="row.taskType == enumTaskTypes.ItemPurchase">Beszerzés</ng-container>
            <ng-container *ngIf="row.taskType == enumTaskTypes.ItemInterest">Megkeresés</ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Leírás</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.description | truncate:20}}
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>

