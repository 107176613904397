/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-search-org-contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./dlg-search-org-contact.component";
import * as i9 from "../../../../services/organisation.service";
import * as i10 from "../../../../services/people.service";
import * as i11 from "@angular/material/dialog";
var styles_DlgSearchOrgContactComponent = [i0.styles];
var RenderType_DlgSearchOrgContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgSearchOrgContactComponent, data: {} });
export { RenderType_DlgSearchOrgContactComponent as RenderType_DlgSearchOrgContactComponent };
function View_DlgSearchOrgContactComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"]))], null, null); }
function View_DlgSearchOrgContactComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00E9v"]))], null, null); }
function View_DlgSearchOrgContactComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.select(_co.organisation.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], null, { change: "change" })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DlgSearchOrgContactComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.select(_v.parent.context.$implicit.email) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], null, { change: "change" })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DlgSearchOrgContactComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_7)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "text-help"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "email"); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.email; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
function View_DlgSearchOrgContactComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_6)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation.emails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DlgSearchOrgContactComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_4)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_5)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "name"); _ck(_v, 4, 0, currVal_0); var currVal_2 = (_co.type == "email"); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.organisation.name; _ck(_v, 5, 0, currVal_1); }); }
function View_DlgSearchOrgContactComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.select(_v.parent.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], null, { change: "change" })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DlgSearchOrgContactComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.select(_v.parent.parent.context.$implicit.email) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], null, { change: "change" })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DlgSearchOrgContactComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_12)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "text-help"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "email"); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.email; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
function View_DlgSearchOrgContactComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_10)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_11)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "name"); _ck(_v, 4, 0, currVal_0); var currVal_2 = (_co.type == "email"); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_1); }); }
function View_DlgSearchOrgContactComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_9)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation.emails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DlgSearchOrgContactComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.select(_v.parent.context.$implicit.fullName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], null, { change: "change" })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DlgSearchOrgContactComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.lastName; var currVal_1 = _v.parent.context.$implicit.firstName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_DlgSearchOrgContactComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.firstName; var currVal_1 = _v.parent.context.$implicit.lastName; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DlgSearchOrgContactComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.select(_v.parent.context.$implicit.email) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], null, { change: "change" })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DlgSearchOrgContactComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_19)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "text-help"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "email"); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.email; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
function View_DlgSearchOrgContactComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_18)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.emails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DlgSearchOrgContactComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_14)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_15)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["tmplLanguageEn", 2]], null, 0, null, View_DlgSearchOrgContactComponent_16)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_17)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "name"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.language == "hu"); var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = (_co.type == "email"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_DlgSearchOrgContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["nameHeader", 2]], null, 0, null, View_DlgSearchOrgContactComponent_2)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_8)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgSearchOrgContactComponent_13)), i1.ɵdid(12, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type == "email"); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.organisation; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.organisation; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.people; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_DlgSearchOrgContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-search-org-contact", [], null, null, null, View_DlgSearchOrgContactComponent_0, RenderType_DlgSearchOrgContactComponent)), i1.ɵdid(1, 114688, null, 0, i8.DlgSearchOrgContactComponent, [i9.OrganisationService, i10.PeopleService, i11.MatDialogRef, i11.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgSearchOrgContactComponentNgFactory = i1.ɵccf("app-dlg-search-org-contact", i8.DlgSearchOrgContactComponent, View_DlgSearchOrgContactComponent_Host_0, {}, {}, []);
export { DlgSearchOrgContactComponentNgFactory as DlgSearchOrgContactComponentNgFactory };
