<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Alap Termékek</a></li>  
    <li>{{product.nameHu}}</li>  
</ul>

<div class="flex"> 
    <div class="full text-right padding-20-0">
        <button mat-mini-fab  color="secondary" [routerLink]="['../../items']" matTooltip="Termékek"> 
            <mat-icon>styles</mat-icon>
        </button>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../products']"  matTooltip=" Alap Termékek"> 
                <mat-icon>kitchen</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../categories']"  matTooltip="Kategóriák"> 
                <mat-icon>workspaces</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../manufacturers']"  matTooltip="Gyártók"> 
                <mat-icon>domain</mat-icon>
            </button>
        </span>
    </div> 
</div>


<div class="section">
    <div class="header">
        <div class="title"><span>Alap Termék</span></div>
        <div class="full">
            <span>
                <button mat-stroked-button  (click)="createManufactureDlg()"> Gyártó Létrehozás</button>
            </span>
            <span class="padding-l-10">
                <button mat-stroked-button (click)="createCategoryDlg()">Kategória Létrehozás</button>
            </span>
        </div>
    </div>
    <app-product 
    (eventCreated)="created($event)"
    (eventDeleted)="deleted($event)" 
    (eventUpdated)="updated($event)"
    [product]="product"></app-product>
</div>

