export class Company {
     id: number;
     name: string;
     domain: string;

     logo: any;
     pdfHeader: any;

     email: String = null;
     phone: String = null;
    
     addressCity: String = null;
     addressLine1: String = null;
     addressLine2: String = null;
     addressPostcode: String = null;

     timezone: string;
     language: string;
     
     created?: Date = null;
     updated?: Date = null;
     creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
     lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.domain = data.domain;
            
            this.logo = data.logo;
            this.pdfHeader = data.pdfHeader;
            
            this.timezone = data.timezone;
            this.language = data.language;

            this.email = data.email;
            this.phone = data.phone;
            
            this.addressCity = data.addressCity;
            this.addressLine1 = data.addressLine1;
            this.addressLine2 = data.addressLine2;
            this.addressPostcode = data.addressPostcode;
            
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }
    }
}