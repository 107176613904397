import { environment } from '../../../environments/environment';

/**
 * Serve as a base calss for a Componenet which manage one view
 * - collect the common functionlities (like new element id counting)
 */

 export abstract class BaseEntity {
    // when adding a new element to the list we have to create a dummy id for 
    PREFIX_ID = '__new__';
    nextId = 0;

    constructor() {}

    getNextId() {
        let nextId = this.nextId++;
        return this.PREFIX_ID + nextId;
    }
 }