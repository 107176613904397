import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Profile } from '../models/profile.model';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../shared/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class ProfileService {
    constructor(httpClient, notificationService) {
        this.httpClient = httpClient;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL = '/accounts/profiles/';
        this.URL_MY_PROFILE = '/accounts/profiles/update_my_profile';
    }
    list() {
        let url = this.baseUrl + this.URL;
        return this.httpClient.get(url).pipe(map(res => res.results.map(profile => new Profile(profile))));
    }
    getByUser(userId) {
        // the response is going to be a list bacuse we use the filter option to get the profile
        let url = this.baseUrl + this.URL;
        //TODO:  fake dict. This maybe shouldn't be a separate function - just part of list
        let params = Utils.getParams({ 'user': userId });
        return this.httpClient.get(url, { params }).pipe(
        // select the first result
        // cannot be more than one!
        map(res => res.results[0]), map(res => new Profile(res)));
    }
    get(id) {
        // get profile by id
        let url = this.baseUrl + this.URL + id + '/';
        return this.httpClient.put(url, id).pipe(map(res => new Profile(res)));
    }
    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.httpClient.put(url, payload).pipe(map(res => new Profile(res)), tap(res => { this.notificationService.updated(); }));
    }
    updateMyProfile(payload) {
        let url = this.baseUrl + this.URL_MY_PROFILE + '/';
        return this.httpClient.post(url, payload).pipe(tap(res => { this.notificationService.updated(); }));
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: ProfileService, providedIn: "root" });
