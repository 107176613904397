<ul class="breadcrumb"> 
    <li>Projektek</li>  
</ul>

<div class="flex"> 
    <button (click)="doSearch()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="doSearch()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Típus</mat-label>
            <mat-select name="project_type" [(ngModel)]="project_type">
                <mat-option (click)="changeType('')" [value]="">Válassz</mat-option>
                <mat-option (click)="changeType(option)" *ngFor="let option of projectTypes" [value]="option.id">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Fontosság</mat-label>
            <mat-select name="importance"  [(ngModel)]="importance">
                <mat-option (click)="changeImportance(null)" [value]="">Válassz</mat-option>
                <mat-option (click)="changeImportance(5)" [value]="5">Nagyon Fontos</mat-option>
                <mat-option (click)="changeImportance(4)" [value]="4">Fontos</mat-option>
                <mat-option (click)="changeImportance(3)" [value]="3">Normal</mat-option>
                <mat-option (click)="changeImportance(2)" [value]="2">Nem Fontos</mat-option>
                <mat-option (click)="changeImportance(1)" [value]="1">Mellékes</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Felelős</mat-label>
            <mat-select name="users" [(ngModel)]="assignee">
                <mat-option (click)="changeUser('')" [value]="">Válassz</mat-option>
                <mat-option (click)="changeUser(option)" *ngFor="let option of users" [value]="option.id">
                    {{option.lastName}} {{option.firstName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeStatus($event)">
            <mat-radio-button [checked]="closed === false" value="open"  matTooltip="Projekt Folyamatban">Aktív</mat-radio-button>
            <mat-radio-button [checked]="closed === true" value="closed" matTooltip="Projekt Befejeződött">Lezárva</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <button mat-mini-fab color="accent"  [routerLink]="['project-setup']" matTooltip="Új Projekt Létrhozása"> 
            <mat-icon>add</mat-icon>
        </button>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="importance">
        <mat-header-cell *matHeaderCellDef>Fonotsság</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-project-importance [importance]="row.importance"></app-project-importance>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.project_type">
                <span class="color-dot" [style.backgroundColor]="row._projectType.color" matTooltip="{{row._projectType.name}}"></span>
            </ng-container>
            <span class="link" (click)="open(row)" matTooltip="{{row.name}}">{{row.name | truncate: 5}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef>Feleős</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-avatar [user]="row._assignee"></app-avatar>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef>Cég</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span *ngIf="row.organization">{{row._organization.name | truncate:4}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Státusz</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <app-project-status [actions]="row.actions"></app-project-status>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>


