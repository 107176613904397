export class Product {
    id: Number = null;
    opencartId: Number = null;
    nameHu: string = null;
    nameEn: string = null;
    manufacturer: Number = null;
    _manufacturer?: any = null;
    category: Number = null;
    _category: any = null;
    type: String = 'machine'; // default type is machine
    imageUrl: String = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.opencartId  = data.opencartId;
            this.nameHu  = data.nameHu;
            this.nameEn  = data.nameEn;
            this.type  = data.type;
            this.imageUrl = data.imageUrl;
            
            this.manufacturer = data.manufacturer;
            if ('_manufacturer' in data) {
                this._manufacturer = data._manufacturer;
            }

            this.category = data.category;
            if ('_category' in data) {
                this._category = data._category;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }
