<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Gyártók</a></li>  
    <li>{{manufacturer.name}}</li>  
</ul>


<div class="section">
    <div class="header">
        <div class="title"><span>Gyártó</span></div>
    </div>
    <app-manufacturer 
    (eventCreated)="created($event)"
    (eventDeleted)="deleted($event)" 
    (eventUpdated)="updated($event)"
    [manufacturer]="manufacturer"></app-manufacturer>
</div>
