<ul class="breadcrumb"> 
    <li>Hozzáférés</li>  
</ul>

<div class="padding-10-0 flex flex-right">
    <button mat-flat-button color="primary" [routerLink]="['role']">Új Role</button>
</div>

<mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row class="cursor" *matRowDef="let row; columns: displayedColumns" (click)="open(row)"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>
