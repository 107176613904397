import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { Company } from '../../models/company.model';
import { FileUploadService } from '../../services/fileUpload.service';
import { EnumFileUploadType } from '../../enums/fileUploadType.enum';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  company: Company = null;

  constructor(
    private companyService: CompanyService,
    private fileUploadService: FileUploadService,
    private modelFactory: ModelFactory
  ){
    this.company = modelFactory.getNewModel(Company)
  }

  ngOnInit() {
    this.companyService.load().subscribe((res: Company) => {
      this.company = res;
    })
  }

  update() {
    this.companyService.update(this.company).subscribe(company => {
      this.company = company;
    })
  }

  onLogoUpload(formData) {
    // do something with the logo
    this.fileUploadService.upload(formData, EnumFileUploadType.CompanyLogo).subscribe(res => {
      // todo: ??
    });
  }

  onPdfHeaderUpload(formData) {
    // do something with the PDF Header
    this.fileUploadService.upload(formData, EnumFileUploadType.CompanyPdfHeader).subscribe(res => {
      // todo: ??
    });
  }

}
