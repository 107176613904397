<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Hozzáférés</a></li>  
    <li> {{!!editMode ? role.name : 'Létrehozás'}}</li>  
</ul>

<div class="section">
    <div class="header">
        <div class="title"><span>{{!!editMode ? role.name : 'Létrehozás'}}</span></div>
    </div>
    <form #f="ngForm" class="from-group">
        <p>
            <mat-form-field appearance="standard">
                <mat-label>Tipus</mat-label>
                <input matInput name="name" [(ngModel)]="role.name" autocomplete="off" required>
            </mat-form-field>
        </p>
        <div class="flex">
            <p class="full">
                <button mat-mini-fab  color="primary" [disabled]="f.invalid" (click)="save()">
                   <mat-icon>save</mat-icon>
                </button>
            </p>
            <p>
                <button mat-mini-fab  class="mat-fab mat-danger"  [disabled]="f.invalid" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </p>
        </div>  
    </form>
</div>

<ng-container *ngIf="role.id">
    <app-role-access [role]="role"></app-role-access>
</ng-container>
