import { Component, Input, OnInit } from '@angular/core';
import { ProjectPlan } from 'src/app/models/projectPlan.model';
import { ProjectActionPlan } from 'src/app/models/projectActionPlan.model';
import { BaseEntity } from '../../../common/baseClasses/baseEntity';
import { PlanningProjectActionsService } from 'src/app/services/planningProjectActions.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ActionPlan } from 'src/app/models/actionPlan.model';
import { DlgEditPlanActionComponent } from 'src/app/shared/dialogs/edit/dlg-edit-plan-action/dlg-edit-plan-action.component';
import { DlgSearchPlanActionComponent } from 'src/app/shared/dialogs/search/dlg-search-plan-action/dlg-search-plan-action.component';

/**
 * View manage list of Actions, send it back in one batch to the backend
 */

@Component({
  selector: 'app-project-actions-plan',
  templateUrl: './project-actions-plan.component.html',
  styleUrls: ['./project-actions-plan.component.scss']
})
export class ProjectActionsPlanComponent extends BaseEntity implements OnInit {
  @Input() projectPlan: ProjectPlan;

  actions: ProjectActionPlan[] = [];

  constructor(
    private modelFacotry: ModelFactory,
    private projectActionPlanService: PlanningProjectActionsService,
    private dialog: MatDialog
  ) {
    super()
  }

  ngOnInit() {
    // list action for Project
    this.projectActionPlanService.list(this.projectPlan.id).subscribe(resp => {
      this.actions = resp;

      this.setOrderNumbers();
    })
  }

  setOrderNumbers() {
    // function set the 'order' number form 0 up to ....
    // indpendenlty what is the current value we only relay on the current order
    this.actions.forEach((action, i) => {
      action.order = i;
    });
  }

  moveUp(action) {
    // order is the index!
    let index = action.order;

    if (index === 0) {
      return;
    }

    // add elemnt to the new position
    this.actions.splice(index - 1, 0, action);
    // delete the lement from the old postion
    this.actions.splice(index + 1, 1);

    this.setOrderNumbers();
  }

  moveDown(action) {
      // order is the index!
      let index = action.order;

      if (index === this.actions.length) {
        //last element
        return;
      }

      // add elemnt to the new position
      this.actions.splice( index + 2, 0, action);
      // delete the lement from the old postion
      this.actions.splice(index, 1);

      this.setOrderNumbers();
  }

  openActionPlanSearch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgSearchPlanActionComponent, dialogConfig);
    dialogRef.componentInstance.onClose.subscribe(action => {
      this.createProjectActionPlan(action)
      this.setOrderNumbers();
    });
  }

  openActionPlanCreate() {
    const dialogConfig = new MatDialogConfig();

    let action = this.modelFacotry.getNewModel(ActionPlan);
    dialogConfig.data = action;
    dialogConfig.width = '500px';

    const dialogRef = this.dialog.open(DlgEditPlanActionComponent, dialogConfig);
    dialogRef.componentInstance.onChange.subscribe(action => {
        this.createProjectActionPlan(action);
    });
  }

  createProjectActionPlan(action) {
    // based on an Action create a Project Action Plan
    let projectActionPlan = this.modelFacotry.getNewModel(ProjectActionPlan);
    projectActionPlan.setId(this.getNextId())
    projectActionPlan.setProject(this.projectPlan);
    projectActionPlan.setAction(action);

    this.actions.push(projectActionPlan)
    this.setOrderNumbers();
  }

  save() {
    this.projectActionPlanService.save(this.projectPlan.id, this.actions).subscribe(actions => {
      this.actions = actions
    })
  }

  delete(action) {
    // becuse order number always keep synced and one Action could be in the list mulitple times
    // the best if we delete it based on order (and not ID)
    this.actions = this.actions.filter(a => a.order != action.order);
    this.setOrderNumbers();
  }
}
