<ng-container *ngIf="data == enumNotificationType.Updated">
    <span>Frissítve</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Created">
    <span>Létrehozva</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Deleted">
    <span>Törölve</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Success">
    <span>Mentve</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Error">
    <span>Hiba történt</span>
</ng-container>

<!--password-->
<ng-container *ngIf="data == enumNotificationType.PassswordNotEqual">
    <span>A két jelszó nem egyforma!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordMinLength">
    <span>Minimum hossz 8!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordLowerCase">
    <span>Nem tartalmaz kisbetűt</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordUpperCase">
    <span>Nem tartalmaz nagybetűt</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordNumber">
    <span>Nem tartalmaz számot!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordSpecialChar">
    <span>Nem tartalmaz különleges karaktert!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.ProductInList">
    <span>Alap Termék már szerepel a listában</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.OrgMissing">
    <span>Cég Hiányzik</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.ItemMissing">
    <span>Termék Hiányzik</span>
</ng-container>