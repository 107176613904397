/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-person.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../contacts/persons/person/person.component.ngfactory";
import * as i3 from "../../../../contacts/persons/person/person.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../services/people.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "./dlg-edit-person.component";
import * as i8 from "../../../../services/modelFactory.service";
var styles_DlgEditPersonComponent = [i0.styles];
var RenderType_DlgEditPersonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditPersonComponent, data: {} });
export { RenderType_DlgEditPersonComponent as RenderType_DlgEditPersonComponent };
export function View_DlgEditPersonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-person", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_PersonComponent_0, i2.RenderType_PersonComponent)), i1.ɵdid(1, 114688, null, 0, i3.PersonComponent, [i4.ActivatedRoute, i5.PeopleService, i4.Router, i6.MatDialog], { people: [0, "people"], organisation: [1, "organisation"] }, { eventCreated: "eventCreated", eventDeleted: "eventDeleted", eventUpdated: "eventUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.people; var currVal_1 = _co.organisation; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_DlgEditPersonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-person", [], null, null, null, View_DlgEditPersonComponent_0, RenderType_DlgEditPersonComponent)), i1.ɵdid(1, 114688, null, 0, i7.DlgEditPersonComponent, [i8.ModelFactory, i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditPersonComponentNgFactory = i1.ɵccf("app-dlg-edit-person", i7.DlgEditPersonComponent, View_DlgEditPersonComponent_Host_0, {}, {}, []);
export { DlgEditPersonComponentNgFactory as DlgEditPersonComponentNgFactory };
