/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-actions-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/common";
import * as i12 from "../../../pipes/truncate.pipe";
import * as i13 from "./project-actions-bar.component";
var styles_ProjectActionsBarComponent = [i0.styles];
var RenderType_ProjectActionsBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectActionsBarComponent, data: {} });
export { RenderType_ProjectActionsBarComponent as RenderType_ProjectActionsBarComponent };
function View_ProjectActionsBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ProjectActionsBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.order + 1); _ck(_v, 0, 0, currVal_0); }); }
function View_ProjectActionsBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "bar ", _v.parent.context.$implicit.status, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ProjectActionsBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 9, "div", [], [[8, "className", 0]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.setSelectedAction(_v.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActionsBarComponent_3)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["Number", 2]], null, 0, null, View_ProjectActionsBarComponent_4)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActionsBarComponent_5)), i1.ɵdid(12, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getStatusText(_v.context.$implicit.status), ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_v.context.$implicit.status == "done"); var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = !_v.context.last; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "action ", _v.context.$implicit.status, " cursor"); _ck(_v, 1, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.name, 4)); _ck(_v, 9, 0, currVal_4); }); }
function View_ProjectActionsBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActionsBarComponent_2)), i1.ɵdid(3, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ProjectActionsBarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Nincsenek L\u00E9p\u00E9sek "]))], null, null); }
export function View_ProjectActionsBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.TruncatePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "project-actions-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActionsBarComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noActions", 2]], null, 0, null, View_ProjectActionsBarComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectActionsBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-actions-bar", [], null, null, null, View_ProjectActionsBarComponent_0, RenderType_ProjectActionsBarComponent)), i1.ɵdid(1, 114688, null, 0, i13.ProjectActionsBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectActionsBarComponentNgFactory = i1.ɵccf("app-project-actions-bar", i13.ProjectActionsBarComponent, View_ProjectActionsBarComponent_Host_0, { actions: "actions" }, { onSelect: "onSelect" }, []);
export { ProjectActionsBarComponentNgFactory as ProjectActionsBarComponentNgFactory };
