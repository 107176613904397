import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PageItemComponent } from 'src/app/items/page-item/page-item.component';
import { Item } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services/item.service';
import { cloneDeep } from 'lodash';
import { DlgEditItemComponent } from '../../edit/dlg-edit-item/dlg-edit-item.component';

@Component({
  selector: 'app-dlg-search-item',
  templateUrl: './dlg-search-item.component.html',
  styleUrls: ['./dlg-search-item.component.scss']
})
export class DlgSearchItemComponent {
  txtSearch: string = '';
  options = [];
  item: Item = new Item(null);
 
  onSave = new EventEmitter();

  constructor(
    private service: ItemService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchItemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.item) {
      this.item =  cloneDeep(data.item);
      this.txtSearch = this.item._product.nameHu
    }
  }

  search() {
    // serch is only happening if at leat 3 chars and has to pass xxx ms after the last typeing
    if (this.txtSearch.length < 3) {
      return;
    }
    
    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 20
    }};

    this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
    this.item = entity;
    this.txtSearch = entity._product.nameHu;
  }

  delete() {
    this.txtSearch = '';
    this.item = null;
  }

  save() {
      this.onSave.emit(this.item)
      this.dialogRef.close();
  }

  openItemCreateDlg() {
    let dialogConfig = new MatDialogConfig(); 
    
    let item = new Item(null)
    
    dialogConfig.data = {
      item: item
    };

    const dialogRef2 = this.dialog.open(DlgEditItemComponent, dialogConfig);

    dialogRef2.componentInstance.onChange.subscribe(item => {
      if (item) {
        this.item = item;
        this.txtSearch = item._product.nameHu;
      }
    });
  }

}
