import { Component, OnInit, Input } from '@angular/core';
import { Action } from 'src/app/models/action.model';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum'

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit {
  @Input() actions: Action[];

  // built to help easy to visualize the status
  data = [];

  constructor() { }

  ngOnInit() {
      this.actions.forEach(action => {
          let a = {
            name: action.name,
            status: action.status,
            class: this.getClass(action)
          }

          this.data.push(a);
      });
  }

  getClass(action) {
    let classes = []

    // status
    if (action.status == EnumActionStatus.NotStarted) {
        classes.push('not-started');
    } else if (action.status == EnumActionStatus.InProgress) {
        classes.push('in-progress');
    } else if (action.status == EnumActionStatus.Blocked) {
      classes.push('blocked');
  } else if (action.status == EnumActionStatus.Done) {
        classes.push('done');
    } else if (action.status == EnumActionStatus.Cancelled) {
        classes.push('cancelled');
    }

    return classes.join(' ');
  }

}
