import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Project } from 'src/app/models/project.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProjectService } from 'src/app/services/project.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DlgSearchProjectTemplateComponent } from 'src/app/shared/dialogs/search/dlg-search-project-template/dlg-search-project-template.component';
import { DlgSearchOrganisationComponent } from 'src/app/shared/dialogs/search/dlg-search-organisation/dlg-search-organisation.component';
import { DlgSearchUserComponent } from 'src/app/shared/dialogs/search/dlg-search-user/dlg-search-user.component';
import { ProjectTypeService } from 'src/app/services/projectType.service';
import { ProjectType } from 'src/app/models/projectType.model';

/**
 * EDIT option of the project (setup and manage the based data)
 * - not for update the current state fo the project
 */

@Component({
  selector: 'app-project-setup',
  templateUrl: './project-setup.component.html',
  styleUrls: ['./project-setup.component.scss']
})
export class ProjectSetupComponent extends BaseEntity implements OnInit {
  project: Project = null;
  projectTemplates:any;
  textSearchTemplate = '';
  isLoading = false;
  errorMsg: string;
  projectTypes: ProjectType[] = null;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelFactory: ModelFactory,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private projectTypeService: ProjectTypeService
  ) {
    super();

    this.project = this.modelFactory.getNewModel(Project);
    // normal importance
    this.project.importance=3;
  }

  ngOnInit() {
    this.projectTypeService.list({params: {limit: 1000}}).subscribe(res => {
      this.projectTypes = res.results;
      this.loadProject();
    })
  }

  loadProject() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.projectService.get(id).subscribe(res => {
        this.project = res;
      });
    }
  }

  save() {
    (this.project.id) ? this.update() : this.create();
  }

  create() {
    this.projectService.create(this.project).subscribe(res => {
      this.project = res;
    })
  }

  update() {
    this.projectService.update(this.project).subscribe(res => {
      this.project = res;
    })
  }

  delete() {
    this.projectService.delete(this.project).subscribe(() => {
       this.router.navigate(['../../projects'],  {
        relativeTo: this.route  
      });
    });
  }

  openTemplateSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.project.project_template) ? this.project._projectTemplate.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchProjectTemplateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(project => {
      if (project) {
        this.project.project_template = project.id;
        this.project._projectTemplate = project;
        this.project.project_type = project.project_type;
        this.project._projectType = project._projectType;
      } else if (project == '') {
        this.project.project_template = null;
        this.project._projectTemplate = null;
        this.project.project_type = null;
        this.project._projectType = null;
      }
    });
  }

  openProjectPage() {
    this.router.navigate(['../project'],  {
      queryParams: {id: this.project.id}, 
      relativeTo: this.route  
    });
  }

  openOrgSearchDlg() {
    // open search dialog to get another company
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.project.organization) ? this.project._organization.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchOrganisationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(organisation => {
      if (organisation) {
          this.project.organization = organisation.id;
          this.project._organization = organisation;
      } else if (organisation == '') {
        // if empty string set back we delete the organisation
        this.project.organization = null;
        this.project._organization = null;
      }
    });
  }

  openAssigneeSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = (this.project.assignee) ? this.project._assignee.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(user => {
      if (user) {
          this.project.assignee = user.id;
          this.project._assignee = user;
      } else if (user == '') {
        this.project.assignee = null;
        this.project._assignee = null;
      }
    });
  }

}
