export class RoleAccess {
    id: number | string;
    role: number;
    module: string;
    accessLevelMyself: number = 0;
    accessLevelOthers: number = 0;
    
    created?: Date;
    updated?: Date;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.role = data.role;
           this.module = data.module;
           this.accessLevelMyself = data.accessLevelMyself;
           this.accessLevelOthers = data.accessLevelOthers;
           
           if (data.created) {
            this.created =  new Date(data.created);
           }

           if (data.updated) {
            this.updated = new Date(data.updated);
           }  

           if (data.creator) {
            this.creator = data.creator;
           }

           if (data.lastModifier) {
            this.lastModifier = data.lastModifier;
           }
       }
   }

   setModule(module: string) {
    this.module = module;
}

    setRoleId(id: number) {
        this.role = id;
    }

    setId(id: string) {
        // set string based id for the new elements before sent to the server
        this.id = id;
    }

}