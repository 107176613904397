import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { PdfSnippetService } from 'src/app/services/pdfSnippet.service';
import { PdfSnippet } from 'src/app/models/pdfSnippet.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';

@Component({
  selector: 'app-dlg-edit-pdf-snippet',
  templateUrl: './dlg-edit-pdf-snippet.component.html',
  styleUrls: ['./dlg-edit-pdf-snippet.component.scss']
})

export class DlgEditPdfSnippetComponent extends BaseEntity implements OnInit {
  type = null;
  language = EnumLanguage.Hu;
  snippets: PdfSnippet[];

  onSelect = new EventEmitter();

  constructor(
    private pdfSnippetService: PdfSnippetService,
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditPdfSnippetComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super();

    this.type = data.type;
    this.language = data.language

    this.fetchShippets()
  }

  ngOnInit() {
    
  }

  fetchShippets() {
    // based on the snippet enum fetch the stored snippets
    this.pdfSnippetService.list(this.type).subscribe(res => {
      this.snippets = res;
    })
  }

  save(snippet) {
    (typeof snippet.id === 'string') ? this.create(snippet) : this.update(snippet);
  }

  create(snippet: any) {
    this.snippets = this.snippets.filter(s => s.id !== snippet.id);
    this.pdfSnippetService.create(snippet).subscribe(s => {
      this.snippets.unshift(s);
    })
  }

  update(snippet) {
    this.pdfSnippetService.update(snippet).subscribe(s => {
      let snippet = this.snippets.filter(s => s.id == snippet.id);
      this.snippets.unshift(s);
    })
  }

  delete (snippetId) {
    if (typeof snippetId === 'string') {
      // delete only from the list
      this.snippets = this.snippets.filter(s => s.id !== snippetId);
    } else {
      // delete from the server
      let snippet = this.snippets.find(s => s.id == snippetId);
      this.pdfSnippetService.delete(snippet).subscribe(res => {
        this.snippets = this.snippets.filter(s => s.id !== snippet.id);
      });
    }
  }

  select(snippet) {
    // send the selected snippet back
    let text = snippet.snippetHu;
    if (this.language == EnumLanguage.En) {
      text = snippet.snippetEn;
    }

    this.onSelect.emit(text);

    this.dialogRef.close();
  }

  add() {
    let snippet = this.modelFactory.getNewModel(PdfSnippet);
    snippet.type = this.type;
    snippet.id = this.getNextId();
    snippet.expanded = true;
    this.snippets.unshift(snippet);
  }

}
