import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatPaginator } from '@angular/material';
import { environment } from '../../environments/environment';
import { UrlParamService } from '../services/urlParam.service';
import { CategoryService } from '../services/category.service';
import { BaseList } from '../common/baseClasses/baseList';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [UrlParamService]
})
export class CategoriesComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  displayedColumns = ['connected', 'name', 'parent'];

  constructor(
    private categoryService: CategoryService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.categoryService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  open(entity) {
    this.router.navigate(['category'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  lookup() {
    this.doSearch();
  }
}
