import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/models/role.model';
import { RolesService } from 'src/app/services/roles.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent extends BaseEntity implements OnInit {
  role: Role = null

  editMode: boolean = false;

  name: string;
  
  constructor(
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private router: Router,
    private modelFactory: ModelFactory
  ) {
    super()
    
    this.role = modelFactory.getNewModel(Role)
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.rolesService.get(id).subscribe(role => {
        this.role = role;
        this.editMode = true;
      });
    }
  }

  save() {
    (this.role.id) ? this.update() : this.create();
  }

  create() {
    this.rolesService.create(this.role).subscribe( role => {
      this.role = role;
      this.editMode = true;

      //  navigate to the page
      this.router.navigate(['./'], {
        queryParams: {id: role.id}, 
        relativeTo: this.route  
      });
    })
  }

  update() {
    this.rolesService.update(this.role).subscribe(role => {
      this.role = role;
    })
  }

  delete() {
    this.rolesService.delete(this.role).subscribe(() => {
      this.router.navigate(['../'], {
        relativeTo: this.route  
      });
    })
  }

}
