<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Termékek</a></li> 
    <ng-container *ngIf="item.product">
        <li>{{item._product.nameHu}} {{item.serialNumber}}</li>  
    </ng-container> 
</ul>


<div class="flex padding-10-0">
    <div class="full text-right">
        <button mat-stroked-button color="primary" (click)="createProductDlg()">Alap Termék Létrehozása</button>
    </div>
</div>
<div class="section">
    <div class="header">
        <div class="title"><span>Termék</span></div>
    </div>
    <app-item 
    (eventCreated)="created($event)"
    (eventDeleted)="deleted($event)" 
    (eventUpdated)="updated($event)"
    [item]="item"></app-item>
</div>
