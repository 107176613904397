<div class="padding-20-0 text-center">
    Alap Termék Keresés
</div>
<ng-container *ngIf="product">
    <div class="text-secondary text-small padding-20-0">
        <span>{{product.nameHu}}</span>
    </div>
</ng-container>
<form>
    <mat-form-field appearance="outline">
        <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Töröl">delete</mat-icon>
        <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés..."  [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
            <div class="truncate">{{option.nameHu}}</div>
            <div>{{option.serialNumber}}</div>      
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
