export class People {
    id: Number = null;
    firstName: String = null;
    lastName: String = null;
    role: String = null;
    organization?: Number = null;
    //repesentation of data
    _organization?: any = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

     //extra readonly informaion - email and phone
     emails? = [];
     phones? = [];

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.role = data.role;
            
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

            if ('organization' in data) {
                this.organization = data.organization;
            }

            if ('_organization' in data) {
                this._organization = data._organization;
            }

            if ('emails' in data) {
                this.emails = data.emails;
            }
    
            if ('phones' in data) {
                this.phones = data.phones;
            }
        } 

    }

    get fullName() {
        return this.lastName + ' ' + this.firstName;
    }
  }

export class PeopleEmail {
    id: Number | String = null;
    name: String = null;
    email: String = null;
    contact: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.email = data.email;
            this.contact = data.organization;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setPeopleId(id: number) {
        this.contact = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummy) id
        this.id = id;
    }
}

export class PeoplePhone {
    id: Number | String = null;
    name: String = null;
    phone: String = null;
    contact: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.phone = data.phone;
            this.contact = data.contact;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setPeopleId(id: number) {
        this.contact = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummygit ) id
        this.id = id;
    }
}