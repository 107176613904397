import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
/**
 * Check if the User has access to the Module
 * - auth guard has to be called first
 */
export class ModuleGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivateChild(route, state) {
        // check the module access based on the user access level
        let module = route.data.module;
        if (module == 'open') {
            // it is an open page like dashboard
            return true;
        }
        if (!this.authService.user) {
            // we still load the user
            return false;
        }
        if (this.authService.user.admin) {
            // admin
            return true;
        }
        else {
            if (this.authService.accessLevel.hasOwnProperty(module)) {
                return this.authService.accessLevel[module].access;
            }
            // module not found
            return false;
        }
    }
}
ModuleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleGuard_Factory() { return new ModuleGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: ModuleGuard, providedIn: "root" });
