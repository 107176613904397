export class PdfSnippet {
    id: number | string = null;
    type: string = null;
    snippetHu: number = null;
    snippetEn: string = null;

    assignee?: number = null;
    _assignee?: any = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 
    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.type = data.type;
            this.snippetHu = data.snippetHu;
            this.snippetEn = data.snippetEn;

            this.assignee = data.assignee;
            this._assignee = data._assignee;

            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
