import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumLanguage } from 'src/app/enums/languages.enum';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseEntity implements OnInit {
  user: User = null;
  editMode: boolean = false;
  enumLanguage = EnumLanguage;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private router: Router,
    private modelFactory: ModelFactory
  ) {
    super();
    
    this.user = this.modelFactory.getNewModel(User);
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.usersService.get(id).subscribe(user => {
        this.user = user;
        this.editMode = true;
      });
    }
  }

  save() {
    (this.user.id) ? this.update() : this.create();
  }

  create() {
    this.usersService.create(this.user).subscribe( user => {
      this.user = user;
      this.editMode = true;

      //  navigate to the page
      this.router.navigate(['./'],  {
        queryParams: {id: user.id}, 
        relativeTo: this.route  
      });
    })
  }

  update() {
    this.usersService.update(this.user).subscribe(user => {
      this.user = user;
    })
  }

  delete () {
    this.usersService.delete(this.user).subscribe(user => {
      //  navigate to the page
      this.router.navigate(['../'],  {
        relativeTo: this.route  
      });
    })
  }

  newPassoword() {
    this.usersService.newPasswordRequest(this.user).subscribe();
  }

  verifyEmail() {
    this.usersService.verifyEmailRequest(this.user).subscribe();
  }

}
