import { Product } from './product.model'

export class Item {
    id: Number = null;
    product: Number = null;
    _product?: any = null;
    parent: Number = null;
    _parent: Item = null;
    serialNumber: String = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.product  = data.product;
            this.serialNumber = data.serialNumber;
            this.parent = data.parent;

            if ('_product' in data) {
                this._product = new Product(data._product);
            }

            if ('_parent' in data) {
                this._parent = new Item(data._parent);
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  
    }
  }
