import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



@Component({
  selector: 'app-dlg-error',
  templateUrl: './dlg-error.component.html',
  styleUrls: ['./dlg-error.component.scss']
})
export class DlgErrorComponent implements OnInit {
  error:any = null;
  now = new Date()

  constructor(
    public dialogRef: MatDialogRef<DlgErrorComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
      this.error = data.error;
  }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  isArray(obj : any ) {
    // helper function in the template to decide the obj is array
    return Array.isArray(obj)
 }

}


