<form #f="ngForm">
    <p *ngIf="!isDlg">
        <ng-container *ngIf="category.opencartId > 0; else noConection">
            <mat-icon class="text-green" matTooltip="Összekapcsolava egy Gyártóval a weblapon">power</mat-icon>
        </ng-container>
        <ng-template #noConection>
            <mat-icon class="text-secondary" matTooltip="Nincs Összekapcsolava egy Gyártóval sem a weblapon">power_off</mat-icon>
        </ng-template>
    </p>
    <p *ngIf="!isDlg"> 
        <mat-form-field appearance="outline">
            <mat-label>Weblap ID:</mat-label>
            <input matInput name="opecartId" [(ngModel)]="category.opencartId" autocomplete="off">
            <mat-icon matSuffix matTooltip="Ha a Kategóriához van OpenCart ID hozzárendelve akkor az adatok a Weblapon kell módosítani először. Majd frissíteni a rendszer adatokat. Adatok szerkeszése nem lehetséges. Ha törlöd az ID-t a kapcsolat is törlődik.">info</mat-icon>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - HU</mat-label>
            <input matInput name="nameHu" [(ngModel)]="category.nameHu" [disabled]="category.opencartId > 0" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - EN</mat-label>
            <input matInput name="nameEn" [(ngModel)]="category.nameEn" [disabled]="category.opencartId > 0" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Kép</mat-label>
            <input matInput name="imageUrl" [(ngModel)]="category.imageUrl" [disabled]="category.opencartId > 0" autocomplete="off">
            <mat-icon matSuffix matTooltip="Teljes url: http://....">info</mat-icon>
            <span matPrefix><img style="width: 40px;" [src]="category.imageUrl" alt=""></span>
        </mat-form-field>
        
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Szülő Kategória</mat-label>
            <ng-container *ngIf="category.parent; else noParent">
                <input matInput name="parent" disabled [(ngModel)]="category._parent.nameHu"  autocomplete="off">
            </ng-container>
            <ng-template #noParent>
                <input matInput name="parent" disabled [(ngModel)]="category.parent" autocomplete="off">
            </ng-template>
            <mat-icon class="pointer" (click)="openCategorySearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
        </mat-form-field>
    </p>
    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="category.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="category"></app-quick-log-info>           
            </span>
        </ng-container>
    </p>
</form>
