import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() onFileSelect = new EventEmitter<any>();

  uploadForm: FormGroup; 

  fileToUpload: File = null;

  constructor() { }

  ngOnInit() {
  }

  handleFileInput(files: any) {
    if (files.length > 0) {
      this.fileToUpload = files.item(0);

      let formData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);

      this.onFileSelect.emit(formData);
    }
}

}
