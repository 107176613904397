import { Component, Input, OnInit } from '@angular/core';
import { Role } from 'src/app/models/role.model';
import { RoleAccess } from 'src/app/models/roleAccess.model';
import { RoleAccessService } from 'src/app/services/roleAccess.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';

@Component({
  selector: 'app-role-access',
  templateUrl: './role-access.component.html',
  styleUrls: ['./role-access.component.scss']
})
export class RoleAccessComponent extends BaseEntity implements OnInit {
  @Input() role: Role;

  roleAccesses: RoleAccess[] = [];

  modules = [];
  // list of moudules where access is not set yet
  availableModuels = null;

  // reset the Module Select to the firt one
  defaultSelect = '?';

  constructor(
    private roleAccessService: RoleAccessService,
    private modelFactory: ModelFactory
  ) {
    super()
  }

  ngOnInit() {
    let params = {role: this.role.id};
    this.roleAccessService.list(params).subscribe(resp => {
      this.roleAccesses = resp;

       // set the list of modules which are not already in use
      this.updateAvailableModuels();
    })

    this.modules = this.roleAccessService.getModules();
  }

  save(roleAccess) {
    if (typeof roleAccess.id === 'string') {
      // create
      this.create(roleAccess);
    } else {
      this.update(roleAccess);
    }
  }

  create(roleAccess) {
    this.roleAccessService.create(roleAccess).subscribe(res => {
      // fitler out the dummy email
      this.roleAccesses = this.roleAccesses.filter(e => e.module != res.module);
      // add the newly created to the list
      this.roleAccesses.push(res)
    })
  }

  update(roleAccess) {
    this.roleAccessService.update(roleAccess).subscribe()
  }

  add($event) {
    // add new element to the list

    if (!$event.value) {
      return;
    }

    let roleAccess = this.modelFactory.getNewModel(RoleAccess)
    roleAccess.setRoleId(this.role.id);
    roleAccess.setModule($event.value);
    roleAccess.setId(this.getNextId());
    this.roleAccesses.push(roleAccess);

    this.updateAvailableModuels()
  }

  delete(roleAccess) {
    if (typeof roleAccess.id === 'string') {
      // delete only from the list
      this.roleAccesses = this.roleAccesses.filter(ra => ra.module !== roleAccess.module);
      this.updateAvailableModuels();
    } else {
      // delete from the server
      this.roleAccessService.delete(roleAccess).subscribe(res => {
        this.roleAccesses = this.roleAccesses.filter(ra => ra.id !== roleAccess.id);
        this.updateAvailableModuels();
      });
    }
  }

  updateAvailableModuels() {
    // the selected list of Moduels changed -> update available modules for the dropdown

    //get a fresh list
    this.availableModuels = JSON.parse(JSON.stringify(this.modules));

    this.roleAccesses.forEach(ra => {
      if (this.availableModuels.indexOf(ra.module) > -1) {
        // get rid of from the list
        this.availableModuels.splice(this.availableModuels.indexOf(ra.module), 1);
      }
    });
  }

}
