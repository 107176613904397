import { HttpParams } from '@angular/common/http';

export class Utils {
    static getParams(params: object) {
        // transform an object to http request params
        // set as the key and values
        if (!params) {
            return
        }

        let httpparam = new HttpParams();

        for (let key in params) {
            httpparam = httpparam.append(key, params[key]);
        }
        
        return httpparam
    }
}