import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Action } from 'src/app/models/action.model';
import { Project } from 'src/app/models/project.model';
import { ActionService } from 'src/app/services/action.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-dlg-search-action',
  templateUrl: './dlg-search-action.component.html',
  styleUrls: ['./dlg-search-action.component.scss']
})
export class DlgSearchActionComponent implements OnInit {
  txtSearch: string = '';
  projectOptions = [];
  actionOptions = [];
  timeout = null;

  project: Project = null;
  action: Action = null;

  constructor(
    private projectService: ProjectService,
    private actionService: ActionService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchActionComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
    // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let options = {params:{
      search: this.txtSearch,
      limit: 50,
      archived: false
    }};

    this.projectService.list(options).subscribe(data => {
      this.projectOptions = data.results
     });
  }

  selectProject(project) {
    this.project = project;

    if (this.project.actions.length > 0) {
      this.actionOptions = this.project.actions;
    }
    
    this.txtSearch = project.name;
  }

  selectAction(action) {
    if (!action) {
      return;
    }

    setTimeout(() => {
      this.dialogRef.close(action);
    }, 200);
     
  }

  select(projectTemplate) {
    if (!projectTemplate) {
      return;
    }
    this.txtSearch = projectTemplate.name;

    setTimeout(() => {
      this.dialogRef.close(projectTemplate);
    }, 200);
     
  }

}
