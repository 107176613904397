import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '../shared/utils';
import { Category } from '../models/category.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class CategoryService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL_LIST = '/categories/';
        // list items
        this.dataSource$ = new BehaviorSubject([]);
        this.data = this.dataSource$.asObservable();
    }
    list(options = null) {
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        // set options
        let base = { url: null, params: null };
        if (options) {
            options = Object.assign({}, base, options);
        }
        else {
            options = base;
        }
        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
            url = options.url;
        }
        else {
            url = this.baseUrl + this.URL_LIST;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }
        return this.http.get(url, { params }).pipe(map(res => {
            res.results = res.results.map(i => new Category(i));
            return res;
        }));
    }
    get(id) {
        let url = this.baseUrl + this.URL_LIST + id + '/';
        return this.http.get(url).pipe(map(res => new Category(res)));
    }
    create(payload) {
        let url = this.baseUrl + this.URL_LIST;
        return this.http.post(url, payload).pipe(map(result => new Category(result)), tap(res => { this.notificationService.created(); }));
    }
    update(payload) {
        let url = this.baseUrl + this.URL_LIST + payload.id + '/';
        return this.http.put(url, payload).pipe(map(res => new Category(res)), tap(res => { this.notificationService.updated(); }));
    }
    delete(payload) {
        let url = this.baseUrl + this.URL_LIST + payload.id + '/';
        return this.http.delete(url, payload).pipe(tap(res => { this.notificationService.deleted(); }));
    }
}
CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: CategoryService, providedIn: "root" });
