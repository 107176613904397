import { EnumLanguage } from "../enums/languages.enum";

export class TaskInterest {
    id: number = null;
    name: string = null;
    description: string = null;
    refNumber: string = null;
    importance: number = null;
    archived: boolean = false;
    status: string = null;
    condition: string = null;
    language: string | EnumLanguage = null;
    open: boolean = false;

    assignee: number = null;
    _assignee: any = null;

    organization: number = null;
    _organization: any = null;

    action: number = null;
    _action: any = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name  = data.name;
            this.description = data.description;
            this.archived = data.archived;
            this.refNumber = data.refNumber;
            this.importance = data.importance;
            this.status = data.status;
            this.condition = data.condition;
            this.language = data.language;
            this.open = data.open;

            this.organization = data.organization;
            if ('_organization' in data) {
                this._organization = data._organization;
            }
           
            this.assignee = data.assignee;
            if ('_assignee' in data) {
                this._assignee = data._assignee;
            }

            this.action = data.action;
            if ('_action' in data) {
                this._action = data._action;
            }
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
