export class Project {
    id: number = null;
    name: string = null;
    description: string = null;
    closed: Boolean = false;
    refNumber: string = null;
    importance: number = null;

    assignee: number = null;
    _assignee: any = null;

    organization: number = null;
    _organization: any = null;

    project_template: number = null;
    _projectTemplate: any = null;

    project_type: number = null;
    _projectType: any = null;

    actions: any = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name  = data.name;
            this.description = data.description;
            this.closed = data.closed;
            this.refNumber = data.refNumber;
            this.importance = data.importance;

            this.organization = data.organization;
            if ('_organization' in data) {
                this._organization = data._organization;
            }
           
            this.project_template = data.project_template;
            if ('_projectTemplate' in data) {
                this._projectTemplate = data._projectTemplate;
            }

            this.project_type = data.project_type;
            if ('_projectType' in data) {
                this._projectType = data._projectType;
            }
        
            this.actions = data.actions;

            this.assignee = data.assignee;
            this._assignee = data._assignee;

            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }

        }  
    }
  }
