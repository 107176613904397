import { Component, Input, OnInit } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumTaskPdfGenerationStatus } from 'src/app/enums/tasks/taskPdfGenerationStatus.enum';
import { Organisation } from 'src/app/models/organisation';
import { TaskInterest } from 'src/app/models/taskInterest.model';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TaskInterestPdfService } from 'src/app/services/taskInterestPdf.service';
import { forkJoin } from 'rxjs';
import { PeopleService } from 'src/app/services/people.service';
import { People } from 'src/app/models/people.model';
import { DlgSearchOrgContactComponent } from 'src/app/shared/dialogs/search/dlg-search-org-contact/dlg-search-org-contact.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EnumPdfSnippet } from 'src/app/enums/pdfSnippets.enum';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumCurrency } from 'src/app/enums/currencies.enum';
import { TaskInterestProductService } from 'src/app/services/taskInterestProduct.service';
import { TaskInterestProduct } from 'src/app/models/taskInterestProduct.model';

@Component({
  selector: 'app-task-interest-pdfs',
  templateUrl: './task-interest-pdfs.component.html',
  styleUrls: ['./task-interest-pdfs.component.scss']
})
export class TaskInterestPdfsComponent extends BaseEntity implements OnInit {
  @Input() task: TaskInterest
  pdfs: any = [];
  // set to true if the pdf creation is clicked to load all of the data what need to setup the PDF}}uu
  createPdf = false;
  enumTaskPdfGenerationSatus = EnumTaskPdfGenerationStatus;
  organizastion: Organisation;
  products: TaskInterestProduct[] =[];
  contacts: People[];
  EnumPdfSnippet = EnumPdfSnippet;
  EnumLanguage = EnumLanguage;
  EnumCurrency = EnumCurrency;

  // contains the data to generate the pdf
  pdfData = {
    language: 'hu',
    application: false, // palyazatoz
    name: null,
    introduction: '',
    priceContains: '',
    deliveryTime: '',
    payMethod: '',
    priceValid: '',
    guarantee: '',
    finish: '',
    vatPerc: 27,
    currency: EnumCurrency.HUF,
    products: []
  };

  constructor(
    private organisationService: OrganisationService,
    private taskInterestPdfService: TaskInterestPdfService,
    private taskInterestProductService: TaskInterestProductService,
    private peopleService: PeopleService,
    private dialog: MatDialog
  ) {
    super()
  }

  ngOnInit() {
    if (this.task) {
      this.pdfData.language = this.task.language;
    }

    this.loadPdfs();
  }

  loadPdfs() {
    let params = {task: this.task.id, limit: 1000};
    this.taskInterestPdfService.list({params}).subscribe(resp => {
      this.pdfs = resp.results;
    })
  }

  reLoadPdfs() {
    this.loadPdfs();
  }

  pdfGeneration() {
    let payload = {
      taskId: this.task.id,
      data: this.pdfData
    };
    
    this.taskInterestPdfService.create(payload).subscribe(() => {
      // referesh pdf list.. change tab
    });
  }

  onTabChange(event) {
    if (event.index === 1) {
      if (this.createPdf) {
        // thata already loadedj
        return;
      }
      this.loadDataForCreation();
    }
  }

  loadDataForCreation() {
    let params = null;
    
    // load the data which need for the pdf creation
    let org = this.organisationService.get(this.task.organization);

    params = { limit: 1000, organisation: this.task.organization};
    let people = this.peopleService.list({params});
    
    params = {task: this.task.id, limit: 1000};
    let products = this.taskInterestProductService.list({params});

    forkJoin([org, people, products]).subscribe(res => {
      this.organizastion = res[0];
      this.contacts = res[1].results;
      this.products = res[2].results;
      this.createPdf = true;

      this.updatePdfDataWithProducts();
    });
  }

  openSearchOrgContactDialog() {
    // open the dialog to select contact information of the pdf
    const dialogConfig = new MatDialogConfig();
        
    dialogConfig.data = {
      organisation: this.organizastion,
      type: 'name',
      language: this.pdfData.language
    };

    const dialogRef = this.dialog.open(DlgSearchOrgContactComponent, dialogConfig);

    dialogRef.componentInstance.onSelect.subscribe(data => {
      this.pdfData.name = data;
    })
  }

  updatePdfDataWithProducts() {
    // add Product data to the data structure
    this.products.forEach(product => {
      let p = {
        id: product.id,
        categoryId: product._product.category,
        amount: product.amount,
        nameEn: product._product.nameEn,
        nameHu: product._product.nameHu,
        type: product._product.type,
        // SPECIAL FIELD FOR HE PDF
        price: null,
        year: null, //gyartasi ev
        customTarifNumber: null,
        countryOfOrigin: null
      }

      this.pdfData.products.push(p);
    });
  }
}
