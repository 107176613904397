import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
/**
 * Only create new models with this facotry!!!
 * - populate the creator property to work with the security
 */
export class ModelFactory {
    constructor(authService) {
        this.authService = authService;
    }
    getNewModel(model) {
        const instance = new model();
        instance.creator = this.authService.user;
        // at creation the assignee and creator are the same
        instance.assignee = this.authService.user.id;
        instance._assignee = this.authService.user;
        return instance;
    }
}
ModelFactory.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModelFactory_Factory() { return new ModelFactory(i0.ɵɵinject(i1.AuthService)); }, token: ModelFactory, providedIn: "root" });
