import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Item } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services/item.service';
import { DlgEditProductComponent } from 'src/app/shared/dialogs/edit/dlg-edit-product/dlg-edit-product.component';
import { DlgSearchItemComponent } from 'src/app/shared/dialogs/search/dlg-search-item/dlg-search-item.component';
import { DlgSearchProductComponent } from 'src/app/shared/dialogs/search/dlg-search-product/dlg-search-product.component';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent extends BaseEntity implements OnInit {
  @Input() item: Item;
  @Input() isDlg: boolean = false;

  editMode: boolean = false;

  @Output() eventCreated: EventEmitter<Item> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<Item> = new EventEmitter();

  constructor(
    private itemService: ItemService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
  }

  save() {
    (this.item.id) ? this.update() : this.create();
  }
  
  create() {
    this.itemService.create(this.item).subscribe(res => {
      this.item = res;
      this.editMode = true;

      this.eventCreated.emit(this.item);
    })
  }

  update() {
    this.itemService.update(this.item).subscribe(res => {
      this.item = res;
      this.eventUpdated.emit(this.item);
    })
  }

  delete() {
      this.itemService.delete(this.item).subscribe(() => {
        this.eventDeleted.emit();
      });
  }

  openProductSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = (this.item.product) ? this.item._product.nameHu : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchProductComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(product => {
      if (product) {
          this.item.product = product.id;
          this.item._product = product;
      } else if (product == '') {
        this.item.product = null;
        this.item._product = null;
      }
    });
  }

  openItemSearchDlg() {
    let dialogConfig = new MatDialogConfig(); 
    
    let parent = (this.item.parent) ? this.item._parent : null;
    
    dialogConfig.data = {
      item: parent
    };

    const dialogRef = this.dialog.open(DlgSearchItemComponent, dialogConfig);

    dialogRef.componentInstance.onSave.subscribe(item => {
      if (item) {
        this.item.parent = item.id;
        this.item._parent = item;
      } else if (item == '') {
        this.item.parent = null;
        this.item._parent = null;
      }
    });
  }
}
