export class ActionPlan {
    id: Number = null;
    name: string = null;
    description: string = null;
    taskType: string = null;
    archived: Boolean = false;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 
    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name  = data.name;
            this.description = data.description;
            this.taskType = data.taskType;
            this.archived = data.archived;

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  
    }
  }
