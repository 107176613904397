<ul class="breadcrumb"> 
    <li>Termékek</li>  
</ul>

<div class="flex"> 
    <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeType($event)">
            <mat-radio-button [checked]="type === 'machine'" value="machine">Gép</mat-radio-button>
            <mat-radio-button [checked]="type === 'part'" value="part">Alkatrész</mat-radio-button>
            <mat-radio-button [checked]="type === 'service'" value="service">Szolgáltatás</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <button mat-mini-fab  color="secondary" [routerLink]="['../../products']" matTooltip="Alap Termékek"> 
            <mat-icon>kitchen</mat-icon>
        </button>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../categories']"  matTooltip="Termék Kategóriák"> 
                <mat-icon>workspaces</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../manufacturers']"  matTooltip="Gyártók"> 
                <mat-icon>domain</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['item']"  matTooltip="Új Termék"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="manufacturer">
        <mat-header-cell *matHeaderCellDef>Gyártó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row._product.manufacturer">
                <img src="{{row._product._manufacturer.imageUrl}}" loading="lazy">
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nameHu">
        <mat-header-cell *matHeaderCellDef>Termék</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)" matTooltip="{{row._product.nameEn}}">{{row._product.nameHu}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="serialNumber">
        <mat-header-cell *matHeaderCellDef>Sorozat Szám</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="badge badge-pill badge-warning">{{row.serialNumber}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="productType">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-icon-item-type [itemType]=row._product.type></app-icon-item-type>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="parent">
        <mat-header-cell *matHeaderCellDef>Beépítve</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.parent">
                <span class="link" matTooltip="{{row._parent._product.nameEn}}" (click)="open(row._parent)" >{{row._parent._product.nameHu}}</span>
                - <span class="badge badge-pill badge-warning">{{row._parent.serialNumber}}</span>
            </ng-container>
        </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>
