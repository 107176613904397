<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Megkersések</a></li> 
    <ng-container *ngIf="task">
        <li>{{task.name}}</li>  
    </ng-container> 
</ul>

<div class="section" style="border-top: 2px solid #ffffff" [style.border-color]="borderColor">
    <ng-container *ngIf="task">
        <div class="flex">
            <div class="full">
                <h5 >{{task.name}}</h5>
                <p class="text-secondary padding-10-0" matTooltip="{{task.description}}">{{task.description | truncate:10}}</p>
            </div>
            <div class="padding-r-10">
                <form #f="ngForm">
                    <mat-form-field appearance="outline">
                        <mat-label>Státusz</mat-label>
                        <mat-select name="status" [(ngModel)]="task.status" (selectionChange)="statusChanged()">
                            <mat-option [value]="enumTaskInterestStatus.WaitingOffer">Ajánlatra Vár</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.WaitingCallback">Visszahívásra Vár</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.WaitingDecision">Döntésre Várunk</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.PersonalMeeting">Személyes Találozó</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.TestWork">Teszt Megmunkálás</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.OfferSent">Ajánlat Elküldve</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.Ordered">Megrendelve</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.KeepInTouch">Kapcsolattartás</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.WaitingPriceCut">Kevezményre Vár</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.BoughtSomebodyElse">Mástól Vett</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.Sold">Eladva</mat-option>
                            <mat-option [value]="enumTaskInterestStatus.NotNow">Nem Aktuális</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            <span>
                <ng-container *ngIf="!task.archived">
                    <button mat-flat-button color="accent"  (click)="setStatus('archived')"  matTooltip="Feladat Aktív, kattints hogy archiváld">
                        Aktív
                    </button>
                </ng-container>
                <ng-container *ngIf="task.archived">
                    <button mat-flat-button  color="warn" (click)="setStatus('active')"  matTooltip="Feladat Archivált, kattints hogy aktiváld">
                        Archivált
                    </button>
                </ng-container>
            </span> 
        </div>
        <div class="padding-10-0">
            <span class="badge badge-info">{{task.refNumber}}</span>
        </div>
        <ng-container *ngIf="task.importance">
            <div class="flex">
                <span class="text-small text-secondary padding-r-10">Fontosság:</span>
                <app-project-importance [importance]="task.importance"></app-project-importance>
            </div>   
        </ng-container>
        <ng-container *ngIf="task.condition">
            <div class="flex padding-10-0">
                <span class="text-small text-secondary padding-r-10">Gép Állapot:</span>
                <div class="text-small text-secondary">
                    <ng-container *ngIf="task.condition == 'new'">Új</ng-container>
                    <ng-container *ngIf="task.condition == 'used'">Használt</ng-container>
                    <ng-container *ngIf="task.condition == 'both'">Bármelyik</ng-container>
                </div>
            </div>   
        </ng-container>
        <div class="flex text-small text-secondary">
            <span class="padding-r-10">Felelős:</span>
            <ng-container *ngIf="task.assignee">
                <app-avatar [user]="task._assignee"></app-avatar>
            </ng-container>
        </div>   
        <ng-container *ngIf="task.organization">
            <div class="text-secondary text-small flex">
                <span class="padding-r-10">Cég:</span>
                <app-bottom-organisation [data]="task.organization"></app-bottom-organisation>
                <div>{{task._organization.name}}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="task.action">
            <div class="text-secondary text-small flex padding-10-0">
                <span class="padding-r-10">Projekt:</span>
                <span class="color-dot" [style.backgroundColor]="task._action._project?._projectType?.color" matTooltip="{{task._action._project?._projectType?.name}}"></span>
                <div>{{task._action._project.name}}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="task.language">
            <div class="flex">
                <span class="text-small text-secondary padding-r-10">Kommunikáció Nyelve:</span>
                <div class="text-small text-secondary">
                    <ng-container *ngIf="task.language == 'hu'">Magyar</ng-container>
                    <ng-container *ngIf="task.language == 'en'">Angol</ng-container>
                </div>
            </div>   
        </ng-container>
        <div class="padding-20-0">
            <ng-container *ngIf="task.id">
                <span *ngIf="!task.archived">
                    <button mat-mini-fab  color="primary" (click)="edit()" matTooltip="Szerkeszés">
                        <mat-icon>edit</mat-icon>
                    </button>
                </span>
                <span class="padding-l-10">
                    <app-quick-log-info [data]="task"></app-quick-log-info>
                </span>
            </ng-container>   
        </div>
    </ng-container>  
</div>


<ng-container *ngIf="task.id">
    <app-task-interest-porducts [task]="task"></app-task-interest-porducts>
</ng-container>

<ng-container *ngIf="task.id">
    <app-task-interest-pdfs [task]="task"></app-task-interest-pdfs>
</ng-container>

