<div class="text-center padding-20-0">
    Profil Szerkesztés
</div>
<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Kerszténv</mat-label>
            <input matInput name="firstname" [(ngModel)]="data.firstName" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Vezetéknév</mat-label>
            <input matInput name="lastname" [(ngModel)]="data.lastName" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Mobil Szám</mat-label>
            <input matInput name="phonePersonal" [(ngModel)]="data.phonePersonal" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Céges TelefonSzám</mat-label>
            <input matInput name="phoneCompany" [(ngModel)]="data.phoneCompany" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Cím</mat-label>
            <input matInput name="address" [(ngModel)]="data.address" autocomplete="off">
        </mat-form-field>
    </p>
    <p  class="padding-10-0">
        <mat-form-field appearance="outline">
            <mat-label>
                Nyelv
            </mat-label>
            <mat-select name="language"  [(ngModel)]="data.language" required>
                <mat-option [value]="enumLanguage.Hu">Magyar</mat-option>
                <mat-option [value]="enumLanguage.Ge">Német</mat-option>
            </mat-select>
          </mat-form-field>
    </p>
    <!--<p>
        <mat-form-field appearance="outline">
            <mat-label>Időzóna</mat-label>
            <input matInput name="timezone" [(ngModel)]="data.timezone" autocomplete="off" disabled>
        </mat-form-field>
    </p>-->
    <p>
        <span class="padding-l-10">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
    </p>
</form>
