import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActionPlan } from 'src/app/models/actionPlan.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { DlgEditItemComponent } from '../dlg-edit-item/dlg-edit-item.component';

@Component({
  selector: 'app-dlg-edit-plan-action',
  templateUrl: './dlg-edit-plan-action.component.html',
  styleUrls: ['./dlg-edit-plan-action.component.scss']
})
export class DlgEditPlanActionComponent implements OnInit {
  actionPlan: ActionPlan = null;
  public onChange = new EventEmitter();

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditItemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data) {
      this.actionPlan = data;
    } else {
      this.actionPlan = this.modelFactory.getNewModel(ActionPlan);
    } 
  }

  ngOnInit() {}

  created(event) {
    this.onChange.emit(event);
    this.closeDlg()
  }

  updated(event) {
    this.onChange.emit(event);
    this.closeDlg();
  }

  deleted(event) {
    this.onChange.emit(event);
    this.closeDlg();
  }

  closeDlg() {
    this.dialogRef.close();
  }

}
