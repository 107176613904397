import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { EnumFileUploadType } from '../enums/fileUploadType.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class FileUploadService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.paths = {};
        this.paths[EnumFileUploadType.CompanyPdfHeader] = '/accounts/company/upload/pdf-header/';
        this.paths[EnumFileUploadType.CompanyLogo] = '/accounts/company/upload/logo/';
    }
    upload(formData, type) {
        let path = this.paths[type];
        let url = this.baseUrl + path;
        let headers = new HttpHeaders;
        headers = headers.set('Content-Type', 'multipart/form-data');
        headers = headers.set('Accept', 'application/json');
        let file = formData.get('file');
        let fileName = file.name;
        let contentDispositionHeader = `form-data; filename="${fileName}"`;
        headers = headers.append('Content-Disposition', contentDispositionHeader);
        headers = headers.append('Access-Control-Allow-Headers', 'content-disposition, accept, accept-encoding, authorization, content-type, dnt, origin, user-agent, x-csrftoken, x-requested-with');
        return this.http.post(url, formData).pipe(map(result => result), tap(res => { this.notificationService.created(); }));
    }
}
FileUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: FileUploadService, providedIn: "root" });
