<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Kontakt</a></li>  
    <ng-container *ngIf="people.fullName"><li>{{people.fullName}}</li></ng-container>  
</ul>

<div class="grid">
    <div class="section">
        <div class="header">
            <div class="title"><span>Kontakt</span></div>
        </div>
      <app-person
        [people]="people"
        (eventCreated)="created($event)"
        (eventDeleted)="deleted($event)" 
        (eventUpdated)="updated($event)"
      ></app-person>
    </div>
    
    <ng-container *ngIf="people.id">
        <app-person-email  [peopleId]="people.id"></app-person-email>
        <app-person-phone  [peopleId]="people.id"></app-person-phone>
    </ng-container>

    <ng-container *ngIf="organisation">
        <div class="section">
            <div class="header">
                <div class="title full"><span>Cég</span></div>
                <div>
                    <button mat-mini-fab  color="secondary" (click)="openOrganisation()" matTooltip="Megnyitás új oldalon">
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </div>
            <div>
                <table class="table">
                    <tr><td>Név</td><td>{{organisation.name}}</td></tr>
                    <tr><td>Terület</td><td>{{organisation.industry}}</td></tr>
                    <tr><td>Adó szám</td><td>{{organisation.taxNumber}}</td></tr>
                    <tr><td>Email</td><td>
                            <ng-container *ngFor="let e of organisation.emails">
                                <div>
                                    {{e.email}}
                                    <ng-container *ngIf="e.name"><span class="text-secondary text-small">({{e.name}})</span></ng-container>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                    <tr><td>Telefon</td><td>
                        <ng-container *ngFor="let p of organisation.phones">
                            <div>
                                {{p.phone}}
                                <ng-container *ngIf="p.name"><span class="text-secondary text-small">({{p.name}})</span></ng-container>
                            </div>
                        </ng-container>
                    </td>
                </tr>
                </table>
            </div>
        </div>
    </ng-container>
</div>
