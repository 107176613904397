import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Manufacturer } from 'src/app/models/manufacturers.model';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-page-manufacturer',
  templateUrl: './page-manufacturer.component.html',
  styleUrls: ['./page-manufacturer.component.scss']
})
export class PageManufacturerComponent implements OnInit {
  manufacturer: Manufacturer = null;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private manufacturerService: ManufacturerService,
    private modelFactory: ModelFactory
  ) {
    this.manufacturer = this.modelFactory.getNewModel(Manufacturer)
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.manufacturerService.get(id).subscribe(m => {
        this.manufacturer = m;
      });
    }
  }

  created(entity) {
    this.manufacturer = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.manufacturer.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../manufacturers'],  {
        relativeTo: this.route  
      });
  }

  updated(event) {
    //
  }
}

