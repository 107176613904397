export class User {
    id: Number = null;
    email: String = null;
    firstName: String = null;
    lastName: String = null;
    language: String = null;
    isActive: boolean = false
    admin: boolean = false;
    verified: boolean = false;
    color: String = null;
    status: String = null;

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.email  = data.email;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.language = data.language;
            this.isActive = data.isActive
            this.admin = data.admin;
            this.verified = data.verified;
            this.color = data.color;
            this.status = data.status;
        }  
    }

    get fullName() {
        return this.lastName + ' ' + this.firstName;
    }
  }

export class SimpleUser {
    id: Number = null;
    email: String = null;
    firstName: String = null;
    lastName: String = null;
    language: String = null;
    color: String = null;

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.email  = data.email;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.language = data.language;
            this.color = data.color;
        }  
    }

    get fullName() {
        return this.lastName + ' ' + this.firstName;
    }
  }