<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Cégek</a></li>  
    <li>
        <ng-container *ngIf="organisation">
            {{organisation.name}}
        </ng-container>    
    </li>  
</ul>


<mat-tab-group animationDuration="0ms">
<mat-tab label="Cég">
    <ng-container *ngTemplateOutlet="companyTab"></ng-container>
</mat-tab>
<mat-tab label="Kontaktok">
    <ng-container *ngTemplateOutlet="personsTab"></ng-container>
</mat-tab>
<mat-tab label="Termékek">
    <ng-container *ngTemplateOutlet="itemsTab"></ng-container>
</mat-tab>
</mat-tab-group>


<ng-template #companyTab class="padding-20-0">
    <div class="grid">
        <div class="section">
            <div class="header">
                <div class="title"><span>Cég</span></div>
            </div>
            <ng-container *ngIf="organisation">
                <app-organisation [organisation]="organisation"
                (eventCreated)="created($event)"
                (eventDeleted)="deleted($event)" 
                (eventUpdated)="updated($event)"
                ></app-organisation>
            </ng-container>
        </div>
        
        
        <ng-container *ngIf="organisation && organisation.id">
            <app-organisation-email  [organisationId]="organisation.id"></app-organisation-email>
            <app-organisation-phone  [organisationId]="organisation.id"></app-organisation-phone>
            <app-organisation-address  [organisationId]="organisation.id"></app-organisation-address>
        </ng-container>
    </div>
</ng-template>

<ng-template #personsTab>
      <ng-container *ngIf="persons">
          <ng-container *ngFor="let p of persons">
                <div class="section">
                    <div class="flex flex-wrap flex-space-between">
                        <div class="padding-10"><mat-icon>assignment_ind</mat-icon>{{p.fullName}}</div>
                        <div *ngIf="p.emails">
                            <ng-container *ngFor="let e of p.emails">
                                <div class="padding-10">{{e.email}} 
                                    <ng-container *ngIf="e.name"><span class="text-secondary text-small">({{e.name}})</span></ng-container>
                                    </div>
                            </ng-container>
                        </div>
                        <div *ngIf="p.phones">
                            <ng-container *ngFor="let p of p.phones">
                                <div class="padding-10">{{p.phone}} 
                                    <ng-container *ngIf="p.name"><span class="text-secondary text-small">({{p.name}})</span></ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <button mat-mini-fab  color="secondary" (click)="openPerson(p)" matTooltip="Megnyitás új oldalon">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
    </ng-container>
    <div class="padding-20">
        <button mat-mini-fab  color="accent" (click)="createPersonDlg()" matTooltip="Új Kontakt"> 
            <mat-icon>add</mat-icon>
        </button>
      </div>
</ng-template>

<ng-template #itemsTab>
    <ng-container *ngIf="organisation && organisation.id">
        <app-organisation-item  [organization]="organisation"></app-organisation-item>
    </ng-container>    
</ng-template>



