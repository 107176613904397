<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Projektek</a></li> 
    <ng-container *ngIf="projectPlan.name">
        <li>{{projectPlan.name}}</li>  
    </ng-container> 
</ul>
<div class="section">
    <div class="header">
        <div class="title"><span>Projekt Terv</span></div>
    </div>
        <form #f="ngForm">
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Név</mat-label>
                    <input matInput name="nev" [(ngModel)]="projectPlan.name"  autocomplete="off" required>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Típus</mat-label>
                    <mat-select name="project_type" [(ngModel)]="projectPlan.project_type" required>
                        <mat-option (click)="changeProjectType(option)" *ngFor="let option of projectTypes" [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Leírás</mat-label>
                    <textarea rows=6 matInput name="description" [(ngModel)]="projectPlan.description"  autocomplete="off" required></textarea>
                </mat-form-field>
                
            </p>
            <p class="padding-20-0">
                <mat-radio-group (change)="changeStatus($event)" >
                    <mat-radio-button  [checked]="!projectPlan.archived" value="active">Active</mat-radio-button>
                    <mat-radio-button [checked]="projectPlan.archived" value="archived">Archivált</mat-radio-button>
                </mat-radio-group>
            </p>
            <p class="padding-20-0">
                <span class="padding-20-0">
                    <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                        <mat-icon>save</mat-icon>
                    </button>
                </span>
                <ng-container *ngIf="projectPlan.id">
                    <span class="padding-20-0 padding-l-10">
                        <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10 padding-10-0">
                        <app-quick-log-info [data]="projectPlan"></app-quick-log-info>           
                    </span>
                </ng-container>
            </p>
        </form>
</div>

<ng-container *ngIf="projectPlan.id">
    <app-project-actions-plan [projectPlan]=projectPlan></app-project-actions-plan>
</ng-container>
