import { ActionPlan } from "./actionPlan.model";
import { ProjectPlan } from "./projectPlan.model";

export class ProjectActionPlan {
    id: number | string = null;
    projectTemplate: number = null;
    actionTemplate: number = null;
    order: number = null;
    _projectTemplate?: any = null;
    _actionTemplate?: any = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: string, email: string, firstName: string, lastName: string} = null;
    lastModifier?:  {id: number, color: string, email: string, firstName: string, lastName: string} = null;
 
    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.projectTemplate  = data.projectTemplate;
            this.actionTemplate  = data.actionTemplate;
            this._projectTemplate = data._projectTemplate;
            this._actionTemplate = data._actionTemplate;

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  
    }

    setId (id: string) {
        // only for newly created objects
        this.id = id;
    }

    setProject(project) {
        // if the id passed we set it, but if the obejct we can set the _ data as well
        let projectId: any = project;
        
        if ( project instanceof ProjectPlan) {
            projectId = project.id;
        } 

        this.projectTemplate = projectId;

        if (project instanceof ProjectPlan) {
            this._projectTemplate = project;
        }
    }

    setAction(action) {
        // if the id passed we set it, but if the obejct we can set the _ data as well
        let actionId: any = action;
        
        if (action instanceof ActionPlan) {
            actionId = action.id;
        } 

        this.actionTemplate = actionId;

        if (action instanceof ActionPlan) {
            this._actionTemplate = action;
        }
    }
  }
