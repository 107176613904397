/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../items/page-item/item/item.component.ngfactory";
import * as i3 from "../../../../items/page-item/item/item.component";
import * as i4 from "../../../../services/item.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "./dlg-edit-item.component";
import * as i7 from "../../../../services/modelFactory.service";
var styles_DlgEditItemComponent = [i0.styles];
var RenderType_DlgEditItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditItemComponent, data: {} });
export { RenderType_DlgEditItemComponent as RenderType_DlgEditItemComponent };
export function View_DlgEditItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "padding-20-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Term\u00E9k L\u00E9trehoz\u00E1s"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-item", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ItemComponent_0, i2.RenderType_ItemComponent)), i1.ɵdid(5, 114688, null, 0, i3.ItemComponent, [i4.ItemService, i5.MatDialog], { item: [0, "item"] }, { eventCreated: "eventCreated", eventDeleted: "eventDeleted", eventUpdated: "eventUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_DlgEditItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-item", [], null, null, null, View_DlgEditItemComponent_0, RenderType_DlgEditItemComponent)), i1.ɵdid(1, 114688, null, 0, i6.DlgEditItemComponent, [i7.ModelFactory, i5.MatDialogRef, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditItemComponentNgFactory = i1.ɵccf("app-dlg-edit-item", i6.DlgEditItemComponent, View_DlgEditItemComponent_Host_0, {}, {}, []);
export { DlgEditItemComponentNgFactory as DlgEditItemComponentNgFactory };
