<div class="project-actions-bar">
    <ng-container *ngIf="actions; else noActions">
        <div class="actions">
            <ng-container *ngFor="let action of actions; let last = last;">
                <div class="action {{action.status}} cursor"
                matTooltip="{{getStatusText(action.status)}}" (click)="setSelectedAction(action)">
                    <div class="number">
                        <span>
                            <ng-container *ngIf="action.status == 'done'; else Number">
                                <mat-icon>check</mat-icon>
                            </ng-container>
                            <ng-template #Number>{{action.order + 1}}</ng-template>
                        </span>
                    </div>
                    <div class="name">{{action.name | truncate:4}}</div>
                </div>
                <div class="bar {{action.status}}" *ngIf="!last"></div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #noActions>
        Nincsenek Lépések
    </ng-template>
   
    
</div>
