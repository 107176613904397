/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-manufacturer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../manufacturers/page-manufacturer/manufacturer/manufacturer.component.ngfactory";
import * as i3 from "../../../../manufacturers/page-manufacturer/manufacturer/manufacturer.component";
import * as i4 from "../../../../services/manufacturer.service";
import * as i5 from "./dlg-edit-manufacturer.component";
import * as i6 from "../../../../services/modelFactory.service";
import * as i7 from "@angular/material/dialog";
var styles_DlgEditManufacturerComponent = [i0.styles];
var RenderType_DlgEditManufacturerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditManufacturerComponent, data: {} });
export { RenderType_DlgEditManufacturerComponent as RenderType_DlgEditManufacturerComponent };
export function View_DlgEditManufacturerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "padding-20-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Gy\u00E1rt\u00F3 L\u00E9trhoz\u00E1s"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-manufacturer", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ManufacturerComponent_0, i2.RenderType_ManufacturerComponent)), i1.ɵdid(3, 114688, null, 0, i3.ManufacturerComponent, [i4.ManufacturerService], { manufacturer: [0, "manufacturer"], isDlg: [1, "isDlg"] }, { eventCreated: "eventCreated", eventDeleted: "eventDeleted", eventUpdated: "eventUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manufacturer; var currVal_1 = true; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_DlgEditManufacturerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-manufacturer", [], null, null, null, View_DlgEditManufacturerComponent_0, RenderType_DlgEditManufacturerComponent)), i1.ɵdid(1, 114688, null, 0, i5.DlgEditManufacturerComponent, [i6.ModelFactory, i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditManufacturerComponentNgFactory = i1.ɵccf("app-dlg-edit-manufacturer", i5.DlgEditManufacturerComponent, View_DlgEditManufacturerComponent_Host_0, {}, {}, []);
export { DlgEditManufacturerComponentNgFactory as DlgEditManufacturerComponentNgFactory };
