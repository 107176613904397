/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-product.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../products/page-product/product/product.component.ngfactory";
import * as i3 from "../../../../products/page-product/product/product.component";
import * as i4 from "../../../../services/product.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "./dlg-edit-product.component";
import * as i7 from "../../../../services/modelFactory.service";
var styles_DlgEditProductComponent = [i0.styles];
var RenderType_DlgEditProductComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditProductComponent, data: {} });
export { RenderType_DlgEditProductComponent as RenderType_DlgEditProductComponent };
export function View_DlgEditProductComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center padding-20-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alap Term\u00E9k L\u00E9trhoz\u00E1sa"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-product", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ProductComponent_0, i2.RenderType_ProductComponent)), i1.ɵdid(3, 114688, null, 0, i3.ProductComponent, [i4.ProductService, i5.MatDialog], { product: [0, "product"], isDlg: [1, "isDlg"] }, { eventCreated: "eventCreated", eventDeleted: "eventDeleted", eventUpdated: "eventUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product; var currVal_1 = true; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_DlgEditProductComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-product", [], null, null, null, View_DlgEditProductComponent_0, RenderType_DlgEditProductComponent)), i1.ɵdid(1, 114688, null, 0, i6.DlgEditProductComponent, [i7.ModelFactory, i5.MatDialogRef, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditProductComponentNgFactory = i1.ɵccf("app-dlg-edit-product", i6.DlgEditProductComponent, View_DlgEditProductComponent_Host_0, {}, {}, []);
export { DlgEditProductComponentNgFactory as DlgEditProductComponentNgFactory };
