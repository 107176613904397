import { Component, Input, OnInit } from '@angular/core';
import { EnumProjectImportance } from 'src/app/enums/projectImportance.enum';

@Component({
  selector: 'app-project-importance',
  templateUrl: './project-importance.component.html',
  styleUrls: ['./project-importance.component.scss']
})
export class ProjectImportanceComponent implements OnInit {
  @Input() importance: string | number;

  importances = [];
  txtImportance = '';

  constructor() { }

  ngOnInit() {
    // convert to number
    this.importance = +this.importance;
        // create an array with the length as the importance nubmber (1-5)
    this.importances.length = +this.importance;
    this.txtImportance = this.getTextImportance();
  }

  getTextImportance() {
    let txt = '';

    if (this.importance === EnumProjectImportance.VeryLow) {
        txt = 'Mellékes';
    } else if (this.importance === EnumProjectImportance.Low) {
      txt = 'Nem Fontos';
    } else if (this.importance === EnumProjectImportance.Normal) {
      txt = 'Normál';
    } else if (this.importance === EnumProjectImportance.High) {
      txt = 'Fontos';
    } else if (this.importance === EnumProjectImportance.SuperHigh) {
      txt = 'Kiemelten Fontos';
    }

    return txt
  }

}
