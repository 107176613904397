import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Organisation } from 'src/app/models/organisation';
import { ModelFactory } from 'src/app/services/modelFactory.service';


@Component({
  selector: 'app-dlg-edit-organisation',
  templateUrl: './dlg-edit-organisation.component.html',
  styleUrls: ['./dlg-edit-organisation.component.scss']
})
export class DlgEditOrganisationComponent implements OnInit {
  organisation: Organisation = null;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditOrganisationComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.organisation = this.modelFactory.getNewModel(Organisation);
  }

  ngOnInit() {
  }

  created(organisation) {
    this.closeDlg(organisation)
  }

  updated(organisation) {
    this.closeDlg(organisation);
  }

  deleted(event) {
    
  }

  closeDlg(organisation) {
    this.dialogRef.close(organisation);
  }


}
