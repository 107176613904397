<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Megkeresések</a></li> 
    <ng-container *ngIf="task">
        <li>{{task.name}}</li>  
    </ng-container> 
</ul>

<ng-container *ngIf="task.id">
    <div class="padding-10-0">
        <button (click)="openTaskPage()" mat-stroked-button color="primary">Vissza a Feladat oldalra</button>
    </div>
</ng-container>

<div class="section">
    <div class="header flex">
        <div class="full">
            <div class="title"><span>Feladat</span></div>
        </div>   
    </div>
        <form #f="ngForm">

            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Név</mat-label>
                    <input matInput name="nev" [(ngModel)]="task.name"  autocomplete="off" required>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Leírás</mat-label>
                    <textarea rows=5 matInput name="description" [(ngModel)]="task.description"  autocomplete="off" required></textarea>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Állapot</mat-label>
                    <mat-select name="condition"  [(ngModel)]="task.condition" required>
                        <mat-option [value]="enumTaskInterestCondtion.New">Új</mat-option>
                        <mat-option [value]="enumTaskInterestCondtion.Used">Használt</mat-option>
                        <mat-option [value]="enumTaskInterestCondtion.Both">Bármelyik</mat-option>
                    </mat-select>
                  </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Fontosság</mat-label>
                    <mat-select name="importance"  [(ngModel)]="task.importance" required>
                        <mat-option [value]="5">Nagyon Fontos</mat-option>
                        <mat-option [value]="4">Fontos</mat-option>
                        <mat-option [value]="3">Normal</mat-option>
                        <mat-option [value]="2">Nem Fontos</mat-option>
                        <mat-option [value]="1">Mellékes</mat-option>
                    </mat-select>
                  </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Felelős</mat-label>
                    <ng-container *ngIf="task.assignee; else noAssignee">
                        <input matInput name="assignee" disabled [(ngModel)]="task._assignee.lastName"  autocomplete="off">
                    </ng-container>
                    <ng-template #noAssignee>
                        <input matInput name="assignee" disabled [(ngModel)]="task.assignee" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openAssigneeSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p  class="padding-10-0">
                <mat-form-field appearance="outline">
                    <mat-label>Cég</mat-label>
                    <ng-container *ngIf="task.organization; else noOrg">
                        <input matInput name="organisation" disabled [(ngModel)]="task._organization.name"  autocomplete="off">
                    </ng-container>
                    <ng-template #noOrg>
                        <input matInput name="organisation" disabled [(ngModel)]="task.organization" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openOrgSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Projekt Lépés</mat-label>
                    <ng-container *ngIf="task.action; else noResult">
                        <input matInput name="template" disabled [(ngModel)]="task._action.name"  autocomplete="off">
                    </ng-container>
                    <ng-template #noResult>
                        <input matInput name="template" disabled [(ngModel)]="task.action" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openActionSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p  class="padding-10-0">
                <mat-form-field appearance="outline">
                    <mat-label>
                        Kommunikáció Nyelve (Pdf, Email)
                    </mat-label>
                    <mat-select name="langugage"  [(ngModel)]="task.language" required>
                        <mat-option [value]="enumLanguage.Hu">Magyar</mat-option>
                        <mat-option [value]="enumLanguage.En">Angol</mat-option>
                    </mat-select>
                  </mat-form-field>
            </p>
            <p>
                <span class="padding-20-0">
                    <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                        <mat-icon>save</mat-icon>
                    </button>
                </span>
                <ng-container *ngIf="task.id">
                    <span class="padding-20-0 padding-l-10">
                        <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10 padding-10-0">
                        <app-quick-log-info [data]="task"></app-quick-log-info>           
                    </span>
                </ng-container>
            </p>
        </form>
</div>