export class Category {
    id: Number = null;
    opencartId: Number = null;
    nameHu: string = null;
    nameEn: string = null;
    imageUrl: string = null;
    parent: Number = null;
    _parent: any = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.opencartId  = data.opencartId;
            this.nameHu  = data.nameHu;
            this.nameEn  = data.nameEn;
            this.imageUrl = data.imageUrl;
            
            this.parent = data.parent;
            if ('_parent' in data) {
                this._parent = data._parent;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  

    }
  }
