import { Component, Input, OnInit } from '@angular/core';
import { Role } from 'src/app/models/role.model';
import { UserRole } from 'src/app/models/userRole.model';
import { RolesService } from 'src/app/services/roles.service';
import { UserRolesService } from 'src/app/services/userRoles.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';


@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
  providers: [UrlParamService]
})
export class UserRolesComponent extends BaseList implements OnInit  {
  @Input() userId: number;
  userRoles: UserRole[] = null;
  roles: Role[] = null;
  // selected new role for the user
  selectedRole: number = null;

  user = null;

  constructor(
    private rolesService: RolesService,
    private userRolesService: UserRolesService,
    private modelFactory: ModelFactory,
    public urlParamService: UrlParamService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    super()

       // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);

    this.userRoles = [this.modelFactory.getNewModel(UserRole)]
    this.roles = [this.modelFactory.getNewModel(Role)]
  }

  ngOnInit() {
    let defaultParams = {limit: 1000}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.rolesService.list(options).subscribe(data => {
          this.roles = data.results;
          // get the user roles
          this.userRolesService.getByUser(this.userId).subscribe(data => {
            this.setListResponseData(data);
            this.userRoles = this.dataSource;
          });
      });
    });
  }

  setSelectedValue(e) {
    // set the selected Role ID form the dorpdown
    this.selectedRole = e.value;
  }

  create() {  
    let payload = {
      user: this.userId,
      role: this.selectedRole
    }
    this.userRolesService.create(payload).subscribe((newUserRole) => {
      // add the new role to the list
      this.userRoles.push(newUserRole);
    })
  }

  delete(userRoleId: number) {
    let userRole = this.userRoles.find(r => r.id == userRoleId);
    this.userRolesService.delete(userRole).subscribe(() => {
      // get rid of the deleted userRole from the list
      this.userRoles = this.userRoles.filter(r => r.id != userRole.id);
    });
  }

}
