/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-plan-action.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../project-plans/actions-plans/page-action-plans/action-plan/action-plan.component.ngfactory";
import * as i3 from "../../../../project-plans/actions-plans/page-action-plans/action-plan/action-plan.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../services/modelFactory.service";
import * as i6 from "../../../../services/planningAction.service";
import * as i7 from "./dlg-edit-plan-action.component";
import * as i8 from "@angular/material/dialog";
var styles_DlgEditPlanActionComponent = [i0.styles];
var RenderType_DlgEditPlanActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditPlanActionComponent, data: {} });
export { RenderType_DlgEditPlanActionComponent as RenderType_DlgEditPlanActionComponent };
export function View_DlgEditPlanActionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center padding-20-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00DAj L\u00E9p\u00E9s L\u00E9trhoz\u00E1sa\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-action-plan", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ActionPlanComponent_0, i2.RenderType_ActionPlanComponent)), i1.ɵdid(3, 114688, null, 0, i3.ActionPlanComponent, [i4.ActivatedRoute, i4.Router, i5.ModelFactory, i6.PlanningActionService], { actionPlan: [0, "actionPlan"] }, { eventCreated: "eventCreated", eventDeleted: "eventDeleted", eventUpdated: "eventUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionPlan; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DlgEditPlanActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-plan-action", [], null, null, null, View_DlgEditPlanActionComponent_0, RenderType_DlgEditPlanActionComponent)), i1.ɵdid(1, 114688, null, 0, i7.DlgEditPlanActionComponent, [i5.ModelFactory, i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditPlanActionComponentNgFactory = i1.ɵccf("app-dlg-edit-plan-action", i7.DlgEditPlanActionComponent, View_DlgEditPlanActionComponent_Host_0, {}, {}, []);
export { DlgEditPlanActionComponentNgFactory as DlgEditPlanActionComponentNgFactory };
