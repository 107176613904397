export class TaskInterestProduct {
    id: number = null;
    amount: number = null;
    task: number = null;
    _task: any = null;
    product: number = null;
    _product: any = null;

    assignee: number = null;
    _assignee: any = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.amount = data.amount;
            
            this.assignee = data.assignee;
            if ('_assignee' in data) {
                this._assignee = data._assignee;
            }
           
            this.task = data.task;
            if ('_task' in data) {
                this._task = data._task;
            }

            this.product  = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }

            if ('_product' in data) {
                this._product = data._product;
            }
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
