<form class="from-group-inline" #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Vezetéknév</mat-label>
            <input matInput name="lastName" [(ngModel)]="people.lastName" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Keresztnév</mat-label>
            <input matInput name="firstName" [(ngModel)]="people.firstName" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Pozició</mat-label>
            <input matInput name="role" [(ngModel)]="people.role" autocomplete="off">
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Cég?</mat-label>
            <ng-container *ngIf="people.organization; else noOrg">
                <input matInput name="organisation" disabled [(ngModel)]="people._organization.name"  autocomplete="off">
            </ng-container>
            <ng-template #noOrg>
                <input matInput name="organisation" disabled [(ngModel)]="people.organization" autocomplete="off">
            </ng-template>
            <mat-icon class="pointer" (click)="openOrgSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
        </mat-form-field>
    </p>

    <p>
        <span class="padding-l-10">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="people.id">
            <span class="padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10">
                <app-quick-log-info [data]="people"></app-quick-log-info>
            </span>
        </ng-container>   
    </p>
</form> 