import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  @Output('clicked') menuClicked: any = new EventEmitter();

  //modules
  contacts = false;
  items = false;
  admin = false;
  projectPlanning = false;


  constructor(
    private securityService: SecurityService
  ) { }

  ngOnInit() {
    this.securityService.modelAccessLevels$.subscribe((res) => {
      if (!res) {
        // no response yet
        return
      }
      // lead is finieshed, now we can check the values
      this.admin = this.securityService.user.admin;
      this.contacts = this.securityService.hasAccess('contacts')
      this.items = this.securityService.hasAccess('items')
      this.projectPlanning = this.securityService.hasAccess('projectplanning')
    })
  
  }

  clicked() {
    this.menuClicked.emit();
  }

}
