<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Projektek</a></li> 
    <ng-container *ngIf="project">
        <li>{{project.name}}</li>  
    </ng-container> 
</ul>

<ng-container *ngIf="project">
    <app-project-actions-bar [actions]="project.actions" (onSelect)="setSelectedAction($event)"></app-project-actions-bar>
</ng-container>

<div class="section" style="border-top: 2px solid #ffffff" [style.border-color]="project?._projectType?.color">
    <ng-container *ngIf="project">
        <div class="flex">
            <h5 class="full">
                {{project.name}}
                <ng-container *ngIf="project.description">
                    <mat-icon class="text-secondary text-small" matTooltip="{{project.description}}">info</mat-icon>
                </ng-container>
            </h5>
            <span class="">
                <ng-container *ngIf="!project.closed">
                    <button mat-flat-button color="accent"  (click)="setStatus('close')"  matTooltip="Projekt Aktív, kattints hogy lezárd">
                        Aktív
                    </button>
                </ng-container>
                <ng-container *ngIf="project.closed">
                    <button mat-flat-button  color="warn" (click)="setStatus('active')"  matTooltip="Projekt Lezárva, kattints hogy aktiváld">
                        Lezárva
                    </button>
                </ng-container>
            </span> 
        </div>
        <div>
            <span class="badge badge-info">{{project.refNumber}}</span>
        </div>
        <ng-container *ngIf="project.project_type">
            <div class="flex text-small text-secondary padding-10-0">
                <span class="padding-r-10">Típus:</span>
                <span>{{project._projectType.name}}</span>
            </div>   
        </ng-container>
        <ng-container *ngIf="project.importance">
            <div class="flex">
                <span class="text-small text-secondary padding-r-10">Fontosság:</span>
                <app-project-importance [importance]="project.importance"></app-project-importance>
            </div>   
        </ng-container>
        <div class="flex text-small text-secondary padding-10-0">
            <span class="padding-r-10">Felelős:</span>
            <ng-container *ngIf="project.assignee">
                <app-avatar [user]="project._assignee"></app-avatar>
            </ng-container>
        </div>   
        <ng-container *ngIf="project.organization">
            <div class="text-secondary text-small flex">
                <span class="padding-r-10">Cég:</span>
                <app-bottom-organisation [data]="project.organization"></app-bottom-organisation>
                <div>{{project._organization.name}}</div>
            </div>
        </ng-container>
        <div class="padding-20-0">
            <ng-container *ngIf="project.id">
                <span *ngIf="!project.closed">
                    <button mat-mini-fab  color="primary" (click)="edit()" matTooltip="Szerkeszés">
                        <mat-icon>edit</mat-icon>
                    </button>
                </span>
                <span class="padding-l-10">
                    <app-quick-log-info [data]="project"></app-quick-log-info>
                </span>
            </ng-container>   
        </div>
    </ng-container>  
</div>

<ng-container *ngIf="project">
    <app-action [action]="selectedAction"></app-action>
</ng-container>
