<ng-container *ngIf="snippets">
    <div class="flex padding-10">
        <div class="full">Opciók</div>
        <div>
            <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új opció létrhozása">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <mat-accordion>
        <ng-container *ngFor="let snippet of snippets">
        <form>
            <mat-expansion-panel [expanded]="snippet?.expanded">
                
                <mat-expansion-panel-header class="w600">
                    <mat-panel-title class="flex">
                        <button mat-button class="mat-success" (click)="select(snippet)">Kiválaszt</button>
                        <div class="padding-l-20">
                            {{snippet.snippetHu | truncate:12}}
                        </div>
                    </mat-panel-title>

                </mat-expansion-panel-header>

                <div class="padding-10-0">
                    <mat-form-field appearance="outline">
                        <mat-label>Magyar</mat-label>
                        <textarea rows="4" name="hu{{snippet.id}}" matInput [(ngModel)]="snippet.snippetHu" required></textarea>
                    </mat-form-field>
                </div>

                <div class="padding-10-0">
                    <mat-form-field appearance="outline">
                        <mat-label>Angol</mat-label>
                        <textarea rows="4" matInput  name="en{{snippet.id}}" [(ngModel)]="snippet.snippetEn"></textarea>
                    </mat-form-field>
                </div>
                <div class="flex">
                    <div class="full">
                        <button mat-mini-fab color="primary" [disabled]="!snippet.snippetHu" (click)="save(snippet)" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <button mat-mini-fab color="danger" (confirm-click)="delete(snippet.id)" confirm matTooltip="Törlés">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

            </mat-expansion-panel>
        </form>
        </ng-container>
    </mat-accordion>
</ng-container>