<div class="section">
  <div class="header">
    <div class="title">
      <span>Ajánlatok</span>
    </div>
    <div>
      <button mat-button (click)="reLoadPdfs()"
        matToolitp="Újra letöltése a PDF fájloknak, ugyanis a generálás elterhat egy percig is.">
        Frissítés
      </button>
    </div>
  </div>
  <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="PDF">
      <table class="table">
        <ng-container *ngFor="let pdf of pdfs">
          <tr>
            <td class="w50">
              <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Done">
                <mat-icon class="text-green">done</mat-icon>
              </ng-container>
              <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Pending">
                <mat-spinner [diameter]="19"></mat-spinner>
              </ng-container>
              <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Failed">
                <mat-icon class="text-red">close</mat-icon>
              </ng-container>
            </td>
            <td class="w100">
              {{pdf.created | date:'yy-mm-dd HH:mm'}}
            </td>
            <td>
              <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Done; else pdfGeneration">
                <a target="_balank" class="link" href="{{pdf.file}}">{{pdf.name}}</a>
              </ng-container>
              <ng-template #pdfGeneration>{{pdf.name}}</ng-template>
            </td>
            <td class="w50">
              <app-quick-log-info [data]="pdf"></app-quick-log-info>
            </td>
          </tr>
        </ng-container>
      </table>
    </mat-tab>
    <mat-tab label="Létrehozás">
      <ng-container *ngIf="!createPdf; else pdfCreaionTemplate">
        <mat-spinner [diameter]="19"></mat-spinner>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #pdfCreaionTemplate>
  <div class="padding-10-0">
    <mat-radio-group [(ngModel)]="pdfData.language">
      <mat-radio-button [value]="EnumLanguage.Hu">Magyar</mat-radio-button>
      <mat-radio-button [value]="EnumLanguage.En">Angol</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <mat-checkbox (change)="pdfData.application = !pdfData.application"
                  [checked]="pdfData.application">Pályázathoz?</mat-checkbox>
  </div>
  <mat-vertical-stepper #stepper>
    <mat-step>
      <form #f>
        <ng-template matStepLabel>Bevezetés</ng-template>
        <div class="padding-10-0">
          <div class="flex">
            <mat-form-field appearance="outline">
              <mat-label>Címzett</mat-label>
              <input matInput required [(value)]="pdfData.name">
              <mat-icon class="pointer text-light-blue" matPrefix (click)="openSearchOrgContactDialog()">search</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="padding-10-0 flex">
          <mat-form-field appearance="outline">
            <mat-label>Első Bekezdés</mat-label>
            <textarea matInput [(value)]="pdfData.introduction" placeholder="Beveztő ..."></textarea>
          </mat-form-field>
          <app-pdf-snippet [language]="pdfData.language" 
                           [type]="EnumPdfSnippet.TasksInterestIntroduction"
                           (onSelect)="pdfData.introduction=$event">
          </app-pdf-snippet>
        </div>
        <div class="padding-10-0">
          <button mat-mini-fab color="primary" matStepperNext>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <form>
        <ng-template matStepLabel>Feltételek</ng-template>
          <div class="padding-10-0 flex">
            <mat-form-field appearance="outline">
              <mat-label>Ár Tartalmazza</mat-label>
              <textarea matInput [(value)]="pdfData.priceContains" placeholder=""></textarea>
            </mat-form-field>
            <app-pdf-snippet [language]="pdfData.language" 
                            [type]="EnumPdfSnippet.TaskInterestPriceContains"
                            (onSelect)="pdfData.priceContains=$event">
            </app-pdf-snippet>
          </div>

          <div class="padding-10-0 flex">
            <mat-form-field appearance="outline">
              <mat-label>Szállítási Határidő</mat-label>
              <textarea matInput [(value)]="pdfData.deliveryTime" placeholder=""></textarea>
            </mat-form-field>
            <app-pdf-snippet [language]="pdfData.language" 
                            [type]="EnumPdfSnippet.TasksInterestDeliveryTime"
                            (onSelect)="pdfData.deliveryTime=$event">
            </app-pdf-snippet>
          </div>

          <div class="padding-10-0 flex">
            <mat-form-field appearance="outline">
              <mat-label>Fizetési Mód</mat-label>
              <textarea matInput [(value)]="pdfData.payMethod" placeholder=""></textarea>
            </mat-form-field>
            <app-pdf-snippet [language]="pdfData.language" 
                            [type]="EnumPdfSnippet.TasksInterestPayMethod"
                            (onSelect)="pdfData.payMethod=$event">
            </app-pdf-snippet>
          </div>

          <div class="padding-10-0 flex">
            <mat-form-field appearance="outline">
              <mat-label>Árak Érvényessége</mat-label>
              <textarea matInput [(value)]="pdfData.priceValid" placeholder=""></textarea>
            </mat-form-field>
            <app-pdf-snippet [language]="pdfData.language" 
                            [type]="EnumPdfSnippet.TasksInterestPriceValid"
                            (onSelect)="pdfData.priceValid=$event">
            </app-pdf-snippet>
          </div>

          <div class="padding-10-0 flex">
            <mat-form-field appearance="outline">
              <mat-label>Garancia</mat-label>
              <textarea matInput [(value)]="pdfData.guarantee" placeholder=""></textarea>
            </mat-form-field>
            <app-pdf-snippet [language]="pdfData.language" 
                            [type]="EnumPdfSnippet.TasksInterestGuarantee"
                            (onSelect)="pdfData.guarantee=$event">
            </app-pdf-snippet>
          </div>
          
          <div>
            <button mat-mini-fab color="primary" class="margin-r-10" matStepperPrevious>
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button mat-mini-fab color="primary" matStepperNext>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Gépek</ng-template>
      <div>

        <div class="padding-10-0 w200">
          <mat-form-field appearance="outline">
            <mat-label>Áfa Százalék</mat-label>
            <input type="number" [(value)]="pdfData.vatPerc" matInput>
            <mat-icon matSuffix>%</mat-icon>
          </mat-form-field>
        </div>

        <div class="padding-10-0 w200">
          <mat-form-field appearance="outline">
            <mat-label>Pénznem</mat-label>
            <mat-select [(value)]="pdfData.currency">
              <mat-option [value]="EnumCurrency.HUF">HUF</mat-option>
              <mat-option [value]="EnumCurrency.EUR">EUR</mat-option>
              <mat-option [value]="EnumCurrency.USD">USD</mat-option>
          </mat-select>
          </mat-form-field>
        </div>

          <mat-card class="margin-10-0" *ngFor="let product of pdfData.products">
              <ng-container *ngIf="pdfData.language=='en'; else huProductName"><span class="text-bold">{{product.nameEn}}</span></ng-container>
              <ng-template #huProductName><span class="text-bold">{{product.nameHu}}</span></ng-template>    

              <div class="flex flex-wrap">
                <div class="padding-r-10 w200">
                  <mat-form-field appearance="outline">
                    <mat-label>Ár - <span class="text-capital">{{pdfData.currency}}</span></mat-label>
                    <input type="number" [(value)]="product.price" matInput>
                  </mat-form-field>
              </div>
  
                <div class="padding-r-10 w200">
                  <mat-form-field appearance="outline">
                    <mat-label>Gyártási Év</mat-label>
                    <input type="number" [(value)]="product.year" matInput>
                  </mat-form-field>
                </div>
  
                <div class="padding-r-10 w200">
                  <mat-form-field appearance="outline">
                    <mat-label>Származási Ország</mat-label>
                    <input [(value)]="product.countryOfOrigin" matInput>
                  </mat-form-field>
                </div>
                
                <div class="padding-r-10 w200">
                  <mat-form-field appearance="outline">
                    <mat-label>VTSZ</mat-label>
                    <input [(value)]="product.customTarifNumber" matInput>
                  </mat-form-field>
                </div>

              </div>
          </mat-card>
     

        <div>
          <button mat-mini-fab color="primary" class="margin-r-10" matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button mat-mini-fab color="primary" matStepperNext>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>

      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Befejezés</ng-template>
      <div>

        <div class="padding-10-0 flex">
          <mat-form-field appearance="outline">
            <mat-label>Befejezés</mat-label>
            <textarea matInput [(value)]="pdfData.finish" placeholder=""></textarea>
          </mat-form-field>
          <app-pdf-snippet [language]="pdfData.language" 
                          [type]="EnumPdfSnippet.TasksInterestFinish"
                          (onSelect)="pdfData.finish=$event">
          </app-pdf-snippet>
        </div>

        <div>
          <button mat-mini-fab color="primary" class="margin-r-10" matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button mat-mini-fab color="primary" matStepperNext>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>

      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>PDF Generálás</ng-template>
      <div>
        <span class="margin-r-10">
          <button mat-mini-fab color="primary"  matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </span>
        <span class="margin-r-10">
          <button mat-flat-button color="accent" class="margin-r-10" (click)="pdfGeneration()">PDF GENERÁLÁS</button>
        </span>
        <button mat-button (click)="stepper.reset()">Újrakezdés</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</ng-template>