import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { Utils } from '../shared/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
/**
 * This is the real USERS service!
 */
export class UsersService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL = '/accounts/users/';
        this.URL_NEW_PASSWORD_REQUEST = '/accounts/password/new/request/';
        this.URL_SET_NEW_PASSWORD = '/accounts/password/new/';
        this.URL_VERIFY_EMAIL_REQUEST = '/accounts/email/verify/request/';
        this.dataSource$ = new BehaviorSubject([]);
        this.data = this.dataSource$.asObservable();
    }
    list(options = null) {
        let url = null;
        let params = null;
        // set options
        let base = { url: null, params: null };
        if (options) {
            options = Object.assign({}, base, options);
        }
        else {
            options = base;
        }
        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
            url = options.url;
        }
        else {
            url = this.baseUrl + this.URL;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }
        return this.http.get(url, { params }).pipe(map(res => {
            res.results = res.results.map(u => new User(u));
            return res;
        }));
    }
    getActiveUsers() {
        // get the list of active + verified users - slim data
        let url = this.baseUrl + this.URL + 'active_users/';
        return this.http.get(url).pipe(map(res => {
            res = res.users.map(u => new User(u));
            return res;
        }));
    }
    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get(url).pipe(map(res => new User(res)));
    }
    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(map(result => new User(result)), tap(res => { this.notificationService.created(); }));
    }
    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(map(res => new User(res)), tap(res => { this.notificationService.updated(); }));
    }
    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(tap(res => { this.notificationService.deleted(); }));
    }
    newPasswordRequest(user) {
        // send for the user an New Password Request verification
        let url = this.baseUrl + this.URL_NEW_PASSWORD_REQUEST;
        let payload = { email: user.email };
        return this.http.post(url, payload).pipe(tap(res => { this.notificationService.emailSent(); }));
    }
    setNewPassword(token, password) {
        // send new password for the user
        // with the valid token update the password
        let url = this.baseUrl + this.URL_SET_NEW_PASSWORD + token + '/';
        let payload = { password: password };
        return this.http.post(url, payload).pipe(tap(res => { this.notificationService.success(); }));
    }
    verifyEmailRequest(user) {
        // send the user email to verify the email address
        let url = this.baseUrl + this.URL_VERIFY_EMAIL_REQUEST;
        let payload = { email: user.email };
        return this.http.post(url, payload).pipe(tap(res => { this.notificationService.emailSent(); }));
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: UsersService, providedIn: "root" });
