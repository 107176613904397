
import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatPaginator } from '@angular/material';
import { environment } from '../../environments/environment';
import { UrlParamService } from '../services/urlParam.service';
import { ProductService } from '../services/product.service';
import { BaseList } from '../common/baseClasses/baseList';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [UrlParamService]
})
export class ProductsComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  displayedColumns = ['manufacturer', 'nameHu', 'category',  'productType'];
  type = 'machine'

  constructor(
    private productService: ProductService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {productType: this.type}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.productService.list(options).subscribe(data => {
        this.setListResponseData(data);
      });
    })
  }

  open(entity) {
    this.router.navigate(['product'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  lookup() {
    this.doSearch();
  }

  changeType(event) {
    this.resetFilters();
    
    this.type = event.value
    let params = {productType: this.type};
    
    this.urlParamService.update(params);
    this.urlParamService.set();
  }
}