import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { LoginComponent } from './public/login/login.component';
import { DashComponent } from './dash/dash.component';
import { ContactsComponent } from './contacts/contacts.component';
import { CompaniesComponent } from './accounts/companies/companies.component';
import { OrganisationsComponent } from './contacts/organisations/organisations.component';
import { RolesComponent } from './accounts/roles/roles.component';
import { RoleComponent } from './accounts/roles/role/role.component';
import { UsersComponent } from './accounts/users/users.component';
import { UserComponent } from './accounts/users/user/user.component';
import { PersonsComponent } from './contacts/persons/persons.component';
import { ProjectsComponent } from './projects/projects.component';
import { ItemsComponent } from './items/items.component';
import { ProductsComponent } from './products/products.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { CategoriesComponent } from './categories/categories.component';
import { PageManufacturerComponent } from './manufacturers/page-manufacturer/page-manufacturer.component';
import { PageCategoryComponent } from './categories/page-category/page-category.component';
import { PageItemComponent } from './items/page-item/page-item.component';
import { PageProductComponent } from './products/page-product/page-product.component';
import { PageOrganisationComponent } from './contacts/organisations/page-organisation/page-organisation.component';
import { PagePersonComponent } from './contacts/persons/page-person/page-person.component';
import { RequestForgottenPassowrdComponent } from './public/request-forgotten-passowrd/request-forgotten-passowrd.component';
import { ModuleGuard } from './services/module.guard';
import { ProjectPlansComponent } from './project-plans/project-plans.component';
import { ProjectPlanComponent } from './project-plans/project-plan/project-plan.component';
import { ActionsPlansComponent } from './project-plans/actions-plans/actions-plans.component';
import { PageActionPlansComponent } from './project-plans/actions-plans/page-action-plans/page-action-plans.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectSetupComponent } from './projects/project-setup/project-setup.component';
import { ProjectTypesComponent } from './project-plans/project-types/project-types.component';
import { ProjectTypeComponent } from './project-plans/project-types/project-type/project-type.component';
import { TaskInterestsComponent } from './tasks/task-interests/task-interests.component';
import { TaskInterestComponent } from './tasks/task-interests/task-interest/task-interest.component';
import { TaskInterestSetupComponent } from './tasks/task-interests/task-interest-setup/task-interest-setup.component';
import { SetNewPasswordComponent } from './public/set-new-password/set-new-password.component';
const ɵ0 = { module: 'open' }, ɵ1 = { module: 'contacts' }, ɵ2 = { module: 'contacts' }, ɵ3 = { module: 'contacts' }, ɵ4 = { module: 'contacts' }, ɵ5 = { module: 'contacts' }, ɵ6 = { module: 'contacts' }, ɵ7 = { module: 'contacts' }, ɵ8 = { module: 'contacts' }, ɵ9 = { module: 'accounts' }, ɵ10 = { module: 'accounts' }, ɵ11 = { module: 'accounts' }, ɵ12 = { module: 'accounts' }, ɵ13 = { module: 'accounts' }, ɵ14 = { module: 'accounts' }, ɵ15 = { module: 'accounts' }, ɵ16 = { module: 'accounts' }, ɵ17 = { module: 'projects' }, ɵ18 = { module: 'projects' }, ɵ19 = { module: 'projects' }, ɵ20 = { module: 'projects' }, ɵ21 = { module: 'items' }, ɵ22 = { module: 'items' }, ɵ23 = { module: 'items' }, ɵ24 = { module: 'items' }, ɵ25 = { module: 'items' }, ɵ26 = { module: 'items' }, ɵ27 = { module: 'items' }, ɵ28 = { module: 'items' }, ɵ29 = { module: 'items' }, ɵ30 = { module: 'items' }, ɵ31 = { module: 'items' }, ɵ32 = { module: 'items' }, ɵ33 = { module: 'projectplanning' }, ɵ34 = { module: 'projectplanning' }, ɵ35 = { module: 'projectplanning' }, ɵ36 = { module: 'projectplanning' }, ɵ37 = { module: 'projectplanning' }, ɵ38 = { module: 'projectplanning' }, ɵ39 = { module: 'projectplanning' }, ɵ40 = { module: 'projectplanning' }, ɵ41 = { module: 'projectplanning' }, ɵ42 = { module: 'open' }, ɵ43 = { module: 'taskinterest' }, ɵ44 = { module: 'taskservice' }, ɵ45 = { module: 'taskservice' }, ɵ46 = { module: 'taskinterest' };
/**
 * data-> module is manage the access of the page
 */
const routes = [
    { path: 'login', component: LoginComponent },
    { path: 'request-forgotten-password', component: RequestForgottenPassowrdComponent },
    { path: 'new-password', component: SetNewPasswordComponent },
    { path: 'app', canActivate: [AuthGuard], canActivateChild: [ModuleGuard], children: [
            { path: 'dash', component: DashComponent, data: ɵ0 },
            { path: '', redirectTo: 'dash', pathMatch: 'full' },
            { path: 'contacts', data: ɵ1, children: [
                    { path: 'organisations', data: ɵ2, children: [
                            { path: 'organisation', component: PageOrganisationComponent, data: ɵ3 },
                            { path: '', component: OrganisationsComponent, data: ɵ4 }
                        ] },
                    { path: 'persons', data: ɵ5, children: [
                            { path: 'person', component: PagePersonComponent, data: ɵ6 },
                            { path: '', component: PersonsComponent, data: ɵ7 }
                        ] },
                    { path: '', component: ContactsComponent, data: ɵ8 }
                ] },
            { path: 'accounts', data: ɵ9, children: [
                    { path: 'company', component: CompaniesComponent, pathMatch: 'full', data: ɵ10 },
                    { path: 'roles', data: ɵ11, children: [
                            { path: 'role', component: RoleComponent, data: ɵ12 },
                            { path: '', component: RolesComponent, data: ɵ13 }
                        ] },
                    { path: 'users', data: ɵ14, children: [
                            { path: 'user', component: UserComponent, data: ɵ15 },
                            { path: '', component: UsersComponent, data: ɵ16 }
                        ] }
                ] },
            { path: 'projects', data: ɵ17, children: [
                    { path: 'project', component: ProjectComponent, data: ɵ18 },
                    { path: 'project-setup', component: ProjectSetupComponent, data: ɵ19 },
                    { path: '', component: ProjectsComponent, data: ɵ20 }
                ] },
            { path: 'items', data: ɵ21, children: [
                    { path: 'item', component: PageItemComponent, data: ɵ22 },
                    { path: '', component: ItemsComponent, data: ɵ23 }
                ] },
            { path: 'products', data: ɵ24, children: [
                    { path: 'product', component: PageProductComponent, data: ɵ25 },
                    { path: '', component: ProductsComponent, data: ɵ26 }
                ] },
            { path: 'manufacturers', data: ɵ27, children: [
                    { path: 'manufacturer', component: PageManufacturerComponent, data: ɵ28 },
                    { path: '', component: ManufacturersComponent, data: ɵ29 }
                ] },
            { path: 'categories', data: ɵ30, children: [
                    { path: 'category', component: PageCategoryComponent, data: ɵ31 },
                    { path: '', component: CategoriesComponent, data: ɵ32 }
                ] },
            { path: 'project-plans', data: ɵ33, children: [
                    { path: 'project', component: ProjectPlanComponent, data: ɵ34 },
                    { path: 'actions', data: ɵ35, children: [
                            { path: 'action', component: PageActionPlansComponent, data: ɵ36 },
                            { path: '', component: ActionsPlansComponent, data: ɵ37 }
                        ] },
                    { path: 'types', data: ɵ38, children: [
                            { path: 'type', component: ProjectTypeComponent, data: ɵ39 },
                            { path: '', component: ProjectTypesComponent, data: ɵ40 }
                        ] },
                    { path: '', component: ProjectPlansComponent, data: ɵ41 }
                ] },
            { path: 'tasks', data: ɵ42, children: [
                    { path: 'interests', data: ɵ43, children: [
                            { path: 'interest', component: TaskInterestComponent, data: ɵ44 },
                            { path: 'interest-setup', component: TaskInterestSetupComponent, data: ɵ45 },
                            { path: '', component: TaskInterestsComponent, data: ɵ46 }
                        ] },
                ] },
        ] },
    { path: '**', component: LoginComponent }
];
//useHash: hash based urls: to make work deeplinking
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46 };
