import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Company } from '../models/company.model';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';

/**
 * This is for the User - Company details!
 */

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/company/';

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService
        ) {}

    load() {
        // get the user company company
        let url = this.baseUrl  + this.URL;
        return this.httpClient.get(url).pipe(
            // return a list but we currently only one makes sense
            map(res => new Company(res))
        )
    }

    update(payload) {
        // remove files to avoid issue with the validation. On the object the fiels are represetned with URL 
        // but the backend expect Files. Thisi service is only handle GET.
        // if you want to upload file use FileService
        delete payload.logo;
        delete payload.pdfHeader;

        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.httpClient.put(url, payload).pipe(
            // return a list but we currently only one makes sense
            map(res => new Company(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

}