import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Role } from '../models/role.model';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';

@Injectable({
    providedIn: 'root'
  })
export class RolesService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/roles/';

    // list of the roles
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        let url = null;
        let params = null;

         // set options
         let base = {url: null, params: null}
         if (options) {
             options = {...base, ...options};
         } else {
             options = base;
         }
 
         if (options.url) {
             // if url sent (paginated) we don't care about params just grab the data by the url
            url = options.url; 
         } else {
             url = this.baseUrl + this.URL;
             if (options.params) {
                 params = Utils.getParams(options.params);
             }
         }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(role => new Role(role))
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get<Role>(url).pipe(
            map(res => new Role(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(
            map(result => new Role(result)),
            tap(res => { this.notificationService.created(); })
        )
    }


    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Role(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}