import { Component, OnInit, Input } from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { BottomOrganisatonDetailsComponent } from './bottom-organisaton-details/bottom-organisaton-details.component';
import { OrganisationService } from 'src/app/services/organisation.service';

@Component({
  selector: 'app-bottom-organisation',
  templateUrl: './bottom-organisation.component.html',
  styleUrls: ['./bottom-organisation.component.scss']
})
export class BottomOrganisationComponent implements OnInit {
  @Input() data: any = null;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private organisationServide: OrganisationService,
  ) {}

  ngOnInit() {
  }

  openBottomSheet(): void {
    if (typeof this.data == "number" ) {
        this.getOrganisation();
    } else {
        this._openBottomSheet();
    }
  }

  _openBottomSheet(): void {
    // passing the data to the component which displayed in the bootm sheet
    this._bottomSheet.open(BottomOrganisatonDetailsComponent, {data: this.data});
  }

  getOrganisation() {
    this.organisationServide.get(this.data).subscribe(res => {
      this.data = res;
      this._openBottomSheet();
    });
  };

}
