/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-category.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./category/category.component.ngfactory";
import * as i5 from "./category/category.component";
import * as i6 from "../../services/category.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./page-category.component";
import * as i9 from "../../services/modelFactory.service";
var styles_PageCategoryComponent = [i0.styles];
var RenderType_PageCategoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageCategoryComponent, data: {} });
export { RenderType_PageCategoryComponent as RenderType_PageCategoryComponent };
export function View_PageCategoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ul", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Kateg\u00F3ria"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Katg\u00F3ria"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-category", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_CategoryComponent_0, i4.RenderType_CategoryComponent)), i1.ɵdid(14, 114688, null, 0, i5.CategoryComponent, [i6.CategoryService, i7.MatDialog], { category: [0, "category"] }, { eventCreated: "eventCreated", eventUpdated: "eventUpdated", eventDeleted: "eventDeleted" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "../"); _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.category; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.category.nameHu; _ck(_v, 7, 0, currVal_3); }); }
export function View_PageCategoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-category", [], null, null, null, View_PageCategoryComponent_0, RenderType_PageCategoryComponent)), i1.ɵdid(1, 114688, null, 0, i8.PageCategoryComponent, [i2.ActivatedRoute, i2.Router, i6.CategoryService, i9.ModelFactory], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageCategoryComponentNgFactory = i1.ɵccf("app-page-category", i8.PageCategoryComponent, View_PageCategoryComponent_Host_0, {}, {}, []);
export { PageCategoryComponentNgFactory as PageCategoryComponentNgFactory };
