import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-dlg-search-user',
  templateUrl: './dlg-search-user.component.html',
  styleUrls: ['./dlg-search-user.component.scss']
})
export class DlgSearchUserComponent implements OnInit {
    //text which we searching 
    txtSearch: string = '';
    timeout = null;
    options: User[] = null; 

  constructor(
    private userService: UsersService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchUserComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;

    this.userService.getActiveUsers().subscribe(users => {
      this.options = users;
    });
  }

  ngOnInit() {}

  select(user) {
    if (!user) {
      return;
    }
    this.txtSearch = user.lastName + ' ' + user.firstName;

    setTimeout(() => {
      this.dialogRef.close(user);
    }, 200);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }

}
