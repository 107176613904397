import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { ListResponse } from '../interfaces/httpResponse.interface';
import { UserRole } from '../models/userRole.model';
import { Utils } from '../shared/utils';


@Injectable({
    providedIn: 'root'
})
export class UserRolesService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/user-roles/';

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }
        return this.httpClient.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(userRole => new UserRole(userRole)))
            )
    }

    getByUser(userId: number) {
        // get the list of the roles for a user
        let url = this.baseUrl  + this.URL;
        
        let params = new HttpParams();
        params = params.append('user', userId.toString());
        params = params.append('limit', '1000');
       
        return this.httpClient.get<ListResponse>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(role => new UserRole(role))
                return res;
            })
            )
    }


    get(id: number) {
        // get userRole by id
        let url = this.baseUrl + this.URL + id + '/';
        return this.httpClient.put(url, id).pipe(
            map(res => new UserRole(res))
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.httpClient.put(url, payload).pipe(
            map(res => new UserRole(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.httpClient.post(url, payload).pipe(
            map(res => new UserRole(res)),
            tap(res => { this.notificationService.created(); }),
            tap(res => { this.list() })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.httpClient.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

}