/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-importance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./project-importance.component";
var styles_ProjectImportanceComponent = [i0.styles];
var RenderType_ProjectImportanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectImportanceComponent, data: {} });
export { RenderType_ProjectImportanceComponent as RenderType_ProjectImportanceComponent };
function View_ProjectImportanceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "level level-", (_v.context.index + 1), ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_ProjectImportanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "project-importance-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "div", [["class", "levels"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectImportanceComponent_1)), i1.ɵdid(4, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.txtImportance; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.importances; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProjectImportanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-importance", [], null, null, null, View_ProjectImportanceComponent_0, RenderType_ProjectImportanceComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProjectImportanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectImportanceComponentNgFactory = i1.ɵccf("app-project-importance", i10.ProjectImportanceComponent, View_ProjectImportanceComponent_Host_0, { importance: "importance" }, {}, []);
export { ProjectImportanceComponentNgFactory as ProjectImportanceComponentNgFactory };
