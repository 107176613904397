export class TaskInterestPdf {
    id: number = null;
    file: string = null;
    name: string = null;
    status: string = null;
    task: number = null;

    assignee: number = null;
    _assignee: any = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.file = data.file;
            this.name = data.name;
            this.status = data.status;
            this.task = data.task;
            
            this.assignee = data.assignee;
            if ('_assignee' in data) {
                this._assignee = data._assignee;
            }
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
