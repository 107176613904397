import { Component, Input, OnInit, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from '@angular/core';
import { Profile } from 'src/app/models/profile.model';
import { ProfileService } from 'src/app/services/profile.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';


/**
 * display one profile for the amdin section
 */

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseEntity implements OnInit {
  profile: Profile =  null;

  // pass to userId to the view
  @Input()
  userId: number;

  constructor(
    private profileService: ProfileService,
    private modelFactory: ModelFactory
  ) {
    super();

    this.profile = this.modelFactory.getNewModel(Profile);
  }

  ngOnInit() {
    if (this.userId) {
      this.profileService.getByUser(this.userId).subscribe(data => {
        this.profile = data;
      });
    }
   
  }

  update() {
    this.profileService.update(this.profile).subscribe(profile => {
      this.profile = profile;
    })
  }

}
