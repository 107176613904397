export class Action {
    id: Number = null;
    name: string = null;
    order: number = null;
    status: string = null;
    project: number = null;
    _project?: any = null;
    action_template: number = null;
    _actionTemplate?: any = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 
    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name  = data.name;
            this.order = data.order;
            this.status = data.status;
            this.project = data.project;
            
            if ('_project' in data) {
                this._project = data._project;
            }

            if ('action_template' in data) {
                this.action_template = data.action_template;
            }

            if ('_actionTemplate' in data) {
                this._actionTemplate = data._actionTemplate;
            }
            
            if ('creator' in data) {
                this.creator = data.creator;
            }    
            
            if ('lastModifier' in data) {
                this.lastModifier = data.lastModifier;
            }    
            
            if ('created' in data) {
                this.created = new Date(data.created);
            }    
            
            if ('updated' in data) {
                this.updated = new Date(data.updated);
            }

        }  
    }
  }
