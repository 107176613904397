/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-people-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./bottom-people-detail.component";
import * as i10 from "@angular/material/bottom-sheet";
import * as i11 from "@angular/router";
var styles_BottomPeopleDetailComponent = [i0.styles];
var RenderType_BottomPeopleDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomPeopleDetailComponent, data: {} });
export { RenderType_BottomPeopleDetailComponent as RenderType_BottomPeopleDetailComponent };
function View_BottomPeopleDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["email"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.email; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_3); }); }
function View_BottomPeopleDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomPeopleDetailComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.emails; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BottomPeopleDetailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["phone"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.phone; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_3); }); }
function View_BottomPeopleDetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomPeopleDetailComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.phones; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BottomPeopleDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["color", "primary"], ["mat-mini-fab", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["edit"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomPeopleDetailComponent_1)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomPeopleDetailComponent_3)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "primary"; _ck(_v, 6, 0, currVal_4); _ck(_v, 8, 0); var currVal_7 = _co.data.emails; _ck(_v, 13, 0, currVal_7); var currVal_8 = _co.data.phones; _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.lastName; var currVal_1 = _co.data.firstName; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 8).inline; var currVal_6 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_BottomPeopleDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-people-detail", [], null, null, null, View_BottomPeopleDetailComponent_0, RenderType_BottomPeopleDetailComponent)), i1.ɵdid(1, 114688, null, 0, i9.BottomPeopleDetailComponent, [i10.MAT_BOTTOM_SHEET_DATA, i10.MatBottomSheetRef, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomPeopleDetailComponentNgFactory = i1.ɵccf("app-bottom-people-detail", i9.BottomPeopleDetailComponent, View_BottomPeopleDetailComponent_Host_0, {}, {}, []);
export { BottomPeopleDetailComponentNgFactory as BottomPeopleDetailComponentNgFactory };
