import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ProjectActionPlan } from '../models/projectActionPlan.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class PlanningProjectActionsService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL_PROJECT_ACTIONS_PLAN = '/project-plans/project-actions/';
        // list items
        this.dataSource$ = new BehaviorSubject([]);
        this.data = this.dataSource$.asObservable();
    }
    list(projectPlanId) {
        // list all Actions Plans for a project paln
        // - projectPlanId is mandatory
        if (!projectPlanId) {
            throw 'Only by Project';
        }
        let params = { project: projectPlanId };
        let url = this.baseUrl + this.URL_PROJECT_ACTIONS_PLAN;
        return this.http.get(url, { params }).pipe(map(res => {
            res = res.map(i => new ProjectActionPlan(i));
            return res;
        }));
    }
    save(projectId, payload) {
        // save the list of action plans
        let url = this.baseUrl + this.URL_PROJECT_ACTIONS_PLAN + '?projectId=' + projectId;
        return this.http.post(url, payload).pipe(map(res => {
            res = res.map(i => new ProjectActionPlan(i));
            return res;
        }), tap(res => { this.notificationService.created(); }));
    }
    get() {
        throw 'Not Implemented!';
    }
}
PlanningProjectActionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanningProjectActionsService_Factory() { return new PlanningProjectActionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: PlanningProjectActionsService, providedIn: "root" });
