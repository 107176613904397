<div class="grid">
<div *ngFor="let item of orgItems">
    <div class="section">
        <div class="header">
            <div class="title">
                <ng-container *ngIf="item._item._product.type == 'machine'; else part">
                    <mat-icon matTooltip="Gép">local_laundry_service</mat-icon>
                </ng-container>
                <ng-template #part>
                    <mat-icon matTooltip="Alkatrész">plumbing</mat-icon>
                </ng-template>
            </div>
        </div>
        <form #f="ngForm">
            <div class="padding-10-0">
                <a [routerLink]="['../../../items/item']" [queryParams]="{id: item.item}">
                    <div matTooltip="{{item._item._product.nameEn}}">{{item._item._product.nameHu}}</div>
                </a>    
                
                <span class="badge badge-pill badge-warning">{{item._item.serialNumber}}</span>
                
                <ng-container *ngIf="item._item.parent">
                    <div class="text-small text-secondary padding-10-0" matTooltip="{{item._item._parent._product.nameEn}}">
                        <a [routerLink]="['../../../items/item']" [queryParams]="{id: item._item.parent}">
                            Beszerelve: {{item._item._parent._product.nameHu}} -  {{item._item._parent.serialNumber}}
                        </a>
                    </div>
                </ng-container>
               
            </div>
            <p class="flex">
                <span class="padding-l-10">
                    <button mat-mini-fab  color="primary" (click)="editOrgItemDlg(item)"  [disabled]="f.invalid" matTooltip="Szerkeszés">
                        <mat-icon>edit</mat-icon>
                    </button>
                </span>
                <span class="padding-l-10">
                    <app-quick-log-info [data]="item"></app-quick-log-info>
                </span>
            </p>     
        </form>          
    </div>
</div>  
</div>

<div class="padding-20">
    <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új Termék Hozzáadás">
        <mat-icon>add</mat-icon>
    </button>
</div>



