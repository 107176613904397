<div class="padding-20-0 text-center">
    Termék Keresés
</div>
<ng-container *ngIf="item">
    <div *ngIf="item._product" class="text-secondary text-small padding-20-0">
        <span >{{item._product.nameHu}}</span> - 
        <span class="badge badge-pill badge-warning">{{item.serialNumber}}</span>
    </div>
</ng-container>
<form class="padding-10-0">
    <mat-form-field appearance="outline">
        <mat-icon class="pointer text-red"   (click)="delete()" matSuffix matTooltip="Töröl">close</mat-icon>
        <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés (min 3)"  [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
            <div class="truncate">{{option._product.nameHu}}</div>
            <div>{{option.serialNumber}}</div>      
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div class="flex padding-l-10">
        <div class="full">
            <button mat-mini-fab  color="primary" (click)="save()"  matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </div>
        <div>
            <button mat-mini-fab  color="accent" (click)="openItemCreateDlg()"  matTooltip="új Termék">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</form>

