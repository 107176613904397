import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Company } from '../models/company.model';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
/**
 * This is for the User - Company details!
 */
export class CompanyService {
    constructor(httpClient, notificationService) {
        this.httpClient = httpClient;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL = '/accounts/company/';
    }
    load() {
        // get the user company company
        let url = this.baseUrl + this.URL;
        return this.httpClient.get(url).pipe(
        // return a list but we currently only one makes sense
        map(res => new Company(res)));
    }
    update(payload) {
        // remove files to avoid issue with the validation. On the object the fiels are represetned with URL 
        // but the backend expect Files. Thisi service is only handle GET.
        // if you want to upload file use FileService
        delete payload.logo;
        delete payload.pdfHeader;
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.httpClient.put(url, payload).pipe(
        // return a list but we currently only one makes sense
        map(res => new Company(res)), tap(res => { this.notificationService.updated(); }));
    }
}
CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: CompanyService, providedIn: "root" });
