import { Component, Inject, OnInit } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-quick-log-info-details',
  templateUrl: './quick-log-info-details.component.html',
  styleUrls: ['./quick-log-info-details.component.scss']
})
export class QuickLogInfoDetailsComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
  }

}
