import { MatDialogConfig } from '@angular/material';
import { DlgErrorComponent } from '../shared/dialogs/dlg-error/dlg-error.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export class ErrorMsgService {
    constructor(dialog) {
        this.dialog = dialog;
        // this service should find the proper error mesage based on the provided errorId
        // the error message is showed in a dialog
        this.error = null;
    }
    show(error) {
        this.error = error;
        this.openDialog();
    }
    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            error: this.error
        };
        this.dialog.open(DlgErrorComponent, dialogConfig);
    }
}
ErrorMsgService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorMsgService_Factory() { return new ErrorMsgService(i0.ɵɵinject(i1.MatDialog)); }, token: ErrorMsgService, providedIn: "root" });
