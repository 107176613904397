import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import { SecurityService } from '../services/security.service';

/**
 * Add to an DOM and based on the provided MODULE and Entity - decide it should be disaplay or not
 * example: <button canEdit [module]="'contacts'" [entity]="organisation">...</button>
 */

@Directive({
    selector: '[canEdit]'
})
export class CanEditDirective  implements OnInit {
    // name of the module
    @Input() module: string;
    // entity which is checked
    @Input() entity: any;

    constructor(
        private ss: SecurityService,
        private elementRef: ElementRef,
    ) {}
    
    
    ngOnInit() {
        if (!this.canEdit()) {
            // async rendering of the input field - we have to wait a bit...
            setTimeout(() => {
               this.secureNode(this.elementRef.nativeElement);
               this.checkChildrenNodes(this.elementRef.nativeElement.childNodes)
            })
        }
    }

    checkChildrenNodes(nodes) {
        nodes.forEach(node => {
            this.secureNode(node);

            if (node.childNodes.length > 0) {
                this.checkChildrenNodes(node.childNodes);
            }
        })
    }

    secureNode(element) {
        if (element instanceof HTMLInputElement) {
            // readonly input fields
            element.readOnly = true;
        } else if (element instanceof HTMLButtonElement) {
            // hide buttons
            element.hidden = true;
        } else if (element.localName == 'app-quick-log-info') {
            // hide quick log info buttons
            element.hidden = true;
        }
    }

    canEdit() {
        let ssModule = this.ss.get(this.module, this.entity);

        if (ssModule.canEdit()) {
            return true;
        }

        return false;
    }

}