<button color="primary" mat-button routerLink="/app/dash" (click)="clicked()">
    <mat-icon>dashboard</mat-icon>Vezérlőpult
  </button>
  
  <button color="primary" mat-button routerLink="/app/projects" (click)="clicked()">
    <mat-icon>linear_scale</mat-icon> Projektek
  </button>
 
    <!---------------Tasks--------------->
    <hr>
    <div class='text-center'>Feladatok</div>
    <button color="primary" mat-button routerLink="/app/projects" (click)="clicked()">
        <mat-icon>construction</mat-icon> Szervízek
    </button>
    <button color="primary" mat-button routerLink="/app/projects" (click)="clicked()">
        <mat-icon>how_to_vote</mat-icon> Beszerzések
    </button>
    <button color="primary" mat-button routerLink="/app/projects" (click)="clicked()">
        <mat-icon>attach_money</mat-icon> Megrendelések
    </button>
    <button color="primary" mat-button routerLink="/app/tasks/interests" (click)="clicked()">
        <mat-icon>3p</mat-icon> Megkeresések
    </button>

  
  <!---------------Contacts--------------->
  <ng-container *ngIf="contacts">
    <hr>
    <div class='text-center'>Telefonkönyv</div>
    <button color="primary" mat-button routerLink="/app/contacts/organisations" (click)="clicked()">
      <mat-icon>storefront</mat-icon> Cégek
    </button>
    <button color="primary" mat-button routerLink="/app/contacts/persons" (click)="clicked()">
      <mat-icon>assignment_ind</mat-icon> Kontaktok
    </button>
  </ng-container>
 
 
  <!---------------Items--------------->
  <ng-container *ngIf="items">
    <hr>
    <button color="primary" mat-button routerLink="/app/items" (click)="clicked()">
      <mat-icon>style</mat-icon> Termékek
    </button>
  </ng-container>
 

  <!---------------Project Templates--------------->
  <ng-container *ngIf="projectPlanning">
    <hr>
    <div class='text-center'>Projekt Tervezés</div>
    <button color="primary" mat-button routerLink="/app/project-plans" (click)="clicked()">
        <mat-icon>pattern</mat-icon> Project Sablon
    </button>
    <button color="primary" mat-button routerLink="/app/project-plans/actions" (click)="clicked()">
      <mat-icon>adjust</mat-icon> Lépés Sablon
  </button>
  <button color="primary" mat-button routerLink="/app/project-plans/types" (click)="clicked()">
    <mat-icon>label</mat-icon> Project Típusok
</button>
</ng-container>

  <!---------------admin--------------->
  <ng-container *ngIf="admin">
    <hr>
    <div class='text-center'>Admin</div>
    <button color="primary" mat-button routerLink="/app/accounts/users" (click)="clicked()">
        <mat-icon>account_circle</mat-icon> Felhasználók
    </button>
    <button color="primary" mat-button routerLink="/app/accounts/roles" (click)="clicked()">
        <mat-icon>security</mat-icon> Hozzáférés
    </button>
    <button color="primary" mat-button routerLink="/app/accounts/company" (click)="clicked()">
        <mat-icon>home_work</mat-icon> Szervezet
    </button>
</ng-container>
