import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnumModule } from '../enums/modules.enum';
import { ModuleSecurity } from '../models/moduleSecurity.model';
import { AuthService } from './auth.service';


@Injectable()
export class SecurityService implements OnInit {
    modelAccessLevels: any = null; 
    modelAccessLevels$ = new BehaviorSubject<any>(null);
    user: any = null;

    // only have ADMIN access
    ADMIN_ONLY_MODULES = [EnumModule.Accounts];
    // Everybody has READ and WRITE access
    BASE_MODULES = [EnumModule.Contacts, EnumModule.Items]

    constructor(
        private authService: AuthService
    ) {
        this.authService.accessLevel$.subscribe(res => {
            // get all the access levels for the Modules
            this.modelAccessLevels = res;
            // used to signal that the access is available
            // Todo: not the best solution to store both way...
            this.modelAccessLevels$.next(res);
        });

        this.authService.user$.subscribe(res => {
            // get the User
            this.user = res;
        });
    }

    ngOnInit() {}

    public hasAccess(moduleName) {
        // based on the module name return check if user has access to the module
        if (this.modelAccessLevels.admin) {
            return true;
        }

        if (this.ADMIN_ONLY_MODULES.indexOf(moduleName) > -1) {
            // Admin only module
            return false;
        } else if(this.BASE_MODULES.indexOf(moduleName) > -1) {
            // everybody has access
            return true;
        }

        let module = this.getRawSettings(moduleName);
        return module.access;
    }

    public getRawSettings(moduleName) {
        // based on the provided module name return the settings
        if (!moduleName) {
        return false;
        }

        if (this.modelAccessLevels.hasOwnProperty(moduleName) == -1) {
            // module not found based on the name
            return false;
        }

        return this.modelAccessLevels[moduleName];
    }

    public get(moduleName, entity) {
        // based on the Module name and the entity which is passed in setup the Security Model
        // @moduleName = txt name of the module
        // @entity = the object which we checking (Item, Organisaiton ...), it has a 'creator' property based on which we make the decistion
        let rawSetting = this.getRawSettings(moduleName);

        let setting = new ModuleSecurity(rawSetting, this.user, entity)

        // Stamp the Data specific settings with the Static Module 'hasAccess check for easy access
        setting['hasAccess'] = this.hasAccess(moduleName);

        return setting;
    }
}