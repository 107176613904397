import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification';
import { ProjectPlan } from '../models/projectPlan.model';
import { Utils } from '../shared/utils';


@Injectable({
    providedIn: 'root'
  })
export class PlanningProjectService {
    baseUrl = environment.baseUrl;

    URL_PROJECT_PLAN = '/project-plans/projects/';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_PROJECT_PLAN;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new ProjectPlan(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_PROJECT_PLAN + id + '/';
        return this.http.get<ProjectPlan>(url).pipe(
            map(res => new ProjectPlan(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_PROJECT_PLAN;
        return this.http.post(url, payload).pipe(
            map(result => new ProjectPlan(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_PROJECT_PLAN + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new ProjectPlan(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_PROJECT_PLAN + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}