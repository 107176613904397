import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { RolesService } from 'src/app/services/roles.service';
import { UrlParamService } from 'src/app/services/urlParam.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  providers: [UrlParamService]
})
export class RolesComponent extends BaseList implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  
  constructor(
    private rolesServide: RolesService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  displayedColumns = ['role'];

  ngOnInit() {
    let defaultParams = {};

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.rolesServide.list(options).subscribe(data => {
        this.setListResponseData(data);
      });
    })
  }

  open(role) {
    this.router.navigate(['role'],  {
      queryParams: {id: role.id}, 
      relativeTo: this.route  
    });
  }

}
