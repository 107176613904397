import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification';
import { Action } from '../models/action.model';
import { Utils } from '../shared/utils';


@Injectable({
    providedIn: 'root'
  })
export class ActionService {
    baseUrl = environment.baseUrl;

    URL = '/actions';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res = res.map(i => new Action(i));
                return res;
            })
        )
    }

    save(projectId, payload) {
        // save the list of action plans
        let url = this.baseUrl + this.URL + '/?projectId=' + projectId;
        return this.http.post<any>(url, payload).pipe(
            map(res => {
                res = res.map(i => new Action(i));
                return res;
            }),
            tap(res => { this.notificationService.created(); })
        )
    }

    get() {
        throw 'Not Implemented!';
     }


    create(payload) {
        throw 'Not Implemented!';
    }

    update(payload) {
        // not possible to update every field
        let url = this.baseUrl + this.URL + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Action(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }
    

    delete(payload) {
        throw 'Not Implemented!';
    }
}