import { Pipe, PipeTransform } from '@angular/core';

/**
 * Truncate the length of the text based on the words
 */

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(text: any, length: number = 20, suffix: string = '...'): any {
    if (!text) {
        return;
    }
    
    if (text.split(" ").length > length ) {
      
      return text.split(" ").splice(0, length).join(" ") + suffix;
    }

    return text;
  }

}