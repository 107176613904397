import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from 'src/app/models/product.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { DlgEditCategoryComponent } from '../dlg-edit-category/dlg-edit-category.component';

@Component({
  selector: 'app-dlg-edit-product',
  templateUrl: './dlg-edit-product.component.html',
  styleUrls: ['./dlg-edit-product.component.scss']
})
export class DlgEditProductComponent implements OnInit {
  product: Product = null;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.product = this.modelFactory.getNewModel(Product);

    if (data) {
      this.product = data;
    }
  }

  ngOnInit() {}

  created(event) {
    this.closeDlg()
  }

  updated(event) {
    this.closeDlg();
  }

  deleted(event) {
    this.closeDlg();
  }

  closeDlg() {
    this.dialogRef.close();
  }

}

