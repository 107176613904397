import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { ProjectTypeService } from 'src/app/services/projectType.service';
import { UrlParamService } from 'src/app/services/urlParam.service';

@Component({
  selector: 'app-project-types',
  templateUrl: './project-types.component.html',
  styleUrls: ['./project-types.component.scss'],
  providers: [UrlParamService]
})
export class ProjectTypesComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  displayedColumns = ['name'];

  archived = false;

  constructor(
    private projectTypeService: ProjectTypeService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  )  {
    super();

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {archived: this.archived}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.projectTypeService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  open(entity) {
    this.router.navigate(['type'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  changeStatus(event) {
    this.resetFilters();

    // active or archived
    this.archived = (event.value == 'archived') ? true : false;
    
    let param = {archived: this.archived};   
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

}
