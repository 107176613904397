import { Component, OnInit, Input } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { OrganisationPhone } from 'src/app/models/organisation';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { OrganisationService } from 'src/app/services/organisation.service';

@Component({
  selector: 'app-organisation-phone',
  templateUrl: './organisation-phone.component.html',
  styleUrls: ['./organisation-phone.component.scss']
})
export class OrganisationPhoneComponent extends BaseEntity implements OnInit {
  @Input() organisationId: number;

  phones: OrganisationPhone[] = [];

  constructor(
    private organisationService: OrganisationService,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {    
    let params = {organisation: this.organisationId};
    this.organisationService.listPhones(params).subscribe(resp => {
      this.phones = resp;
    })
  }

  save(phone) {
    if (typeof phone.id === 'string') {
      // create
      this.create(phone);
    } else {
      this.update(phone);
    }
  }

  create(phone) {
    this.organisationService.createPhone(phone).subscribe(res => {
      // fitler out the dummy email
      this.phones = this.phones.filter(e => e.phone != res.phone);
      // add the newly created to the list
      this.phones.push(res)
    })
  }

  update(phone) {
    this.organisationService.updatePhone(phone).subscribe()
  }

  add() {
    // add new element to the list
    let phone = this.modelFactory.getNewModel(OrganisationPhone);
    phone.setOrganisationId(this.organisationId);
    // set a dummy ID
    phone.setId(this.getNextId());
    this.phones.push(phone);
  }

  delete(phone) {
    if (typeof phone.id === 'string') {
      // delete only from the list
      this.phones = this.phones.filter(e => e.id !== phone.id);
    } else {
      // delete from the server
      this.organisationService.deletePhone(phone.id).subscribe(res => {
        this.phones = this.phones.filter(e => e.id !== phone.id);
      });
    } 
  }

}
