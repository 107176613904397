<div class="project-status-bar">
    <div class="actions">
        <ng-container *ngFor="let action of data; let last = last">
            <div class="action {{action.class}}" matTooltip="{{action.name}}"></div>
            <ng-container *ngIf="!last">
                <div class="divider">-</div>
            </ng-container>    
        </ng-container>
    </div>
</div>
