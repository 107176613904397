export enum EnumTaskInterestStatus {
    WaitingOffer = 'waiting_offer',  // ajanlatre var
    Ordered = 'ordered', // megrendelve
    WaitingDecision = 'wating_decision', // dontesre varunk
    PersonalMeeting = 'personal_meeting',  //szemelyes talalkozo
    TestWork = 'test_work',  //test megmunkalas
    WaitingCallback = 'waiting_callback',  //visszahivasra var
    OfferSent = 'offer_sent',  //ajanlat elkuldve

    KeepInTouch = 'keep_in_touch',  //kapcsolattartas
    WaitingPriceCut = 'waiting_price_cut',  //arkedvezmenyre var
    BoughtSomebodyElse = 'bought_somebody_else',  //mastol vett
    Sold = 'sold',  //eladva
    NotNow = 'not_now' //nem aktualis
  }