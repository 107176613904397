import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { TaskInterest } from 'src/app/models/taskInterest.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TaskInterestService } from 'src/app/services/taskInterest.service';
import { EnumTaskInterestStatus } from 'src/app/enums/tasks/taskInterestStatus.enum'

@Component({
  selector: 'app-task-interest',
  templateUrl: './task-interest.component.html',
  styleUrls: ['./task-interest.component.scss']
})
export class TaskInterestComponent extends BaseEntity implements OnInit {
  task: TaskInterest = null;
  borderColor = '#ffffff'

  enumTaskInterestStatus = EnumTaskInterestStatus

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private taskInterestService: TaskInterestService,
    private modelFactory: ModelFactory
  ) {
    super();

    this.task = this.modelFactory.getNewModel(TaskInterest)
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.taskInterestService.get(id).subscribe(i => {
        this.task = i;
        this.setBorderColor();
      });
    }
  }

  setStatus(status) {
    let archived = (status === 'archived') ? true : false;
    this.task.archived = archived;
    this.taskInterestService.update(this.task).subscribe(task => {
      this.task = task;
    });
  }

  setBorderColor() {
    // if project has a color set it for the Task
    let color = null;

    if (this.task._action && this.task._action._project && this.task._action._project._projectType) {
     color = this.task._action._project._projectType.color;
    }

   if (color) {
     this.borderColor = color;
   }
  }

  edit() {
    this.router.navigate(['../interest-setup'],  {
      queryParams: {id: this.task.id}, 
      relativeTo: this.route  
    });
  }

  statusChanged() {
    this.taskInterestService.update(this.task).subscribe(task => {
      this.task = task;
    })
  }
}
