<div class="section">
    <div class="header flex">
        <div class="title full"><span>Projekt Lépések ({{actions.length}})</span></div>
        <div class="padding-10-0">
            <button mat-stroked-button (click)="openActionPlanSearch()">
                <mat-icon>add</mat-icon> Lépés Hozzáadása
            </button>
        </div>
    </div>
    <div>
        <table class="table">
              <thead>
                  <tr>
                    <th class=w50>Sorszám</th>
                      <th class="w50">Fel</th>
                      <th class=w50>Le</th>
                      <th>Lépés</th>
                      <th class=w50></th>
                  </tr>
              </thead>      
            <tbody>
                <ng-container *ngFor="let action of actions; let first = first; let last = last">
                    <tr>
                        <td>{{action.order + 1}}</td>
                        <td>
                            <ng-container *ngIf="!first">
                                <mat-icon class="cursor text-green" (click)="moveUp(action)">arrow_circle_up</mat-icon>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="!last">
                                <mat-icon class="cursor text-red" (click)="moveDown(action)">arrow_circle_down</mat-icon>
                            </ng-container>
                        </td>
                        <td>
                            {{action.name}}
                            <div class="helper-text" matTooltip="{{action._actionTemplate.description}}">
                                {{action._actionTemplate.description | truncate:10}}
                            </div>
                        </td>
                        <td>
                            <button mat-button (click)="delete(action)" matTooltip="Törlésre Megjelölve">
                                <mat-icon class="text-secondary">close</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container> 
            </tbody>
        </table>
    </div>
    <div class="padding-20-0">
        <button mat-mini-fab color="primary" (click)="save()" matTooltip="Mentés">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>

