/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./organisations/organisations.component.ngfactory";
import * as i3 from "../services/urlParam.service";
import * as i4 from "@angular/router";
import * as i5 from "./organisations/organisations.component";
import * as i6 from "../services/organisation.service";
import * as i7 from "./contacts.component";
var styles_ContactsComponent = [i0.styles];
var RenderType_ContactsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsComponent, data: {} });
export { RenderType_ContactsComponent as RenderType_ContactsComponent };
export function View_ContactsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Full Contacts Componenet"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-organisations", [], null, null, null, i2.View_OrganisationsComponent_0, i2.RenderType_OrganisationsComponent)), i1.ɵprd(512, null, i3.UrlParamService, i3.UrlParamService, [i4.ActivatedRoute, i4.Router]), i1.ɵdid(7, 114688, null, 0, i5.OrganisationsComponent, [i6.OrganisationService, i4.Router, i4.ActivatedRoute, i3.UrlParamService], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, null); }
export function View_ContactsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts", [], null, null, null, View_ContactsComponent_0, RenderType_ContactsComponent)), i1.ɵdid(1, 114688, null, 0, i7.ContactsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsComponentNgFactory = i1.ɵccf("app-contacts", i7.ContactsComponent, View_ContactsComponent_Host_0, {}, {}, []);
export { ContactsComponentNgFactory as ContactsComponentNgFactory };
