import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from 'src/app/models/action.model';

@Component({
  selector: 'app-project-actions-bar',
  templateUrl: './project-actions-bar.component.html',
  styleUrls: ['./project-actions-bar.component.scss']
})
export class ProjectActionsBarComponent implements OnInit {
  @Input() actions: Action[];
  @Output() onSelect = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getStatusText(status) {
    let txt = 'Nem kezdődött el'

    if (status === 'done') {
      txt = 'Lépés Befjezve';
    } else if (status === 'in_progress') {
      txt = 'Lépés Folyamatban';
    } else if (status == 'cancelled') {
      txt = 'Lépés Törölve';
    } else if (status == 'blocked') {
      txt = 'Lépés Elakadt';
    }

    return txt;
  }

  setSelectedAction(action) {
    this.onSelect.emit(action);
  }

}
