/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-category.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../categories/page-category/category/category.component.ngfactory";
import * as i3 from "../../../../categories/page-category/category/category.component";
import * as i4 from "../../../../services/category.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "./dlg-edit-category.component";
import * as i7 from "../../../../services/modelFactory.service";
var styles_DlgEditCategoryComponent = [i0.styles];
var RenderType_DlgEditCategoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditCategoryComponent, data: {} });
export { RenderType_DlgEditCategoryComponent as RenderType_DlgEditCategoryComponent };
export function View_DlgEditCategoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center padding-20-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kateg\u00F3ria L\u00E9trhoz\u00E1sa"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-category", [], null, [[null, "eventCreated"], [null, "eventDeleted"], [null, "eventUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventCreated" === en)) {
        var pd_0 = (_co.created($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDeleted" === en)) {
        var pd_1 = (_co.deleted($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventUpdated" === en)) {
        var pd_2 = (_co.updated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CategoryComponent_0, i2.RenderType_CategoryComponent)), i1.ɵdid(3, 114688, null, 0, i3.CategoryComponent, [i4.CategoryService, i5.MatDialog], { category: [0, "category"], isDlg: [1, "isDlg"] }, { eventCreated: "eventCreated", eventUpdated: "eventUpdated", eventDeleted: "eventDeleted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.category; var currVal_1 = true; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_DlgEditCategoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-category", [], null, null, null, View_DlgEditCategoryComponent_0, RenderType_DlgEditCategoryComponent)), i1.ɵdid(1, 114688, null, 0, i6.DlgEditCategoryComponent, [i7.ModelFactory, i5.MatDialogRef, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditCategoryComponentNgFactory = i1.ɵccf("app-dlg-edit-category", i6.DlgEditCategoryComponent, View_DlgEditCategoryComponent_Host_0, {}, {}, []);
export { DlgEditCategoryComponentNgFactory as DlgEditCategoryComponentNgFactory };
