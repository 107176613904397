import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DlgErrorComponent } from '../shared/dialogs/dlg-error/dlg-error.component';



@Injectable({
    providedIn: 'root'
})
export class ErrorMsgService {
    // this service should find the proper error mesage based on the provided errorId
    // the error message is showed in a dialog
    error:any = null;

    constructor(
        private dialog: MatDialog
    ) {}

    show(error) {
        this.error = error;
        this.openDialog()
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            error: this.error
        };

        this.dialog.open(DlgErrorComponent, dialogConfig);
    }
}
