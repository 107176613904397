import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanningProjectService } from 'src/app/services/planningProject.service';

@Component({
  selector: 'app-dlg-search-project-template',
  templateUrl: './dlg-search-project-template.component.html',
  styleUrls: ['./dlg-search-project-template.component.scss']
})
export class DlgSearchProjectTemplateComponent implements OnInit {
  txtSearch: string = '';
  options = [];
  timeout = null;

  constructor(
    private planningProjectService: PlanningProjectService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchProjectTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;
  }

  ngOnInit() {
  }

  search() {
    if (this.txtSearch.length < 3) {
      return;
    }

    this._doSearch();
  }

  _doSearch() {
    let options = {params:{
      search: this.txtSearch,
      limit: 20,
      archived: false
    }};

    this.planningProjectService.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(projectTemplate) {
    if (!projectTemplate) {
      return;
    }
    this.txtSearch = projectTemplate.name;

    setTimeout(() => {
      this.dialogRef.close(projectTemplate);
    }, 200);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }



}
