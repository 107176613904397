import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { PdfSnippet } from '../models/pdfSnippet.model';
import { ListResponse } from '../interfaces/httpResponse.interface';


@Injectable({
    providedIn: 'root'
  })
export class PdfSnippetService {
    baseUrl = environment.baseUrl;

    URL = '/pdf/snippets/';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(snippet_type: string) {
        // only list a snippets for a specific snippet type!
        let url = this.baseUrl + this.URL;
        let params = {
            type: snippet_type,
            limit: '1000'
        }

        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(snippet => new PdfSnippet(snippet));
                return res.results;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get<PdfSnippet>(url).pipe(
            map(res => new PdfSnippet(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(
            map(result => new PdfSnippet(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new PdfSnippet(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}