/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dash.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dash.component";
var styles_DashComponent = [i0.styles];
var RenderType_DashComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashComponent, data: {} });
export { RenderType_DashComponent as RenderType_DashComponent };
export function View_DashComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Dash"]))], null, null); }
export function View_DashComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dash", [], null, null, null, View_DashComponent_0, RenderType_DashComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashComponentNgFactory = i1.ɵccf("app-dash", i2.DashComponent, View_DashComponent_Host_0, {}, {}, []);
export { DashComponentNgFactory as DashComponentNgFactory };
