export enum EnumNotificationType {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted',
    Success = 'success',
    Error = 'error',
    EmailSent = 'emailSent',
    PassswordNotEqual = 'passwordNotEqual',
    PasswordMinLength = 'passwordMinLength',
    PasswordLowerCase = 'passwordLowerCase',
    PasswordUpperCase = 'passwordUppercase',
    PasswordNumber = 'passwordNumber',
    PasswordSpecialChar = 'passwordSpecialChar',

    // specials 

    // company
    OrgMissing = 'orgMissing',

    // termek
    ItemMissing = 'itemMissing',

    // alap termek
    ProductInList = 'productInList'
  }