import { Component, OnInit } from '@angular/core';
import { Organisation } from 'src/app/models/organisation';
import { ActivatedRoute, Router } from "@angular/router";
import { OrganisationService } from 'src/app/services/organisation.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PeopleService } from 'src/app/services/people.service';
import { People } from 'src/app/models/people.model';
import { DlgEditPersonComponent } from 'src/app/shared/dialogs/edit/dlg-edit-person/dlg-edit-person.component';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { EnumCountry } from 'src/app/enums/countries.enums';

@Component({
  selector: 'app-page-organisation',
  templateUrl: './page-organisation.component.html',
  styleUrls: ['./page-organisation.component.scss']
})
export class PageOrganisationComponent implements OnInit {
  organisation: Organisation = null;
  persons: People[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private organisationService: OrganisationService,
    private personService: PeopleService,
    private dialog: MatDialog,
    private modelFactory: ModelFactory
  ) { }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.organisationService.get(id).subscribe(i => {
        this.organisation = i;
      });

      this.loadPersons(id);
    } else {
      this.organisation = this.modelFactory.getNewModel(Organisation);
      // default country is Hungary
      this.organisation.addressCountry = EnumCountry.Hungary;
    }
  }

  created(entity) {
    this.organisation = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.organisation.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../organisations'],  {
        relativeTo: this.route  
      });
  }

  updated(event) {
    //
  }

  loadPersons(orgId) {
      let options = {params: {organisation: orgId, limit: 1000}};
      this.personService.list(options).subscribe(listResponse => {
          this.persons = listResponse.results;
      });
  }

  openPerson(person) {
     //  navigate to the page
     this.router.navigate(['../../persons/person'],  {
      queryParams: {id: person.id}, 
      relativeTo: this.route  
    });
  }

  createPersonDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {
      organisation: this.organisation
    };

    const dialogRef = this.dialog.open(DlgEditPersonComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(person => {
        this.loadPersons(this.organisation.id);     
    });
  }

}
