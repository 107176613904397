import { Component, Input, Output, OnInit, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { OrganisationService } from 'src/app/services/organisation.service';
import { PeopleService } from 'src/app/services/people.service';
import { EventEmitter } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumCountry } from 'src/app/enums/countries.enums';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent extends BaseEntity implements OnInit {
  @Input() organisation: Organisation;
  @Input() isDlg: boolean = false;

  people: People[] = null;

  editMode: boolean = false;
  
  enumsCountry = EnumCountry;

  @Output() eventCreated: EventEmitter<Organisation> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<Organisation> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<Organisation> = new EventEmitter();
  
  constructor(
    private route: ActivatedRoute,
    private organisationService: OrganisationService,
    private router: Router,
    private peopleService: PeopleService
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.organisation)
  }

  save() {
    (this.organisation.id) ? this.update() : this.create();
  }

  create() {
    this.organisationService.create(this.organisation).subscribe( organisation => {
      this.organisation = organisation;
      this.editMode = true;

      this.eventCreated.emit(this.organisation);
    })
  }

  update() {
    this.organisationService.update(this.organisation).subscribe(organisation => {
      this.organisation = organisation;

      this.eventUpdated.emit(this.organisation);
    })
  }

  delete() {
    this.organisationService.delete(this.organisation).subscribe(() => {
      this.eventDeleted.emit();
    });
  }

  getPeople(organisationId) {
    let options = {
      params: {
        limit: 1000,
        organisation: organisationId
      }
    }

    this.peopleService.list(options).subscribe(res => {
      //list query with a big limit
      if (res.results.length > 0) {
        this.people = res.results;
      }
    });
  }

  openPeople(people) {
     //  navigate to the page
     this.router.navigate(['../../persons/person'],  {
      queryParams: {id: people.id}, 
      relativeTo: this.route  
    });
  }

}
