import { Component, Inject, OnInit } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-organisaton-details',
  templateUrl: './bottom-organisaton-details.component.html',
  styleUrls: ['./bottom-organisaton-details.component.scss']
})
export class BottomOrganisatonDetailsComponent implements OnInit {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    // create refrence to the current Sheet
    private _bottomSheetRef: MatBottomSheetRef<BottomOrganisatonDetailsComponent>,
    private router: Router) { }

  ngOnInit() { }

  edit() {
    if (this.data) {
      // navigate to the edit page
      this.router.navigate(['/app/contacts/organisations/organisation'],  {
        queryParams: {id: this.data.id}
      });

      // close the sheet
      this._bottomSheetRef.dismiss();
    }
    
  }

}
