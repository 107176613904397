import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Action } from 'src/app/models/action.model';
import { Project } from 'src/app/models/project.model';
import { ActionService } from 'src/app/services/action.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { DlgSearchPlanActionComponent } from 'src/app/shared/dialogs/search/dlg-search-plan-action/dlg-search-plan-action.component';

@Component({
  selector: 'app-actions-setup',
  templateUrl: './actions-setup.component.html',
  styleUrls: ['./actions-setup.component.scss']
})
export class ActionsSetupComponent extends BaseEntity implements OnInit {
  @Input() project: Project;

  actions: Action[] = [];

  constructor(
    private modelFacotry: ModelFactory,
    private actionService: ActionService,
    private dialog: MatDialog
  ) {
    super()
  }

  ngOnInit() {
    // list action for Project
    let options = {params: {project: this.project.id}}
    this.actionService.list(options).subscribe(resp => {
      this.actions = resp;

      this.setOrderNumbers();
    })
  }

  setOrderNumbers() {
    // function set the 'order' number form 0 up to ....
    // indpendenlty what is the current value we only relay on the current order
    this.actions.forEach((action, i) => {
      action.order = i;
    });
  }

  moveUp(action) {
    // order is the index!
    let index = action.order;

    if (index === 0) {
      return;
    }

    // add elemnt to the new position
    this.actions.splice(index - 1, 0, action);
    // delete the lement from the old postion
    this.actions.splice(index + 1, 1);

    this.setOrderNumbers();
  }

  moveDown(action) {
      // order is the index!
      let index = action.order;

      if (index === this.actions.length) {
        //last element
        return;
      }

      // add elemnt to the new position
      this.actions.splice( index + 2, 0, action);
      // delete the lement from the old postion
      this.actions.splice(index, 1);

      this.setOrderNumbers();
  }

  openActionSearch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = null;

    /*const dialogRef = this.dialog.open(DlgSearchPlanActionComponent, dialogConfig);
    dialogRef.componentInstance.onClose.subscribe(action => {
      this.createProjectActionPlan(action)
      this.setOrderNumbers();
    });*/
  }

  save() {
    this.actionService.save(this.project.id, this.actions).subscribe(actions => {
      this.actions = actions
    })
  }

  delete(action) {
    // becuse order number always keep synced and one Action could be in the list mulitple times
    // the best if we delete it based on order (and not ID)
    this.actions = this.actions.filter(a => a.order != action.order);
    this.setOrderNumbers();
  }

  openActionPlanSearch() {
    // get the actionPlan
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgSearchPlanActionComponent, dialogConfig);
    dialogRef.componentInstance.onClose.subscribe(actionPlan => {
      this.createAction(actionPlan)
    });
  }

  createAction(actionPlan) {
    // based on actionPlan create an Action and add it as a last to the list
    let action = this.modelFacotry.getNewModel(Action);
    action.id = this.getNextId();
    action.project = this.project.id;
    action._project = this.project;
    action.name = actionPlan.name;
    action.order = this.actions.length + 1;
    action.action_template = actionPlan.id;
    action._actionTemplate = actionPlan;

    this.actions.push(action);

    this.setOrderNumbers();
  }
}

