import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from './models/company.model';
import { User } from './models/user';
import { AuthService } from './services/auth.service';
import { CompanyService } from './services/company.service';
import { SecurityService } from './services/security.service';
import { DlgEditUserProfileComponent } from './shared/dialogs/edit/dlg-edit-user-profile/dlg-edit-user-profile.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn: boolean = false;
  user: User;
  company: Company = null;

  constructor (
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private ss: SecurityService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.authService.loggedIn$.subscribe((val) => {
      // get static data or the observable status
      this.loggedIn = val || this.authService.isLoggedIn();
    });

    // get the logged in user data
    this.authService.user$.subscribe(user => {
     this.user = user;
    })

    // get the logged in user data
    this.authService.accessLevel$.subscribe(accessLevel => {
     
    });

    //get company details
    if (!this.company) {
      this.companyService.load().subscribe(company => {
        this.company = company;
      });
    } 
  }

  logout() {
    this.loggedIn = false;
    this.authService.logout();
    this.router.navigate(['login'])
  }

  openUserProfileEdit() {
    // open User Profile DLG to edit you own data which is not managed by the admin
    const dialogConfig = new MatDialogConfig();

    if (!this.user) {
      alert('Ki vagy te? Nincs Felhasználó!')
    }

    dialogConfig.data = {
      user: this.user
    };

    const dialogRef = this.dialog.open(DlgEditUserProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      // fetch user maybe changed a name
      this.authService.fetchUser().subscribe();
    });
  }
}
