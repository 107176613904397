<form class="" #f="ngForm" canEdit [module]="'contacts'" [entity]="organisation">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Cég</mat-label>
            <input matInput name="name" [(ngModel)]="organisation.name" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Terület</mat-label>
            <input matInput name="industry" [(ngModel)]="organisation.industry" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Adó Szám</mat-label>
            <input matInput name="taxNumber" [(ngModel)]="organisation.taxNumber" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline"> 
            <mat-label>Telefon</mat-label>
            <input matInput name="telefon" [(ngModel)]="organisation.phone" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput name="email" [(ngModel)]="organisation.email" autocomplete="off">
        </mat-form-field>
    </p>
    <hr>
    <div class="badge">Cím</div>
    <div class="flex flex-wrap flex-space-evenly">
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Ország</mat-label>
                <mat-select [(value)]="organisation.addressCountry">
                    <mat-option [value]="enumsCountry.Albania">Albánia</mat-option>
                    <mat-option [value]="enumsCountry.Andorra">Andorra</mat-option>
                    <mat-option [value]="enumsCountry.Austria">Ausztria</mat-option>
                    <mat-option [value]="enumsCountry.Belarus">Fehéroroszország</mat-option>
                    <mat-option [value]="enumsCountry.Belgium">Belgium</mat-option>
                    <mat-option [value]="enumsCountry.Bosnia">Bosznia</mat-option>
                    <mat-option [value]="enumsCountry.Bulgaria">Bulgária</mat-option>
                    <mat-option [value]="enumsCountry.Croatia">Horvátország</mat-option>
                    <mat-option [value]="enumsCountry.CzechRepublic">Cseh Köztársaság</mat-option>
                    <mat-option [value]="enumsCountry.Denmark">Dánia</mat-option>
                    <mat-option [value]="enumsCountry.Estonia">Észtország</mat-option>
                    <mat-option [value]="enumsCountry.Finland">Finnország</mat-option>
                    <mat-option [value]="enumsCountry.France">Franciaország</mat-option>
                    <mat-option [value]="enumsCountry.Germany">Németország</mat-option>
                    <mat-option [value]="enumsCountry.Greece">Görögország</mat-option>
                    <mat-option [value]="enumsCountry.Hungary">Magyarórszág</mat-option>
                    <mat-option [value]="enumsCountry.Iceland">Izland</mat-option>
                    <mat-option [value]="enumsCountry.Ireland">Írország</mat-option>
                    <mat-option [value]="enumsCountry.Italy">Olaszország</mat-option>
                    <mat-option [value]="enumsCountry.Latvia">Lettország</mat-option>
                    <mat-option [value]="enumsCountry.Liechtenstein">Liechtenstein</mat-option>
                    <mat-option [value]="enumsCountry.Lithuania">Litvánia</mat-option>
                    <mat-option [value]="enumsCountry.Luxembourg">Luxemburg</mat-option>
                    <mat-option [value]="enumsCountry.Malta">Málta</mat-option>
                    <mat-option [value]="enumsCountry.Moldova">Moldova</mat-option>
                    <mat-option [value]="enumsCountry.Monaco">Monaco</mat-option>
                    <mat-option [value]="enumsCountry.Montenegro">Montenegró</mat-option>
                    <mat-option [value]="enumsCountry.Netherlands">Hollandia</mat-option>
                    <mat-option [value]="enumsCountry.Macedonia">Macedónia</mat-option>
                    <mat-option [value]="enumsCountry.Norway">Norvégia</mat-option>
                    <mat-option [value]="enumsCountry.Poland">Lengyelország</mat-option>
                    <mat-option [value]="enumsCountry.Portugal">Portugália</mat-option>
                    <mat-option [value]="enumsCountry.Romania">Románia</mat-option>
                    <mat-option [value]="enumsCountry.Russia">Oroszország</mat-option>
                    <mat-option [value]="enumsCountry.SanMarino">San Marino</mat-option>
                    <mat-option [value]="enumsCountry.Serbia">Szerbia</mat-option>
                    <mat-option [value]="enumsCountry.Slovenia">Szlovákia</mat-option>
                    <mat-option [value]="enumsCountry.Slovenia">Szlovénia</mat-option>
                    <mat-option [value]="enumsCountry.Spain">Spanyolország</mat-option>
                    <mat-option [value]="enumsCountry.Sweden">Svédország</mat-option>
                    <mat-option [value]="enumsCountry.Switzerland">Svájc</mat-option>
                    <mat-option [value]="enumsCountry.Ukraine">Ukrajna</mat-option>
                    <mat-option [value]="enumsCountry.UnitedKingdom">Egyesült Királyság</mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Város</mat-label>
                <input matInput name="city" [(ngModel)]="organisation.addressCity" autocomplete="off">
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Utca (1)</mat-label>
                <input matInput name="cim1" [(ngModel)]="organisation.addressLine1" autocomplete="off">
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Utca (2)</mat-label>
                <input matInput name="cim2" [(ngModel)]="organisation.addressLine2" autocomplete="off">
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Iranyítószám</mat-label>
                <input matInput name="postcode" [(ngModel)]="organisation.addressPostcode" autocomplete="off">
            </mat-form-field>
        </p>
    </div>
    <p class="flex padding-10-0">
        <span class="padding-l-20">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="organisation.id">
            <span class="padding-l-20">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-20">
                <app-quick-log-info [data]="organisation"></app-quick-log-info>
            </span>
        </ng-container>
    </p>

</form>
