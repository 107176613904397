import { Component, OnInit, Inject } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DlgEditOrganisationComponent } from '../../edit/dlg-edit-organisation/dlg-edit-organisation.component';

@Component({
  selector: 'app-dlg-search-organisation',
  templateUrl: './dlg-search-organisation.component.html',
  styleUrls: ['./dlg-search-organisation.component.scss']
})
export class DlgSearchOrganisationComponent implements OnInit {
  //text which we searching 
  txtSearch: string = '';
  options = [];
  timeout = null;

  constructor(
    private organisationService: OrganisationService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchOrganisationComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;
  }

  ngOnInit() {
    setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let options = {params:{
      search: this.txtSearch,
      limit: 50
    }};

    this.organisationService.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(organisation) {
    if (!organisation) {
      return;
    }
    this.txtSearch = organisation.name;

    setTimeout(() => {
      this.dialogRef.close(organisation);
    }, 200);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }

  createOrganisation() {
    let dialogConfig = new MatDialogConfig(); 
  
    
    dialogConfig.data = {};

    const dialogRef = this.dialog.open(DlgEditOrganisationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(organisation => {
      this.select(organisation);
    });
  }

}
