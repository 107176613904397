import { Component, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { People } from 'src/app/models/people.model';
import { PeopleService } from 'src/app/services/people.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DlgSearchOrganisationComponent } from 'src/app/shared/dialogs/search/dlg-search-organisation/dlg-search-organisation.component';
import { EventEmitter } from '@angular/core';
import { Organisation } from 'src/app/models/organisation';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent extends BaseEntity implements OnInit {
  @Input() people: People;
  @Input() organisation: Organisation = null;
  @Input() isDlg: boolean = false;

  editMode: boolean = false;

  @Output() eventCreated: EventEmitter<People> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<People> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<People> = new EventEmitter();

  constructor(    
    private route: ActivatedRoute,
    private peopleService: PeopleService,
    private router: Router,
    private dialog: MatDialog) {
      super();
    }

  ngOnInit() {
    if (this.organisation && !this.people.id) {
      // if the organisation is passed to the componenet we set it for the new person
      this.people.organization = this.organisation.id;
      this.people._organization = this.organisation;
    }
  }

  save() {
    (this.people.id) ? this.update() : this.create();
  }

  create() {
    this.peopleService.create(this.people).subscribe(res => {
      this.people = res;
      this.editMode = true;

      this.eventCreated.emit(this.people);
    })
  }

  update() {
    this.peopleService.update(this.people).subscribe(res => {
      this.people = res;

      this.eventUpdated.emit(this.people);
    })
  }

  delete() {
    this.peopleService.delete(this.people).subscribe(() => {
      this.eventDeleted.emit();
    });
  }

  openOrgSearchDlg() {
    // open search dialog to get another company
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.people.organization) ? this.people._organization.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchOrganisationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(organisation => {
      if (organisation) {
          // update the origanistion for the people
          this.people.organization = organisation.id;
          // update the organisation details 
          this.people._organization = organisation;
      } else if (organisation == '') {
        // if empty string set back we delete the organisation
        this.people.organization = null;
        this.people._organization = null;
      }
    });
  }
}
