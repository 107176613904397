import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CategoryService } from 'src/app/services/category.service';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-dlg-search-category',
  templateUrl: './dlg-search-category.component.html',
  styleUrls: ['./dlg-search-category.component.scss']
})
export class DlgSearchCategoryComponent implements OnInit {
  //text which we searching 
  txtSearch: string = '';
  options = [];

  timeout = null;

  constructor(
    private service: CategoryService,
    public dialogRef: MatDialogRef<DlgSearchCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;
  }

  ngOnInit() {
    // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 50
    }};

    this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
      this.txtSearch = entity.nameHu;

      setTimeout(() => {
        this.dialogRef.close(entity);
      }, 500);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 500);
  }

}

