import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatPaginator } from '@angular/material';
import { PeopleService } from 'src/app/services/people.service';
import { environment } from '../../../environments/environment';
import { UrlParamService } from '../../services/urlParam.service';
import { BaseList } from 'src/app/common/baseClasses/baseList';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss'],
  providers: [UrlParamService]
})
export class PersonsComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  displayedColumns = ['name', 'role', 'organisation', 'updated'];

  constructor(
    private peopleServide: PeopleService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ){
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.peopleServide.list(options).subscribe(data => {
        this.setListResponseData(data);
      });
    });
  }

  open(people) {
    this.router.navigate(['person'],  {
      queryParams: {id: people.id}, 
      relativeTo: this.route  
    });
  }

  openOrg(people) {
    if(!people.organization) {
      return;
    }

    this.router.navigate(['/app/contacts/organisations/organisation'],  {
      queryParams: {id: people.organization}
    });
  }

  lookup() {
    this.doSearch();
  }

}
