export class Organisation {
    id: number = null;
    name: String = null;
    industry?: String = null;
    taxNumber?: String = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

    email: String = null;
    phone: String = null;
    addressCountry: String  = null;
    addressCity: String = null;
    addressLine1: String = null;
    addressLine2: String = null;
    addressPostcode: String = null;

     //extra informaion - email, phone and addresses
     emails? = [];
     phones? = [];
     addresses? = [];

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name = data.name;
            this.industry = data.industry;
            this.taxNumber = data.taxNumber;

            this.email = data.email;
            this.phone = data.phone;
            
            this.addressCountry = data.addressCountry;
            this.addressCity = data.addressCity;
            this.addressLine1 = data.addressLine1;
            this.addressLine2 = data.addressLine2;
            this.addressPostcode = data.addressPostcode;
            
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

            if ('emails' in data) {
                this.emails = data.emails;
            }
    
            if ('phones' in data) {
                this.phones = data.phones;
            }
    
            if ('addresses' in data) {
                this.addresses = data.addresses;
            }
        }
    }
  }

export class OrganisationEmail {
    id: number | String = null;
    name: String = null;
    email: String = null;
    organization: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.email = data.email;
            this.organization = data.organization;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setOrganisationId(id: number) {
        this.organization = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummy) id
        this.id = id;
    }
}

export class OrganisationPhone {
    id: number | String = null;
    name: String = null;
    phone: String = null;
    organization: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.phone = data.phone;
            this.organization = data.organization;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setOrganisationId(id: number) {
        this.organization = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummygit ) id
        this.id = id;
    }
}

export class OrganisationAddress {
    id: number | String = null;
    name: String = null;
    city: String = null;
    addressLine1: String = null;
    addressLine2?: String = null;
    postcode?: String = null;
    organization: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.organization = data.organization;
            this.city = data.city;
            this.addressLine1 = data.addressLine1;

            if (data.postcode) {
                this.postcode = data.postcode;
            }

            if (data.addressLine2) {
                this.addressLine2 = data.addressLine2;
            }
            
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setOrganisationId(id: number) {
        this.organization = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummygit ) id
        this.id = id;
    }
}

    export class OrganisationItem {
        id: number | String = null;
        organization: Number = null;
        _organization?: any = null;
        item: Number = null;
        _item?: any = null;
        
        created?: Date = null;
        updated?: Date = null;
        creator?: {color: String, email: String, firstName: String, lastName: String} = null;
        lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;
    
        constructor(data: any) {
            if (data) {
                this.id = data.id;
                this.organization = data.organization;
                this.item = data.item;
    
                if (data._organization) {
                    this._organization = data._organization;
                }
    
                if (data._item) {
                    this._item = data._item;
                }
                
                this.creator = data.creator;
                this.lastModifier = data.lastModifier;
                this.created = new Date(data.created);
                this.updated = new Date(data.updated);
            }  
        }
    
        setOrganisation(id: number, organization?: Organisation) {
            this.organization = id;
            if (organization) {
                this._organization = organization;
            }
        }

        setItemId(id: number) {
            this.item = id;
        }
    
        setId(id: string) {
            // only use newly created data (before we sending it to the server)
            // therefore for security reasons we only allow to passing string (dummy id)
            this.id = id;
        }
}