<ul class="breadcrumb"> 
    <li>Megkeresések</li>  
</ul>

<div class="flex"> 
    <button (click)="doSearch()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="doSearch()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Státus</mat-label>
            <mat-select name="status"  [(ngModel)]="status"  (selectionChange)="changeTaskStatus($event.value)">
                <mat-option [value]="">Összes</mat-option>
                <mat-option [value]="enumTaskInterestStatus.WaitingOffer">Ajánlatra Vár</mat-option>
                <mat-option [value]="enumTaskInterestStatus.WaitingCallback">Visszahívásra Vár</mat-option>
                <mat-option [value]="enumTaskInterestStatus.WaitingDecision">Döntésre Várunk</mat-option>
                <mat-option [value]="enumTaskInterestStatus.PersonalMeeting">Személyes Találozó</mat-option>
                <mat-option [value]="enumTaskInterestStatus.TestWork">Teszt Megmunkálás</mat-option>
                <mat-option [value]="enumTaskInterestStatus.OfferSent">Ajánlat Elküldve</mat-option>
                <mat-option [value]="enumTaskInterestStatus.Ordered">Megrendelve</mat-option>
                <mat-option [value]="enumTaskInterestStatus.KeepInTouch">Kapcsolattartás</mat-option>
                <mat-option [value]="enumTaskInterestStatus.WaitingPriceCut">Kevezményre Vár</mat-option>
                <mat-option [value]="enumTaskInterestStatus.BoughtSomebodyElse">Mástól Vett</mat-option>
                <mat-option [value]="enumTaskInterestStatus.Sold">Eladva</mat-option>
                <mat-option [value]="enumTaskInterestStatus.NotNow">Nem Aktuális</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Fontosság</mat-label>
            <mat-select name="importance"  [(ngModel)]="importance">
                <mat-option (click)="changeImportance(null)" [value]="">Összes</mat-option>
                <mat-option (click)="changeImportance(5)" [value]="5">Nagyon Fontos</mat-option>
                <mat-option (click)="changeImportance(4)" [value]="4">Fontos</mat-option>
                <mat-option (click)="changeImportance(3)" [value]="3">Normal</mat-option>
                <mat-option (click)="changeImportance(2)" [value]="2">Nem Fontos</mat-option>
                <mat-option (click)="changeImportance(1)" [value]="1">Mellékes</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Felelős</mat-label>
            <mat-select name="users" [(ngModel)]="assignee">
                <mat-option (click)="changeUser('')" [value]="">Összes</mat-option>
                <mat-option (click)="changeUser(option)" *ngFor="let option of users" [value]="option.id">
                    {{option.lastName}} {{option.firstName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeStatus($event)">
            <mat-radio-button [checked]="archived == false" value="active"  matTooltip="Aktív">Aktív</mat-radio-button>
            <mat-radio-button [checked]="archived == true" value="archived" matTooltip="Archivált">Archivált</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <button mat-mini-fab color="accent"  [routerLink]="['interest-setup']" matTooltip="Új Megkeresés Létrhozása"> 
            <mat-icon>add</mat-icon>
        </button>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="importance">
        <mat-header-cell *matHeaderCellDef>Fonotsság</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-project-importance [importance]="row.importance"></app-project-importance>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)" matTooltip="{{row.name}}">{{row.name | truncate: 4}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef>Feleős</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-avatar [user]="row._assignee"></app-avatar>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Státusz</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.status == enumTaskInterestStatus.WaitingOffer">Ajánlatra Vár</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.Ordered">Visszahívásra Vár</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.WaitingDecision">Döntésre Várunk</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.PersonalMeeting">Személyes Találozó</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.TestWork">Teszt Megmunkálás</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.WaitingCallback">Ajánlat Elküldve</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.OfferSent">Megrendelve</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.KeepInTouch">Kapcsolattartás</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.WaitingPriceCut">Kevezményre Vár</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.BoughtSomebodyElse">Mástól Vett</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.Sold">Eladva</ng-container>
          <ng-container *ngIf="row.status == enumTaskInterestStatus.NotNow">Nem Aktuális</ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef>Projekt</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.action">
                <div>
                    <div matTooltip="row._action._project.name" matTooltip="{{row._action._project.name}}">{{row._action._project.name | truncate:4}}</div>
                    <div class="text-small text-grey" matTooltip="row._action.name" matTooltip="{{row._action.name}}">{{row._action.name | truncate:4}}</div>
                </div>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef>Cég</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span *ngIf="row.organization" matTooltip="{{row._organization.name}}">{{row._organization.name | truncate:4}}</span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>



