<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név</mat-label>
            <input matInput name="nev" [(ngModel)]="actionPlan.name"  autocomplete="off" required>
        </mat-form-field>
        
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Leírás</mat-label>
            <textarea rows=6 matInput name="description" [(ngModel)]="actionPlan.description"  autocomplete="off"></textarea>
        </mat-form-field>
        
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Feladat</mat-label>
            <mat-select [(value)]="actionPlan.taskType">
                <mat-option value="">Nincs Feladat</mat-option>
                <mat-option [value]="enumTaskTypes.Service">Szervíz</mat-option>
                <mat-option [value]="enumTaskTypes.ItemOrder">Megrendelés</mat-option>
                <mat-option [value]="enumTaskTypes.ItemPurchase">Beszerzés</mat-option>
                <mat-option [value]="enumTaskTypes.ItemInterest">Megkeresés</mat-option>
            </mat-select>
        </mat-form-field>
    </p>
    <p class="padding-20-0">
        <mat-radio-group (change)="changeStatus($event)" >
            <mat-radio-button  [checked]="!actionPlan.archived" value="active">Aktív</mat-radio-button>
            <mat-radio-button [checked]="actionPlan.archived" value="archived">Archivált</mat-radio-button>
        </mat-radio-group>
    </p>
    <p class="padding-20-0">
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="actionPlan.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés"  (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="actionPlan"></app-quick-log-info>           
            </span>
        </ng-container>
    </p>
</form>