<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>App</title>
</head>
<body>
  <!--loader-->
  <app-loader></app-loader>
  <!-- Page-->
  <mat-drawer-container autosize *ngIf="loggedIn; else notLoggedIn">
      <mat-drawer #drawer class="sidenav" mode="over">
        <ng-container *ngIf="user">
          <app-sidemenu (clicked)="drawer.close()"></app-sidemenu>
        </ng-container>
      </mat-drawer>
      <div class="sidenav-content">
        <mat-toolbar color="primary">
          <mat-toolbar-row>
              <button mat-icon-button (click)="drawer.toggle()">
                  <mat-icon>menu</mat-icon>
              </button>
              <span><ng-container *ngIf="company">{{company.name}}</ng-container></span>
              <span class="spacer"></span>

            <!--Create New-->
            <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>add_circle_outline</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <button mat-menu-item routerLink="/app/projects/project-setup">
                    <mat-icon>inventory_2</mat-icon>
                    <span >Új Projekt</span> 
                  </button>
                  <button mat-menu-item routerLink="/app/contacts/organisations/organisation">
                    <mat-icon>storefront</mat-icon>
                    <span >Új Cég</span> 
                  </button>
                  <button mat-menu-item routerLink="/app/contacts/persons/person">
                    <mat-icon>assignment_ind</mat-icon>
                    <span>Új Kontakt</span> 
                  </button>
                  <button mat-menu-item routerLink="/app/items/item">
                    <mat-icon>style</mat-icon>
                    <span>Új Termék</span> 
                  </button>
              </mat-menu>

              <span class="spacer-to-right"></span>
              
              <button mat-icon-button [matMenuTriggerFor]="accountSection">
                <mat-icon>account_circle</mat-icon>
              </button>
              <mat-menu #accountSection="matMenu">
                <button mat-menu-item (click)="logout()">
                  <mat-icon>exit_to_app</mat-icon>
                  <span >Kilépés</span> 
                </button>  
                <button mat-menu-item (click)="openUserProfileEdit()">
                  <mat-icon>edit</mat-icon>
                  <span>Profil Szerkesztés</span> 
                </button>
              </mat-menu>     
              <span *ngIf="user">{{user.firstName}}</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <div class="page-container container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
</mat-drawer-container>
  <!--end:page-->

  <!--login-->
<ng-template #notLoggedIn class="page-container container-fluid">
  <router-outlet></router-outlet>
</ng-template>
</body>
</html>




      