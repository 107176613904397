import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Category } from 'src/app/models/category.model';
import { CategoryService } from 'src/app/services/category.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-page-category',
  templateUrl: './page-category.component.html',
  styleUrls: ['./page-category.component.scss']
})
export class PageCategoryComponent implements OnInit {
  category: Category = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private modelFactory: ModelFactory
  ) {
    this.category = this.modelFactory.getNewModel(Category)
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.categoryService.get(id).subscribe(c => {
        this.category = c;
      });
    }
  }

  created(entity) {
    this.category = entity;
    this.router.navigate(['./'],  {
      queryParams: {id: this.category.id}, 
      relativeTo: this.route  
    });
  }

  deleted(evetn) {
      this.router.navigate(['../../categoryies'],  {
      relativeTo: this.route  
    });
  }

  updated(entity) {
    this.category = entity;
  }

}
