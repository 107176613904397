import { Role } from './role.model';
import { SimpleUser } from './user';

export class UserRole {
    id: number = null;
    user: SimpleUser =  new SimpleUser(null);
    role: Role =  new Role(null);
    created?: Date = null;
    updated?: Date = null;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.user = new SimpleUser(data._user);
           this.role =  new Role(data._role);
           this.updated = new Date(data.updated);

           if (data.created) {
            this.created =  new Date(data.created);
           }

           if (data.updated) {
            this.updated = new Date(data.updated);
           }  
       }
   }
}