/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-organisation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/common";
import * as i12 from "./bottom-organisation.component";
import * as i13 from "@angular/material/bottom-sheet";
import * as i14 from "../../../services/organisation.service";
var styles_BottomOrganisationComponent = [i0.styles];
var RenderType_BottomOrganisationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomOrganisationComponent, data: {} });
export { RenderType_BottomOrganisationComponent as RenderType_BottomOrganisationComponent };
function View_BottomOrganisationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "showBottomOrg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 3, "mat-icon", [["class", "cursor mat-icon notranslate"], ["matTooltip", "C\u00E9g"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.openBottomSheet() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(4, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, 0, ["storefront"]))], function (_ck, _v) { _ck(_v, 3, 0); var currVal_2 = "C\u00E9g"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_BottomOrganisationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomOrganisationComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BottomOrganisationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-organisation", [], null, null, null, View_BottomOrganisationComponent_0, RenderType_BottomOrganisationComponent)), i1.ɵdid(1, 114688, null, 0, i12.BottomOrganisationComponent, [i13.MatBottomSheet, i14.OrganisationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomOrganisationComponentNgFactory = i1.ɵccf("app-bottom-organisation", i12.BottomOrganisationComponent, View_BottomOrganisationComponent_Host_0, { data: "data" }, {}, []);
export { BottomOrganisationComponentNgFactory as BottomOrganisationComponentNgFactory };
