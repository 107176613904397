import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-person',
  templateUrl: './dlg-edit-person.component.html',
  styleUrls: ['./dlg-edit-person.component.scss']
})
export class DlgEditPersonComponent implements OnInit {
  people: People = new People(null);
  organisation: Organisation = null;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditPersonComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.people = this.modelFactory.getNewModel(People);
    this.organisation = data.organisation
  }

  ngOnInit() {
  }

  created(people) {
    this.closeDlg(people)
  }

  updated(people) {
    this.closeDlg(people);
  }

  deleted(event) {
    //pass
  }

  closeDlg(people) {
    this.dialogRef.close(people);
  }


}
