import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PdfSnippetService } from 'src/app/services/pdfSnippet.service';
import { PdfSnippet } from 'src/app/models/pdfSnippet.model';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DlgEditPdfSnippetComponent } from '../../dialogs/edit/dlg-edit-pdf-snippet/dlg-edit-pdf-snippet.component';

@Component({
  selector: 'app-pdf-snippet',
  templateUrl: './pdf-snippet.component.html',
  styleUrls: ['./pdf-snippet.component.scss']
})
export class PdfSnippetComponent implements OnInit {
  @Input() type: any;
  @Input() language: any;

  snippets: PdfSnippet[];

  @Output() onSelect = new EventEmitter();

  // selected text in the dialog based on the language
  snippet: Text;

  constructor(
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
  }

  openSnippetDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {
      type: this.type,
      language: this.language
    };

    const dialogRef = this.dialog.open(DlgEditPdfSnippetComponent, dialogConfig);

    dialogRef.componentInstance.onSelect.subscribe(snippet => {
        this.snippet = snippet;
        this.onSelect.emit(this.snippet);
    });
   }

}
