<div class="section">
    <div class="header">
        <div class="title">
            <span>Cím ({{addresses.length}})</span>
        </div>
    </div>

    <div *ngFor="let obj of addresses">
            <form class="from-group-inline" #f="ngForm">
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Város</mat-label>
                        <input matInput type="text" name="city" [(ngModel)]="obj.city" autocomplete="off" required>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Cím 1</mat-label>
                        <input matInput type="text" name="addressLine1" [(ngModel)]="obj.addressLine1" autocomplete="off" required>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Cím 2</mat-label>
                        <input matInput type="text" name="addressLine2" [(ngModel)]="obj.addressLine2" autocomplete="off">
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Irányítószám</mat-label>
                        <input matInput type="text" name="postcode" [(ngModel)]="obj.postcode" autocomplete="off">
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Megjegyzés</mat-label>
                        <input matInput type="text" name="name" [(ngModel)]="obj.name" autocomplete="off">
                    </mat-form-field>
                </p>


                <p>
                    <span class="padding-l-10">
                        <button mat-mini-fab  color="primary" (click)="save(obj)"  [disabled]="f.invalid" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10">
                        <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete(obj)" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10">
                        <app-quick-log-info [data]="obj"></app-quick-log-info>
                    </span>         
                </p>
            </form>          
    </div>   
    <div class="padding-20-0">
        <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új Email">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>

