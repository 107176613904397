<ng-container *ngIf="project.id">
    <div class="padding-10-0">
        <button (click)="openProjectPage()" mat-stroked-button color="primary">Vissza a Projekt oldalra</button>
    </div>
</ng-container>

<div class="section">
    <div class="header flex">
        <div class="full">
            <div class="title"><span>Projekt</span></div>
        </div>   
    </div>
        <form #f="ngForm">
            <p *ngIf="!project.id">
                <mat-form-field appearance="outline">
                    <mat-label>Projekt Terv</mat-label>
                    <ng-container *ngIf="project.project_template; else noResult">
                        <input matInput name="template" disabled [(ngModel)]="project._projectTemplate.name"  autocomplete="off">
                    </ng-container>
                    <ng-template #noResult>
                        <input matInput name="template" disabled [(ngModel)]="project.project_template" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openTemplateSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Project Név</mat-label>
                    <input matInput name="nev" [(ngModel)]="project.name"  autocomplete="off" required>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Típus</mat-label>
                    <mat-select name="project_type" [(ngModel)]="project.project_type" required>
                        <mat-option *ngFor="let option of projectTypes" [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Leírás</mat-label>
                    <textarea rows=5 matInput name="description" [(ngModel)]="project.description"  autocomplete="off" required></textarea>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Fontosság</mat-label>
                    <mat-select name="importance"  [(ngModel)]="project.importance">
                        <mat-option [value]="5">Nagyon Fontos</mat-option>
                        <mat-option [value]="4">Fontos</mat-option>
                        <mat-option [value]="3">Normal</mat-option>
                        <mat-option [value]="2">Nem Fontos</mat-option>
                        <mat-option [value]="1">Mellékes</mat-option>
                    </mat-select>
                  </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Felelős?</mat-label>
                    <ng-container *ngIf="project.assignee; else noAssignee">
                        <input matInput name="assignee" disabled [(ngModel)]="project._assignee.lastName"  autocomplete="off">
                    </ng-container>
                    <ng-template #noAssignee>
                        <input matInput name="assignee" disabled [(ngModel)]="project.assignee" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openAssigneeSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Cég?</mat-label>
                    <ng-container *ngIf="project.organization; else noOrg">
                        <input matInput name="organisation" disabled [(ngModel)]="project._organization.name"  autocomplete="off">
                    </ng-container>
                    <ng-template #noOrg>
                        <input matInput name="organisation" disabled [(ngModel)]="project.organization" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openOrgSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p class="padding-20-0">
                <span class="padding-20-0">
                    <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                        <mat-icon>save</mat-icon>
                    </button>
                </span>
                <ng-container *ngIf="project.id">
                    <span class="padding-20-0 padding-l-10">
                        <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10 padding-10-0">
                        <app-quick-log-info [data]="project"></app-quick-log-info>           
                    </span>
                </ng-container>
            </p>
        </form>
</div>

<ng-container *ngIf="project.id">
    <app-actions-setup [project]="project"></app-actions-setup>
</ng-container>
