export class Role {
    id: number;
    name: string;
    created?: Date;
    updated?: Date;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.name = data.name;
           
           if (data.created) {
            this.created =  new Date(data.created);
           }

           if (data.updated) {
            this.updated = new Date(data.updated);
           }  
       }
   }
}