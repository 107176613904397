import { Component, OnInit, Input } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { OrganisationAddress } from 'src/app/models/organisation';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { OrganisationService } from 'src/app/services/organisation.service';
@Component({
  selector: 'app-organisation-address',
  templateUrl: './organisation-address.component.html',
  styleUrls: ['./organisation-address.component.scss']
})
export class OrganisationAddressComponent extends BaseEntity implements OnInit {
  @Input() organisationId: number;

  addresses: OrganisationAddress[] = [];

  constructor(
    private organisationService: OrganisationService,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {
    let params = {organisation: this.organisationId};
    this.organisationService.listAddresses(params).subscribe(res => {
      this.addresses = res;
    })
  }

  save(address) {
    if (typeof address.id === 'string') {
      // create
      this.create(address);
    } else {
      this.update(address);
    }
  }

  create(address) {
    this.organisationService.createAddress(address).subscribe(res => {
      this.addresses = this.addresses.filter(e => e.addressLine1 != res.addressLine1);
      this.addresses.push(res)
    })
  }

  update(address) {
    this.organisationService.updateAddress(address).subscribe()
  }

  add() {
    // add new element to the list
    let address = this.modelFactory.getNewModel(OrganisationAddress);
    address.setOrganisationId(this.organisationId);
    // set a dummy ID
    address.setId(this.getNextId());
    this.addresses.push(address);
  }

  delete(address) {
    if (typeof address.id === 'string') {
      // delete only from the list
      this.addresses = this.addresses.filter(a => a.id !== address.id);
    } else {
      // delete from the server
      this.organisationService.deleteAddress(address.id).subscribe(res => {
        this.addresses = this.addresses.filter(a => a.id !== address.id);
      });
    }
  }

}

