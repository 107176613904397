import { Component, Input,  OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { OrganisationService } from 'src/app/services/organisation.service';
import { Organisation, OrganisationItem } from 'src/app/models/organisation';
import { DlgEditOrganisationItemComponent } from 'src/app/shared/dialogs/edit/dlg-edit-organisation-item/dlg-edit-organisation-item.component';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';

@Component({
  selector: 'app-organisation-item',
  templateUrl: './organisation-item.component.html',
  styleUrls: ['./organisation-item.component.scss']
})
export class OrganisationItemComponent extends BaseEntity implements OnInit {
  @Input() organization: Organisation;

  orgItems = null;

  constructor(
    private organisationService: OrganisationService,
    private dialog: MatDialog,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {
   this.loadItems();
  }

  loadItems() {
    let params = {organization: this.organization.id, limit:1000};
    this.organisationService.listItems(params).subscribe(resp => {
      this.orgItems = resp;
    })
  }

  add() {
    let orgItem = this.modelFactory.getNewModel(OrganisationItem);
    orgItem.setOrganisation(this.organization.id, this.organization)
    this.editOrgItemDlg(orgItem);
  }
 
  editOrgItemDlg(orgItem) {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {orgItem: orgItem};

    const dialogRef = this.dialog.open(DlgEditOrganisationItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(orgItem => {
        this.loadItems();
    });
  }

}

