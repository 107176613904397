import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { ProjectType } from 'src/app/models/projectType.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProjectTypeService } from 'src/app/services/projectType.service';

@Component({
  selector: 'app-project-type',
  templateUrl: './project-type.component.html',
  styleUrls: ['./project-type.component.scss']
})
export class ProjectTypeComponent extends BaseEntity implements OnInit {
  projectType: ProjectType = null;

  constructor(
    private modelFactory: ModelFactory,
    private route: ActivatedRoute,
    private router: Router,
    private projectTypeService: ProjectTypeService
  ) {
    super();
    
    this.projectType = this.modelFactory.getNewModel(ProjectType);
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.projectTypeService.get(id).subscribe(i => {
        this.projectType = i;
      });
    }
  }

  save() {
    (this.projectType.id) ? this.update() : this.create();
  }

  create() {
    this.projectTypeService.create(this.projectType).subscribe(res => {
      this.projectType = res;
    })
  }

  update() {
    this.projectTypeService.update(this.projectType).subscribe(res => {
      this.projectType = res;
    })
  }

  delete() {
    this.projectTypeService.delete(this.projectType).subscribe(() => {
       this.router.navigate(['../../types'],  {
        relativeTo: this.route  
      });
    });
  }

  changeStatus(event) {
    this.projectType.archived = (event.value == 'archived') ? true : false;
  }

}
