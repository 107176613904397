import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoaderService {
    constructor() {
        this.isLoading = new Subject();
    }
    show() {
        this.isLoading.next(true);
    }
    hide() {
        // delay to hide 1000 sec to give time to loader animation
        setTimeout(() => {
            this.isLoading.next(false);
        }, 800);
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
