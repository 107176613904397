import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { TaskInterest } from 'src/app/models/taskInterest.model';
import { TaskInterestProduct } from 'src/app/models/taskInterestProduct.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { TaskInterestProductService } from 'src/app/services/taskInterestProduct.service';
import { DlgSearchProductComponent } from 'src/app/shared/dialogs/search/dlg-search-product/dlg-search-product.component';
import { EnumNotificationType } from 'src/app/enums/notificationType.enum';
import { EnumNotificationLevel } from 'src/app/enums/notificationLevel.enum';

@Component({
  selector: 'app-task-interest-porducts',
  templateUrl: './task-interest-porducts.component.html',
  styleUrls: ['./task-interest-porducts.component.scss']
})
export class TaskInterestPorductsComponent extends BaseEntity implements OnInit {
  @Input() task: TaskInterest
  taskProducts: TaskInterestProduct[] = null;

  constructor(
   private taskInterestProductService: TaskInterestProductService,
   private modelFactory: ModelFactory,
   private dialog: MatDialog,
   private notificationService: NotificationService
  ) {
    super()
  }

  ngOnInit() {
    let params = {task: this.task.id, limit: 1000};
    this.taskInterestProductService.list({params}).subscribe(resp => {
      this.taskProducts = resp.results;
    })
  }

  openProductSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchProductComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(product => {
      if (product) {
          let pInList = this.taskProducts.find(tp => tp.product === product.id);
          if (pInList) {
            this.notificationService.notify(EnumNotificationType.ProductInList, EnumNotificationLevel.Error)
            return;
          }

          let taskProduct = this.modelFactory.getNewModel(TaskInterestProduct)
          
          taskProduct.task = this.task.id;
          taskProduct._task = this.task;
          taskProduct.product = product.id;
          taskProduct._product = product;
          taskProduct.id = this.getNextId();

          this.taskProducts.push(taskProduct);
      } 
    });
  }

  save(taskProduct) {
    if (typeof taskProduct.id === 'string') {
      this.create(taskProduct);
    } else {
      this.update(taskProduct);
    }
  }

  create(taskProduct) {
    this.taskInterestProductService.create(taskProduct).subscribe(res => {
      this.taskProducts = this.taskProducts.filter(p => p.id != taskProduct.id);
      this.taskProducts.push(res)
    })
  }

  update(taskProduct) {
    this.taskInterestProductService.update(taskProduct).subscribe();
  }

  delete(taskProduct) {
    if (typeof taskProduct.id === 'string') {
      // delete only from the list
      this.taskProducts = this.taskProducts.filter(p => p.id !== taskProduct.id);
    } else {
      // delete from the server
      this.taskInterestProductService.delete(taskProduct).subscribe(res => {
        this.taskProducts = this.taskProducts.filter(p => p.id !== taskProduct.id);
      });
    }
  }
}
