import { Component, Input, OnInit } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum';
import { Action } from 'src/app/models/action.model';
import { ActionService } from 'src/app/services/action.service';


@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent extends BaseEntity implements OnInit {
  @Input() action: Action

  enumActionStatus = EnumActionStatus;

  constructor(
    private actionService: ActionService
  ) {
    super();
  }

  ngOnInit() {
  }

  statusChange(event) {
    // already set on the action, just send it to the server
    this.actionService.update(this.action).subscribe();
  }

}
