<ul class="breadcrumb"> 
    <li>Gyártók</li>  
</ul>

<div class="flex"> 
    <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    
    <div class="full text-right">
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../items']"  matTooltip="Termékek"> 
                <mat-icon>style</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../products']" matTooltip="Alap Termékek"> 
                <mat-icon>kitchen</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="secondary" [routerLink]="['../../categories']"  matTooltip="Kategóriák"> 
                <mat-icon>workspaces</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['manufacturer']"  matTooltip="Új Gyártó"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="connected">
        <mat-header-cell *matHeaderCellDef>Weblap</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.opencartId > 0; else noConection">
                <mat-icon class="text-green" matTooltip="Összekapcsolava egy Gyártóval a weblapon">power</mat-icon>
            </ng-container>
            <ng-template #noConection>
                <mat-icon class="text-secondary" matTooltip="Nincs Összekapcsolava egy Gyártóval sem a weblapon">power_off</mat-icon>
            </ng-template>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Gyártó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link">{{row.name}} </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="pointer" (click)="open(row)"></mat-row>

</mat-table>


<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>

