
<form #f="ngForm">
    <p  *ngIf="!isDlg"> 
        <mat-form-field appearance="outline">
            <mat-label>Weblap ID:</mat-label>
            <input type="number" matInput name="opecartId" [(ngModel)]="product.opencartId" autocomplete="off">
            <mat-icon matSuffix matTooltip="Ha az Alap Termékhez van OpenCart ID hozzárendelve akkor az adatok a Weblapon kell módosítani először. Majd frissíteni a rendszer adatokat. Adatok szerkeszése nem lehetséges. Ha törlöd az ID-t a kapcsolat is törlődik.">info</mat-icon>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - HU</mat-label>
            <input matInput name="nameHu" [(ngModel)]="product.nameHu" [disabled]="product.opencartId > 0" autocomplete="off" [required]="!(product.opencartId)">
        </mat-form-field>
    </p>
    <ng-container *ngIf="!(product.opencartId)">
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Név - EN</mat-label>
                <input matInput name="nameEn" [(ngModel)]="product.nameEn" [disabled]="product.opencartId > 0" autocomplete="off">
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline">
                <mat-label>Gyártó</mat-label>
                <ng-container *ngIf="product.manufacturer; else noManufacturer">
                    <input matInput name="manufacturer" disabled [(ngModel)]="product._manufacturer.name"  autocomplete="off" required>
                </ng-container>
                <ng-template #noManufacturer>
                    <input matInput name="manufacturer" disabled [(ngModel)]="product.manufacturer" autocomplete="off" required>
                </ng-template>
                <ng-container *ngIf="!(product.opencartId)">
                    <mat-icon class="pointer" (click)="openManufacturerSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>  
                </ng-container>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline">
                <mat-label>Kategória</mat-label>
                <ng-container *ngIf="product.category; else noCategory">
                    <input matInput name="category" disabled [(ngModel)]="product._category.nameHu"  autocomplete="off">
                </ng-container>
                <ng-template #noCategory>
                    <input matInput name="category" disabled [(ngModel)]="product.category" autocomplete="off">
                </ng-template>
                <ng-container *ngIf="!(product.opencartId)">
                    <mat-icon class="pointer" (click)="openCategorySearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
                </ng-container>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline">
                <mat-label>Kép</mat-label>
                <input matInput name="imageUrl" [(ngModel)]="product.imageUrl" [disabled]="product.opencartId > 0" autocomplete="off">
                <mat-icon matSuffix matTooltip="Teljes url: http://....">info</mat-icon>
                <span matPrefix>
                    <ng-container *ngIf="product.imageUrl">
                        <img style="width: 40px;" [src]="product.imageUrl" alt="">
                    </ng-container>
                </span>
            </mat-form-field>
        </p>

        <p class="padding-20-0">
            <mat-radio-group (change)="changeType($event)" >
                <mat-radio-button [disabled]="product.opencartId" [checked]="product.type === 'machine'" value="machine">Gép</mat-radio-button>
                <mat-radio-button [disabled]="product.opencartId" [checked]="product.type === 'part'" value="part">Alkatrész</mat-radio-button>
                <mat-radio-button [disabled]="product.opencartId" [checked]="product.type === 'service'" value="service">Szolgáltatás</mat-radio-button>
            </mat-radio-group>
        </p>
    </ng-container>
    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="product.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="product"></app-quick-log-info>           
            </span>
        </ng-container> 
    </p>
</form>  

