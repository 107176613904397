<div class="section">
    <div class="header">
        <div class="title">
            <span>Érdeklődés a Termékek Iránt</span>
        </div>
    </div>
    <table class="table">  
        <ng-container *ngFor="let p of taskProducts">
            <tr>
                <td class="w50">
                    <app-icon-item-type [itemType]="p._product.type"></app-icon-item-type>
                </td>
                <td>
                    {{p._product.nameHu}}  
                </td>
                <td>
                    <mat-form-field class="w100">
                        <mat-label>Mennyiség</mat-label>
                        <input type="number" min="0" matInput [(ngModel)]="p.amount" required>
                    </mat-form-field>
                </td>
                <td class="flex">
                    <span class="padding-l-10">
                        <button mat-mini-fab  color="primary" [disabled]="p.amount < 1" (click)="save(p)" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </span>
                    <ng-container *ngIf="p.id">
                        <span class="padding-l-10">
                            <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete(p)" confirm>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                        <span class="padding-l-10">
                            <app-quick-log-info [data]="p"></app-quick-log-info>           
                        </span>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </table>
    <button mat-mini-fab  color="accent"  (click)="openProductSearchDlg()" matTooltip="Új Hozzáadaása">
        <mat-icon>add</mat-icon>
    </button>
</div>    