<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Kategória</a></li>  
    <li>{{category.nameHu}}</li>  
</ul>


<div class="section">
    <div class="header">
        <div class="title"><span>Katgória</span></div>
    </div>
    <app-category
    (eventCreated)="created($event)"
    (eventDeleted)="deleted($event)" 
    (eventUpdated)="updated($event)"
    [category]="category"></app-category>
</div>

