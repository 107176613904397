import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PdfSnippet } from '../models/pdfSnippet.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification";
export class PdfSnippetService {
    constructor(http, notificationService) {
        this.http = http;
        this.notificationService = notificationService;
        this.baseUrl = environment.baseUrl;
        this.URL = '/pdf/snippets/';
        // list items
        this.dataSource$ = new BehaviorSubject([]);
        this.data = this.dataSource$.asObservable();
    }
    list(snippet_type) {
        // only list a snippets for a specific snippet type!
        let url = this.baseUrl + this.URL;
        let params = {
            type: snippet_type,
            limit: '1000'
        };
        return this.http.get(url, { params }).pipe(map(res => {
            res.results = res.results.map(snippet => new PdfSnippet(snippet));
            return res.results;
        }));
    }
    get(id) {
        let url = this.baseUrl + this.URL + id + '/';
        return this.http.get(url).pipe(map(res => new PdfSnippet(res)));
    }
    create(payload) {
        let url = this.baseUrl + this.URL;
        return this.http.post(url, payload).pipe(map(result => new PdfSnippet(result)), tap(res => { this.notificationService.created(); }));
    }
    update(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.put(url, payload).pipe(map(res => new PdfSnippet(res)), tap(res => { this.notificationService.updated(); }));
    }
    delete(payload) {
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.http.delete(url, payload).pipe(tap(res => { this.notificationService.deleted(); }));
    }
}
PdfSnippetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PdfSnippetService_Factory() { return new PdfSnippetService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotificationService)); }, token: PdfSnippetService, providedIn: "root" });
