import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UsersService } from 'src/app/services/users.service';
import { EnumLanguage } from 'src/app/enums/languages.enum';

/**
 * Allow to edit the Profile and User infromation for the User
 * - only those which allowed to edit
 */

@Component({
  selector: 'app-dlg-edit-user-profile',
  templateUrl: './dlg-edit-user-profile.component.html',
  styleUrls: ['./dlg-edit-user-profile.component.scss']
})
export class DlgEditUserProfileComponent implements OnInit {
  data:any = {};
  user: any = null;
  profile: any = null;
  enumLanguage = EnumLanguage;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgEditUserProfileComponent>,
  ) {
    //get the user 
    this.user = this.authService.user;
    // get the profile from special endpoint
    let profile = null;
    this.profileService.getByUser(this.user.id).subscribe(p => {
      this.profile = p;
      this.buildModel();
    });
  }

  ngOnInit() {
  
  }

  buildModel() {
    // building a fake model with the editable User and Profile fields
    this.data = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      language: this.user.language,
      phonePersonal: this.profile.phonePersonal,
      phoneCompany: this.profile.phoneCompany,
      address: this.profile.address,
      timezone: this.profile.timezone,
    }
  }

  save() {
    this.profileService.updateMyProfile(this.data).subscribe(() => {
      this.dialogRef.close();
    });
  }

}
