import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth.guard';

import { LoginComponent } from './public/login/login.component';
import { DashComponent } from './dash/dash.component';
import { ContactsComponent } from './contacts/contacts.component';
//accounts
import { AccountsComponent } from './accounts/accounts.component';
import { CompaniesComponent } from './accounts/companies/companies.component';
import { OrganisationsComponent } from './contacts/organisations/organisations.component';
import { OrganisationComponent } from './contacts/organisations/page-organisation/organisation/organisation.component';
import { RolesComponent } from './accounts/roles/roles.component';
import { RoleComponent } from './accounts/roles/role/role.component';
import { UsersComponent } from './accounts/users/users.component';
import { UserComponent } from './accounts/users/user/user.component';
import { PersonsComponent } from './contacts/persons/persons.component';
import { PersonComponent } from './contacts/persons/person/person.component';
import { ProjectsComponent } from './projects/projects.component';
import { ItemComponent } from './items/page-item/item/item.component';
import { ItemsComponent } from './items/items.component';
import { ProductComponent } from './products/page-product/product/product.component';
import { ProductsComponent } from './products/products.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { CategoriesComponent } from './categories/categories.component';
import { PageManufacturerComponent } from './manufacturers/page-manufacturer/page-manufacturer.component';
import { PageCategoryComponent } from './categories/page-category/page-category.component';
import { PageItemComponent } from './items/page-item/page-item.component';
import { PageProductComponent } from './products/page-product/page-product.component';
import { PageOrganisationComponent } from './contacts/organisations/page-organisation/page-organisation.component';
import { PagePersonComponent } from './contacts/persons/page-person/page-person.component';
import { RequestForgottenPassowrdComponent } from './public/request-forgotten-passowrd/request-forgotten-passowrd.component';
import { ModuleGuard } from './services/module.guard';
import { ProjectPlansComponent } from './project-plans/project-plans.component';
import { ProjectPlanComponent } from './project-plans/project-plan/project-plan.component';
import { ActionsPlansComponent } from './project-plans/actions-plans/actions-plans.component';
import { ActionPlanComponent } from './project-plans/actions-plans/page-action-plans/action-plan/action-plan.component';
import { PageActionPlansComponent } from './project-plans/actions-plans/page-action-plans/page-action-plans.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectSetupComponent } from './projects/project-setup/project-setup.component';
import { ProjectTypesComponent } from './project-plans/project-types/project-types.component';
import { ProjectTypeComponent } from './project-plans/project-types/project-type/project-type.component';
import { TaskInterestsComponent } from './tasks/task-interests/task-interests.component';
import { TaskInterestComponent } from './tasks/task-interests/task-interest/task-interest.component';
import { TaskInterestSetupComponent } from './tasks/task-interests/task-interest-setup/task-interest-setup.component';
import { SetNewPasswordComponent } from './public/set-new-password/set-new-password.component';

/**
 * data-> module is manage the access of the page
 */

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'request-forgotten-password', component: RequestForgottenPassowrdComponent},
  { path: 'new-password', component: SetNewPasswordComponent},
  { path: 'app', canActivate: [AuthGuard], canActivateChild: [ModuleGuard], children: [
    { path: 'dash', component: DashComponent, data: {module: 'open'}},
    { path: '', redirectTo: 'dash', pathMatch: 'full'},
    { path: 'contacts', data: {module: 'contacts'}, children: [
      { path: 'organisations', data: {module: 'contacts'}, children: [
        {path: 'organisation', component: PageOrganisationComponent, data: {module: 'contacts'}},
        {path: '', component: OrganisationsComponent,  data: {module: 'contacts'}}
      ]}, 
      { path: 'persons', data: {module: 'contacts'}, children: [
        {path: 'person', component: PagePersonComponent,  data: {module: 'contacts'}},
        {path: '', component: PersonsComponent, data: {module: 'contacts'}}
      ]}, 
      {path: '', component: ContactsComponent,  data: {module: 'contacts'}}
    ]},
    { path: 'accounts', data: {module: 'accounts'}, children: [
      {path: 'company', component: CompaniesComponent,  pathMatch: 'full', data: {module: 'accounts'}},
      {path: 'roles',  data: {module: 'accounts'}, children: [
        {path: 'role', component: RoleComponent, data: {module: 'accounts'}},
        {path: '', component: RolesComponent, data: {module: 'accounts'}}
      ]},
      {path: 'users', data: {module: 'accounts'}, children: [
        {path: 'user', component: UserComponent, data: {module: 'accounts'}},
        {path: '', component: UsersComponent, data: {module: 'accounts'}}
      ]}
    ]},
    {path: 'projects', data: {module: 'projects'}, children: [
      {path: 'project', component: ProjectComponent,  data: {module: 'projects'}},
      {path: 'project-setup', component: ProjectSetupComponent,  data: {module: 'projects'}},
      {path: '', component: ProjectsComponent, data: {module: 'projects'}}
    ]},
    {path: 'items', data: {module: 'items'}, children: [
      {path: 'item', component: PageItemComponent,  data: {module: 'items'}},
      {path: '', component: ItemsComponent, data: {module: 'items'}}
    ]},
    {path: 'products', data: {module: 'items'}, children: [
      {path: 'product', component: PageProductComponent, data: {module: 'items'}},
      {path: '', component: ProductsComponent, data: {module: 'items'}}
    ]},
    {path: 'manufacturers', data: {module: 'items'}, children: [
      {path: 'manufacturer', component: PageManufacturerComponent, data: {module: 'items'}},
      {path: '', component: ManufacturersComponent, data: {module: 'items'}}
    ]},
    {path: 'categories', data: {module: 'items'}, children: [
      {path: 'category', component: PageCategoryComponent, data: {module: 'items'}},
      {path: '', component: CategoriesComponent, data: {module: 'items'}}
    ]},
    {path: 'project-plans', data: {module: 'projectplanning'}, children: [
      {path: 'project', component: ProjectPlanComponent, data: {module: 'projectplanning'}},
      {path: 'actions', data: {module: 'projectplanning'}, children: [
        {path: 'action', component: PageActionPlansComponent, data: {module: 'projectplanning'}},
        {path: '', component: ActionsPlansComponent, data: {module: 'projectplanning'}}
      ]},
      {path: 'types', data: {module: 'projectplanning'}, children: [
        {path: 'type', component: ProjectTypeComponent, data: {module: 'projectplanning'}},
        {path: '', component: ProjectTypesComponent, data: {module: 'projectplanning'}}
      ]},
      {path: '', component: ProjectPlansComponent, data: {module: 'projectplanning'}}
    ]},
    {path: 'tasks', data: {module: 'open'}, children: [
      {path: 'interests', data: {module: 'taskinterest'}, children: [
        {path: 'interest', component: TaskInterestComponent, data: {module: 'taskservice'}},
        {path: 'interest-setup', component: TaskInterestSetupComponent, data: {module: 'taskservice'}},
        {path: '', component: TaskInterestsComponent, data: {module: 'taskinterest'}}
      ]},
    ]},
  ]},
  { path: '**', component: LoginComponent}
];
//useHash: hash based urls: to make work deeplinking
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
